<header style="
    padding: 1rem;
    background: #fff;
    display: flex;
    height: 3%;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  ">
  <img src="./../../assets/logo.png" style="width: 7rem" />
  <div class="header-sign-right">
    @if(isView) {
    <button mat-button color="primary" (click)="downloadPDF()" [disabled]="isDownloading || !base64">
      Download
    </button>
    } @else {
    <button mat-button mat-raised-button color="primary" (click)="signPDF()"
      [disabled]="isSubmitting || isSubmitted || isSigned || !base64">
      Submit
    </button>
    }

    @if(signerNameInitials) {
      <mat-card class="mat-elevation-z8 sign-header-user-profile">
        <span>{{signerNameInitials}}</span>
      </mat-card>
    }
  </div>
</header>

<main style="height: 82%; margin-top: 1rem">
  @if(isDownloading) {
  <kzn-loader message="Downloading file..."></kzn-loader>
  } @else if(!isView && (isSigned || isSubmitted)) {
  <kzn-status-message [heading]="statusMessage.heading" [message]="statusMessage.message"
    [isSuccess]="statusMessage.isSuccess"></kzn-status-message>
  } @else if(isError) {
  <kzn-status-message [heading]="statusMessage.heading" [message]="statusMessage.message"
    [isSuccess]="statusMessage.isSuccess"></kzn-status-message>
  } @else if(base64) {
  <ngx-extended-pdf-viewer [base64Src]="base64" [handTool]="false" [showHandToolButton]="true"
    (annotationLayerRendered)="onAnnotationLayerRendered($event)" [customToolbar]="additionalButtons"
    [showUnverifiedSignatures]="true" (pagesLoaded)="pagesLoaded($event)" [pageViewMode]="pageViewMode">
  </ngx-extended-pdf-viewer>

  <ng-template #additionalButtons>
    <div id="toolbarViewer">
      <div id="toolbarViewerLeft">
        <!-- <pdf-toggle-sidebar></pdf-toggle-sidebar> -->
        <div class="toolbarButtonSpacer"></div>
        <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
        <pdf-paging-area></pdf-paging-area>
      </div>
      <!-- <pdf-zoom-toolbar></pdf-zoom-toolbar> -->
      <div id="toolbarViewerRight">
        @if(!isView) {
        <div class="document-action-btn-container">
          <button mat-stroked-button color="primary" (click)="openSignDialog(true, true)">
            <mat-icon>add_comment</mat-icon>Comment
          </button>
          <button mat-stroked-button color="primary" (click)="openSignDialog(false, true)">
            <mat-icon>add</mat-icon>Signature
          </button>
        </div>
        }
      </div>
    </div>
  </ng-template>
  }
</main>
<kzn-footer></kzn-footer>
@if(isSubmitting) {
<kzn-loader [isOverlay]="true"></kzn-loader>
}