import { Component, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { SortDirection } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateEditCustomerComponent } from "@components/create-edit-customer/create-edit-customer.component";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { StatusMessageComponent } from "@components/layouts/status-message/status-message.component";
import { TableComponent } from "@components/layouts/table/table.component";
import { ViewCustomerComponent } from "@components/view-customer/view-customer.component";
import { Constants } from "@config/constants";
import { Messages } from "@config/messages";
import { ICustomer } from "@modals/api.modal";
import { ITableActionRow } from "@modals/app.modal";
import { AppService } from "@services/app.service";
import { HttpService } from "@services/http.service";

@Component({
  selector: "kzn-customers",
  standalone: true,
  imports: [
    LoaderComponent,
    StatusMessageComponent,
    ViewCustomerComponent,
    TableComponent,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: "./customers.component.html",
  styleUrl: "./customers.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class CustomersComponent implements OnInit {
  private _httpService = inject(HttpService);
  private _activatedRoute = inject(ActivatedRoute);
  private _appService = inject(AppService);
  private _router = inject(Router);
  public isLoading: boolean = true;
  public customers: Array<ICustomer> = [];
  public isError: boolean = false;
  public errorMessage: string = "";
  public customerID!: string;
  public selectedCustomer!: ICustomer;
  public defaultSortColumnDef: string = "CreatedOn";
  public defaultSortDirection: SortDirection = "desc";
  public tableColumns = [
    {
      columnDef: "CustomerName",
      header: "Customer Name",
    },
    {
      columnDef: "Email",
      header: "Email",
    },
    {
      columnDef: "Phone",
      header: "Phone",
    },
    {
      columnDef: "LoginId",
      header: "Username",
      cell: (row: ICustomer) => row?.Users?.[0]?.LoginId,
    },
    {
      columnDef: "IsEnabled",
      header: "Active",
      cell: (row: ICustomer) => (row?.IsEnabled ? "Yes" : "No"),
    },
    {
      columnDef: "CreatedOn",
      header: "Creation Date",
      isDate: true,
      cell: (row: ICustomer) =>
        this._appService.getFormattedDate(row.CreatedOn),
    },
    {
      columnDef: "UpdatedOn",
      header: "Updation Date",
      isDate: true,
      cell: (row: ICustomer) =>
        this._appService.getFormattedDate(row.UpdatedOn),
    },
  ];
  public tableRowActions: Array<ITableActionRow> = [
    {
      icon: "remove_red_eye",
      color: "primary",
      title: "View",
      actionFn: (row: ICustomer) => {
        this._router.navigate([`customers/${row.CustomerId}`]);
      },
    },
    {
      icon: "edit",
      color: "primary",
      title: "Edit",
      actionFn: (row: ICustomer) => {
        this.openCreateEditCustomerDialog(row);
      },
    },
  ];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.customerID = this._activatedRoute.snapshot.params["customerID"];
    this.getCustomers();
  }

  public getCustomers(showLoader?: boolean): void {
    if (showLoader) this.isLoading = true;
    this._httpService
      .get(this._httpService.createEndpoint(Constants.API_URI_CUSTOMER_GET_ALL))
      .subscribe(
        (response: any) => {
          this.customers = response.Data;
          if (this.customerID) {
            this.selectedCustomer = this.customers.find(
              (customer) => customer.CustomerId == this.customerID
            ) as ICustomer;
          }
          this.isLoading = false;
          this.isError = false;
        },
        (errorResponse) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage =
            errorResponse.error?.FailRules?.[0]?.Msg ||
            Messages.MSG_ERROR_UNEXPECTED;
        }
      );
  }

  public openCreateEditCustomerDialog(customerDetails?: ICustomer) {
    const dialogRef = this.dialog.open(CreateEditCustomerComponent, {
      data: {
        ...(customerDetails ? { customerDetails } : {}),
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.isSuccess) {
        this.getCustomers();
      }
    });
  }
}
