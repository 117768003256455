import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public tableInputData: Array<any> = [];
  
  constructor() { }
}
