<mat-card class="mat-elevation-z8 pd-1 card__pdf-tools-properties">
    <mat-card-header>
        <mat-card-title>Tools</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <button mat-stroked-button [color]="selectedTool === 'esign' ? 'primary' : 'none'"
            (click)="toggleTool('esign')">
            <mat-icon color="primary">edit</mat-icon> Signature
        </button>
        <button mat-stroked-button [color]="selectedTool === 'initials' ? 'primary' : 'none'"
            (click)="toggleTool('initials')">
            <mat-icon color="primary">short_text</mat-icon> Initials
        </button>
        <button mat-stroked-button [color]="selectedTool === 'stamps' ? 'primary' : 'none'"
            (click)="toggleTool('stamps')">
            <mat-icon color="primary">check_circle</mat-icon> Stamps
        </button>
        <button mat-stroked-button [color]="selectedTool === 'text' ? 'primary' : 'none'" (click)="toggleTool('text')">
            <mat-icon color="primary">text_fields</mat-icon> Text
        </button>
        <button mat-stroked-button [color]="selectedTool === 'date' ? 'primary' : 'none'" (click)="toggleTool('date')">
            <mat-icon color="primary">calendar_today</mat-icon> Date
        </button>
        <button mat-stroked-button [color]="selectedTool === 'checkbox' ? 'primary' : 'none'"
            (click)="toggleTool('checkbox')">
            <mat-icon color="primary">check_box</mat-icon> Checkbox
        </button>
        <button mat-stroked-button [color]="selectedTool === 'comment' ? 'primary' : 'none'"
            (click)="toggleTool('comment')">
            <mat-icon color="primary">comment</mat-icon> Comment
        </button>
    </mat-card-content>
</mat-card>

<div class="pdf-container" #pdfContainer (click)="handlePdfClick($event)"></div>

<mat-card class="mat-elevation-z8 pd-1 card__pdf-tools-properties"
    [ngStyle]="{ visibility: selectedAnnotation ? 'visible' : 'hidden' }">
    <mat-card-header>
        <mat-card-title>Properties</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        @if(selectedAnnotation) {
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
            <mat-label>Placeholder</mat-label>
            <input matInput id="placeholder" #placeholderElement [(ngModel)]="selectedAnnotation.properties.placeholder"
                (input)="updateAnnotationProperty('placeholder', placeholderElement.value)" />
        </mat-form-field>

        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
            <mat-label>Font Size</mat-label>
            <mat-select [(value)]="selectedAnnotation.properties.fontSize"
                (selectionChange)="updateAnnotationProperty('fontSize', $event.value)">
                <mat-option *ngFor="let size of fontSizes" [value]="size">
                    {{ size }} px
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
            <mat-label>Assign to</mat-label>
            <mat-select [(value)]="selectedAnnotation.properties.user"
                (selectionChange)="updateAnnotationProperty('user', $event.value)">
                <mat-option *ngFor="let user of tableService.tableInputData" [value]="user.email">
                    {{ user.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
            <mat-label>Copy to</mat-label>
            <mat-select (selectionChange)="copyAnnotationToPages($event.value)">
                <mat-option value="all">All Pages</mat-option>
                <mat-option value="even">Even Pages</mat-option>
                <mat-option value="odd">Odd Pages</mat-option>
            </mat-select>
        </mat-form-field>
        }
    </mat-card-content>
</mat-card>
