<header style="
    padding: 1rem;
    background: #fff;
    display: flex;
    height: 3%;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  ">
    <img src="./../../assets/logo.png" style="width: 7rem" />
    <div class="header-sign-right">
        @if(signerNameInitials()) {
        <mat-card class="mat-elevation-z8 sign-header-user-profile">
            <span>{{signerNameInitials()}}</span>
        </mat-card>
        }
    </div>
</header>