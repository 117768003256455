<div class="otp-verification-container">
  <div class="otp-verification-header">
    <h1 class="primary a-center"><strong>OTP Verification</strong></h1>
    <h3>Enter the 6 digit verification code received on your Email ID</h3>
    @if(isOTPExpired) {
    <kzn-alert
      [message]="Messages.MSG_ERROR_OTP_EXPIRED"
      style="display: flex"
    ></kzn-alert>
    }
  </div>
  <div class="otp-verification-content">
    <div class="otp-verification-input">
      <div class="otp-verification-input__header">
        <span class="otp-verification-input__header-left"
          >OTP valid for {{ timerValue }}</span
        >
        <button
          mat-stroked-button
          class="otp-verification-input__header-right primary clickable"
          (click)="resendOTP()"
          [class.spinner]="resendingOTP"
          [disabled]="resendingOTP || isResendOTPDisabled"
        >
          Resend OTP
        </button>
      </div>
      <kzn-otp-input #otpInputComponentRef></kzn-otp-input>
    </div>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="verifyOTP()"
      [class.spinner]="isLoading"
      [disabled]="isLoading || timerValue === '00:00' || resendingOTP"
    >
      Verify
    </button>
    <button mat-button (click)="navigateBack()">Back</button>
  </div>
</div>
