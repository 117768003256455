import { environment } from "./../../environments/environment";

export class Constants {
  public static readonly OTP_TIME_VALIDITY = 60 * 5;
  public static readonly SIGNATURE_TAG_PREFIX = "kaizen";
  public static readonly SIGNATURE_DYNAMIC_TAG_PREFIX = "kaizensign";
  public static readonly COMMENT_TAG_PREFIX = "kaizencomment";
  public static readonly DEFAULT_SIGN_IMAGE_WIDTH = "19.50%";
  public static readonly DEFAULT_SIGN_IMAGE_HEIGHT = "3.75%";
  public static readonly DEFAULT_SIGN_COMMENT_WIDTH = 220;
  public static readonly DEFAULT_SIGN_COMMENT_HEIGHT = 85;
  public static readonly DEFAULT_SIGN_COMMENT_WIDTH_PERCENTAGE = 30;
  public static readonly DEFAULT_SIGN_COMMENT_HEIGHT_PERCENTAGE = 15;
  public static readonly RESEND_OTP_DISABLE_TIMER_SECONDS = 120;
  public static readonly MINIMUM_SIGN_IMAGE_WIDTH = 16 * 6;
  public static readonly MINIMUM_SIGN_IMAGE_HEIGHT = 16 * 2;
  public static readonly MINIMUM_SIGN_COMMENT_WIDTH = 16 * 9;
  public static readonly MINIMUM_SIGN_COMMENT_HEIGHT = 16 * 5;
  public static readonly DEFAULT_ROUTE_AFTER_LOGIN = "requests";
  public static readonly DEFAULT_SIGN_COMMENT_FONT = "32px Arial";
  public static readonly DEFAULT_COMMENT_PREFIX = "Comment by {ROLE}";
  public static readonly DEFAULT_COMMENT_SUFFIX =
    "powered by www.AYANKAiZEN.com";
  public static readonly SIGN_COMMENT_CLASS = "input-sign-comment";
  public static readonly DEFAULT_SIGN_INITIALS_COUNT = 6;
  public static readonly MAX_SIGN_UPLOAD_FILE_SIZE_IN_KB = 200 * 1024;
  public static readonly MAX_EMAIL_TEMPLATE_COUNT = 2;

  public static readonly API_ENDPOINT = environment.API_ENDPOINT;
  public static readonly API_AUTH_CODE = environment.API_AUTH_CODE;
  public static readonly API_URI_LOGIN = "Handshake/login";
  public static readonly API_URI_OTP = "Handshake/Otp";
  public static readonly API_URI_OTP_VALIDATE = "Handshake/Otp/validate";
  public static readonly API_URI_USER_DETAILS = "Handshake/UserDetail";
  public static readonly API_URI_LOGOUT = "Handshake/logout";
  public static readonly API_URI_PASSWORD_RESET = "Password/Reset";
  public static readonly API_URI_PASSWORD_CHANGE = "Password/Change";
  public static readonly API_URI_SIGN_DOWNLOAD_SIGN = "Sign/DownloadSign";
  public static readonly API_URI_SIGN_DOWNLOAD_VIEW = "Sign/DownloadView";
  public static readonly API_URI_SIGN_UPLOAD = "Sign/Upload";
  public static readonly API_URI_DECLINE_SIGN_REQUEST = "/DeclineSignRequest";
  public static readonly API_URI_SIGN_REQUEST_ALL = "SignRequest/All";
  public static readonly API_URI_SIGN_REQUEST_STR = "Sign/RequestStr";
  public static readonly API_URI_SIGN_REQUEST_GET_DRAFT = "SignRequest/GetDraft";
  public static readonly API_URI_APP_TOKEN_GET_ALL = "AppToken/GetAll";
  public static readonly API_URI_APP_TOKEN_CREATE = "AppToken/Create";
  public static readonly API_URI_CUSTOMER_GET_ALL = "Customer/GetAll";
  public static readonly API_URI_CUSTOMER_CREATE = "Customer/Create";
  public static readonly API_URI_CUSTOMER_UPDATE = "Customer/Update";
  public static readonly API_URI_CUSTOMER_SUBSCRIBE = "Customer/Subscribe";
  public static readonly API_URI_CUSTOMER_SUBSCRIPTION_UPDATE =
    "CustomerSubscription/Update";
  public static readonly API_URI_SUBSCRIPTION_GET_ALL = "Subscription/GetAll";
  public static readonly API_URI_BLOG_LIST = "Blog/List";
  public static readonly API_URI_BLOG_CREATE = "Blog/Create";
  public static readonly API_URI_BLOG_UPDATE = "Blog/Update";
  public static readonly API_URI_EMAIL_TEMPLATE_GET_ALL = "EmailTemplate/GetAll";
  public static readonly API_URI_EMAIL_TEMPLATE_CREATE = "EmailTemplate/Create";
  public static readonly API_URI_EMAIL_TEMPLATE_UPDATE = "EmailTemplate/Update";
  public static readonly API_URI_EMAIL_TEMPLATE_DELETE = "EmailTemplate/Delete";
  public static readonly API_URI_SIGN_GET_SIGNER_SIGNATURE = "Sign/GetSignerSignature";
}
