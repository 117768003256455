import { Component, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { CreateTokenComponent } from "@components/create-token/create-token.component";
import { StatusMessageComponent } from "@components/layouts/status-message/status-message.component";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { TableComponent } from "@components/layouts/table/table.component";
import { Constants } from "@config/constants";
import { Messages } from "@config/messages";
import { IAppToken } from "@modals/api.modal";
import { HttpService } from "@services/http.service";
import { AppService } from "@services/app.service";

@Component({
  selector: "kzn-settings",
  standalone: true,
  imports: [
    LoaderComponent,
    StatusMessageComponent,
    TableComponent,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: "./settings.component.html",
  styleUrl: "./settings.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SettingsComponent implements OnInit {
  private _httpService = inject(HttpService);
  private _appService = inject(AppService);
  public isLoading: boolean = true;
  public allTokens: Array<IAppToken> = [];
  public isError: boolean = false;
  public errorMessage: string = "";
  public tableColumns = [
    {
      columnDef: "AppName",
      header: "App Name",
    },
    {
      columnDef: "CustomerName",
      header: "Customer Name",
    },
    {
      columnDef: "Token",
      header: "Token",
    },
    {
      columnDef: "TokenCreatedAt",
      header: "Creation Date",
      cell: (row: IAppToken) => {
        return this._appService.getFormattedDate(row.TokenCreatedAt);
      },
    },
    {
      columnDef: "TokenExpiredAt",
      header: "Expiration Date",
      cell: (row: IAppToken) => {
        return this._appService.getFormattedDate(row.TokenExpiredAt);
      },
    },
  ];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getAllTokens();
  }

  getAllTokens(showLoader?: boolean): void {
    if (showLoader) this.isLoading = true;
    this._httpService
      .get(
        this._httpService.createEndpoint(Constants.API_URI_APP_TOKEN_GET_ALL)
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isError = false;
          this.allTokens = response.Data;
        },
        (errorResponse) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage =
            errorResponse.error?.FailRules?.[0]?.Msg ||
            Messages.MSG_ERROR_UNEXPECTED;
        }
      );
  }

  public openCreateTokenDialog() {
    const dialogRef = this.dialog.open(CreateTokenComponent);
    dialogRef.afterClosed().subscribe((data) => {
      if (data.isSuccess) {
        this.getAllTokens();
      }
    });
  }
}
