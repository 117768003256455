<div [id]="id" class="resizable" cdkDrag cdkDragBoundary=".page"
  [ngStyle]="{ left: left, top: top, height: isCustomTagElement ? '100%' : height, width: isCustomTagElement ? '100%' : width }">
  <div class="action-handles-container">
    <mat-icon color="primary" class="drag-handle" cdkDragHandle matTooltip="Drag"
      [ngClass]="{ disabled: !isDraggable || isCustomTagElementStatic }">open_with</mat-icon>
    <mat-icon color="warn" (click)="onRemoveElement ? onRemoveElement() : destroyComponent()" matTooltip="Delete"
      [ngClass]="{ disabled: isCustomTagElementStatic }">delete</mat-icon>
  </div>
  <div class="resizers"
    [ngClass]="{ 'resizers-comment-only sign-comment-container': isCommentOnly || isCustomTagElement }">
    <div class="sign-container" [ngClass]="{ 'custom-tag-container': isCustomTagElement }">
      @if(isCustomTagElement) {
      <div [class]="Constants.SIGN_COMMENT_CLASS">
        <ng-content select="[tag-content]"></ng-content>
      </div>
      } @else {
      @if(isCommentOnly) {
      <div [class]="Constants.SIGN_COMMENT_CLASS">
        <mat-form-field appearance="outline">
          <mat-label>{{ Constants.DEFAULT_COMMENT_PREFIX }}</mat-label>
          <textarea matInput [value]="comment" placeholder="Enter Comment" autofocus></textarea>
        </mat-form-field>
        <label class="sign-comment-suffix">{{
          Constants.DEFAULT_COMMENT_SUFFIX
          }}</label>
      </div>
      } @else {
      <img height="100%" width="100%" [src]="imageBase64" [attr.signatureId]="signatureId" alt="" resize />
      }
      <!-- @if(signComment) {
        <div class="sign-comment-container">
          <div class="sign-comment-prefix">Comment :&nbsp;</div>
          <div class="sign-comment">{{ signComment }}</div>
        </div>
        } -->
      }
    </div>
    <div class="resizer top-left" [ngClass]="{'resizer__disabled' : isCustomTagElementStatic}"></div>
    <div class="resizer top-right" [ngClass]="{'resizer__disabled' : isCustomTagElementStatic}"></div>
    <div class="resizer bottom-left" [ngClass]="{'resizer__disabled' : isCustomTagElementStatic}"></div>
    <div class="resizer bottom-right" [ngClass]="{'resizer__disabled' : isCustomTagElementStatic}"></div>
  </div>
</div>