import { CommonModule } from "@angular/common";
import { Component, ViewChild, ViewEncapsulation, inject } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { Router, RouterModule } from "@angular/router";
import { OtpInputComponent } from "@components/otp-input/otp-input.component";
import { OtpVerificationComponent } from "@components/otp-verification/otp-verification.component";
import { Constants } from "@config/constants";
import { Messages } from "@config/messages";
import { IPasswordChange, IPasswordReset } from "@modals/api.modal";
import { HttpService } from "@services/http.service";
import { ToastService } from "@services/toast.service";

@Component({
  selector: "kzn-reset-password",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule,
    OtpVerificationComponent,
    OtpInputComponent,
    CommonModule,
  ],
  providers: [HttpService],
  templateUrl: "./reset-password.component.html",
  styleUrl: "./reset-password.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent {
  Messages = Messages;
  userName = new FormControl("", Validators.required);
  passwordForm = new FormGroup({
    newPassword: new FormControl("", Validators.required),
    confirmPassword: new FormControl("", [
      Validators.required,
      this.passwordMatchValidator.bind(this),
    ]),
  });
  @ViewChild("otpInputComponentRef") otpInputComponentRef!: OtpInputComponent;

  private _httpService = inject(HttpService);
  private _toastr = inject(ToastService);
  private _router = inject(Router);

  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public isSendingOTP: boolean = false;
  public isOTPSent: boolean = false;
  public isResettingPassword: boolean = false;
  public requestId: string = "";

  passwordMatchValidator(
    control: FormControl
  ): { [key: string]: boolean } | null {
    const newPassword = this.passwordForm?.get("newPassword")?.value;
    const confirmPassword = control.value;

    // Check if the passwords match
    if (newPassword !== confirmPassword) {
      return { passwordMismatch: true }; // Add custom error if passwords don't match
    }

    return null; // Return null if there is no error
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  public sendResetOTP(): void {
    this.userName.markAsTouched();

    if (this.userName.valid) {
      this.userName.disable();
      this.isSendingOTP = true;
      const request: IPasswordReset = {
        LoginId: this.userName.value!,
      };

      this._httpService
        .post(
          this._httpService.createEndpoint(Constants.API_URI_PASSWORD_RESET),
          request
        )
        .subscribe(
          (response: any) => {
            this.isSendingOTP = false;
            this.isOTPSent = true;
            this.requestId = response.Data.RequestId;
          },
          (error) => {
            this.isSendingOTP = false;
            this.userName.enable();
            this._httpService.showHttpError(error);
          }
        );
    }
  }

  public resetPassword(): void {
    this.passwordForm.markAllAsTouched();

    if (
      this.passwordForm.valid &&
      this.otpInputComponentRef.isOtpInputValid()
    ) {
      this.passwordForm.disable();
      this.isResettingPassword = true;

      const request: IPasswordChange = {
        LoginId: this.userName.value!,
        RequestId: this.requestId,
        NewPwd: this.passwordForm.get("newPassword")?.value!,
        Otp: this.otpInputComponentRef.getOtpValue(),
      };

      this._httpService
        .post(
          this._httpService.createEndpoint(Constants.API_URI_PASSWORD_CHANGE),
          request
        )
        .subscribe(
          (response: any) => {
            this._toastr.showSuccess(Messages.MSG_SUCCESS_PASSWORD_CHANGED);
            this._router.navigate([`/login`]);
          },
          (error) => {
            this.isResettingPassword = false;
            this.passwordForm.enable();
            this._httpService.showHttpError(error);
          }
        );
    }
  }
}
