import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private _toastr = inject(ToastrService);
  private _toastrConfig = {
    timeOut: 10000,
    progressBar: true,
    closeButton: true
  }

  showSuccess(message: string) {
    this._toastr.success(message, '', this._toastrConfig);
  }

  showError(message: string) {
    this._toastr.error(message, '', this._toastrConfig);
  }

  showInfo(message: string) {
    this._toastr.info(message, '', this._toastrConfig);
  }

  showWarning(message: string) {
    this._toastr.warning(message, '', this._toastrConfig);
  }
}
