import { CommonModule } from "@angular/common";
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "kzn-loader",
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule],
  templateUrl: "./loader.component.html",
  styleUrl: "./loader.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent {
  @Input() message: string = "Please wait...";
  @Input() isOverlay: boolean = false;
}
