import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import interact from 'interactjs';
import { MatStepper } from "@angular/material/stepper";
import { SharedMaterialModule } from "@config/shared-material.module";
import { HttpService } from "@services/http.service";
import { Constants } from "@config/constants";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { IAppToken } from '@modals/api.modal';
import { TableService } from '@services/table.service';
import { MatCardModule } from '@angular/material/card';
import { AppService } from '@services/app.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-pdf-view-edit',
    templateUrl: './pdf-view-edit.component.html',
    styleUrl: './pdf-view-edit.component.scss',
    imports: [SharedMaterialModule, MatCardModule],
    standalone: true
})
export class PdfViewEditComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('pdfContainer') pdfContainer!: ElementRef<HTMLDivElement>;
    @Output() previousStep = new EventEmitter<void>();
    @Input() pdfFile!: File | null;
    @Input({ required: true }) appToken!: IAppToken;
    @Input() stepper!: MatStepper;
    @Output() onSubmitComplete = new EventEmitter<void>();
    @Input() topPosition: number = 0;

    private _activatedRoute = inject(ActivatedRoute);

    selectedTool: "text" | "checkbox" | "date" | "esign" | "comment" | "initials" | "stamps" | null = null;
    annotations: any[] = [];
    pdfBase64: string | null = null;
    selectedAnnotation: any = null;
    selectedFontSize: number = 16;
    placeholder: string = '';
    fontSizes: number[] = [8, 10, 12, 14, 16, 18, 20, 24];
    totalPages: number[] = [];
    isDraftRequest: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private httpService: HttpService,
        public tableService: TableService,
        private _appService: AppService
    ) { }

    ngOnInit(): void {
        pdfjsLib.GlobalWorkerOptions.workerSrc =
            'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js';
        this.isDraftRequest = this._activatedRoute.snapshot.data["isDraftRequest"] ?? false;
    }

    ngOnChanges(): void {
        if (this.pdfFile) {
            this.convertPdfToBase64();
            this.renderPDF(this.pdfFile).then();
        }
    }

    ngAfterViewInit() {
        document.addEventListener('click', this.handleOutsideClick.bind(this));
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.handleOutsideClick.bind(this));
    }

    toggleTool(tool: "text" | "checkbox" | "date" | "esign" | "comment" | "initials" | "stamps"): void {
        if (this.selectedTool === tool) {
            this.selectedTool = null;
        } else {
            this.selectedTool = tool;
            this.selectTool(tool);
        }
    }

    selectTool(tool: "text" | "checkbox" | "date" | "esign" | "comment" | "initials" | "stamps"): void {
        this.selectedTool = tool;
        this.pdfContainer.nativeElement.style.cursor = 'crosshair';
    }

    handleOutsideClick(event: MouseEvent): void {
        const clickedElement = event.target as HTMLElement;
        const propertyBox = document.querySelector('.property-box');
        const matSelectOverlay = document.querySelector('.cdk-overlay-container'); // Angular Material overlay

        if (
            propertyBox &&
            !propertyBox.contains(clickedElement) &&
            !this.isClickOnAnnotation(clickedElement) &&
            !(matSelectOverlay && matSelectOverlay.contains(clickedElement)) // Prevent hiding on dropdown click
        ) {
            if (this.selectedAnnotation) {
                // Remove highlight from the previously selected annotation
                this.selectedAnnotation.element.style.border = '1px solid rgba(0, 0, 0, 0.5)';
                this.selectedAnnotation.element.style.boxShadow = 'none';
            }
            this.selectedAnnotation = null;
        }
    }

    private isClickOnAnnotation(element: HTMLElement): boolean {
        // Check if the clicked element is an annotation or inside an annotation.
        return this.annotations.some((annotation) => annotation.element.contains(element));
    }

    handlePdfClick(event: MouseEvent): void {
        if (!this.selectedTool) return;

        const coordinates = this.getAnnotationCoordinates(event);

        if (!coordinates) return;

        // Pass the page number to addAnnotation
        this.addAnnotation(this.selectedTool, coordinates.x, coordinates.y, coordinates.page);

        // Reset the tool and cursor
        this.selectedTool = null;
        this.pdfContainer.nativeElement.style.cursor = 'default';
    }

    getAnnotationCoordinates(event: MouseEvent): { x: number; y: number; page: number } | null {
        const pdfPages = Array.from(this.pdfContainer.nativeElement.querySelectorAll('.pdf-page')) as HTMLElement[];

        for (let i = 0; i < pdfPages.length; i++) {
            const pageRect = pdfPages[i].getBoundingClientRect();
            if (
                event.clientY >= pageRect.top &&
                event.clientY <= pageRect.bottom &&
                event.clientX >= pageRect.left &&
                event.clientX <= pageRect.right
            ) {
                const x = event.clientX - pageRect.left;
                const y = event.clientY - pageRect.top;
                const page = i + 1; // Pages are 1-based

                return { x, y, page };
            }
        }

        console.error('Failed to determine clicked page.');
        return null;
    }

    private async renderPDF(file: File): Promise<void> {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
            const typedArray = new Uint8Array(e.target?.result as ArrayBuffer);
            const pdf = await pdfjsLib.getDocument(typedArray).promise;

            this.totalPages = Array.from({ length: pdf.numPages }, (_, i) => i + 1); // Populate total pages

            if (!this.pdfContainer?.nativeElement) return;

            const pdfContainer = this.pdfContainer.nativeElement;
            pdfContainer.innerHTML = ''; // Clear previous content

            const pdfWidth = 800; // Fixed PDF width
            // pdfContainer.style.width = `${pdfWidth}px`;
            // pdfContainer.style.margin = '0 auto'; // Center align
            pdfContainer.style.border = '1px solid #ccc'; // Border around PDF container

            for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const viewport = page.getViewport({ scale: pdfWidth / page.getViewport({ scale: 1 }).width });

                const canvas = document.createElement('canvas');
                canvas.width = viewport.width;
                canvas.height = viewport.height;

                const context = canvas.getContext('2d')!;
                await page.render({ canvasContext: context, viewport }).promise;

                const pageDiv = document.createElement('div');
                pageDiv.classList.add('pdf-page');
                pageDiv.style.position = 'relative';
                pageDiv.appendChild(canvas);

                pdfContainer.appendChild(pageDiv);

                // Add a bold line between pages
                if (i < pdf.numPages) {
                    const separator = document.createElement('div');
                    separator.style.height = '4px';
                    separator.style.backgroundColor = 'black';
                    separator.style.margin = '20px 0';
                    pdfContainer.appendChild(separator);
                }
            }
        };

        fileReader.readAsArrayBuffer(file);
    }

    private addAnnotation(type: "text" | "checkbox" | "date" | "esign" | "comment" | "initials" | "stamps", x: number, y: number, page?: number, dimensions?: { width: number; height: number }): void {
        const wrapper = document.createElement('div');

        wrapper.classList.add('annotation-wrapper');
        wrapper.style.position = 'absolute';
        wrapper.style.left = `${x}px`;
        wrapper.style.top = `${y}px`;
        wrapper.style.border = '2px solid $color-primary';
        wrapper.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
        wrapper.style.padding = '5px';
        wrapper.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
        wrapper.draggable = false;
        wrapper.style.opacity = '0.8';

        wrapper.onclick = (event) => {
            event.stopPropagation();
            this.selectAnnotation(wrapper, type);
        };

        let annotationContent: HTMLDivElement | null = null;
        if (type === 'esign') {
            annotationContent = document.createElement('div');
            annotationContent.textContent = 'Signature';
            annotationContent.style.width = dimensions?.width ? `${dimensions.width}px` : '190px';
            annotationContent.style.height = dimensions?.height ? `${dimensions.height}px` : '50px';
            annotationContent.style.textAlign = 'center';
            annotationContent.style.lineHeight = '50px';
            annotationContent.style.fontStyle = 'italic';
        } else if (type === 'initials') {
            annotationContent = document.createElement('div');
            annotationContent.textContent = 'Initial';
            annotationContent.style.width = dimensions?.width ? `${dimensions.width}px` : '80px';
            annotationContent.style.height = dimensions?.height ? `${dimensions.height}px` : '60px';
            annotationContent.style.textAlign = 'center';
            annotationContent.style.lineHeight = '50px';
            annotationContent.style.fontStyle = 'italic';
        } else if (type === 'stamps') {
            annotationContent = document.createElement('div');
            annotationContent.textContent = 'Stamp';
            annotationContent.style.width = dimensions?.width ? `${dimensions.width}px` : '60px';
            annotationContent.style.height = dimensions?.height ? `${dimensions.height}px` : '60px';
            annotationContent.style.textAlign = 'center';
            annotationContent.style.lineHeight = '50px';
            annotationContent.style.fontStyle = 'italic';
        } else if (type === 'text') {
            annotationContent = document.createElement('div');
            annotationContent.contentEditable = 'true';
            annotationContent.textContent = this.placeholder || 'Editable Text';
            annotationContent.style.width = dimensions?.width ? `${dimensions.width}px` : '150px';
            annotationContent.style.height = dimensions?.height ? `${dimensions.height}px` : '22px';
            annotationContent.style.fontSize = `${this.selectedFontSize}px`;
            annotationContent.style.outline = 'none';
        } else if (type === 'date') {
            annotationContent = document.createElement('div');
            annotationContent.style.width = dimensions?.width ? `${dimensions.width}px` : '140px';
            annotationContent.style.height = dimensions?.height ? `${dimensions.height}px` : '25px';
            annotationContent.innerHTML = `
                <input type="date" style="font-size: ${this.selectedFontSize}px;" placeholder="${this.placeholder || 'Select Date'}" />
            `;
        } else if (type === 'checkbox') {
            annotationContent = document.createElement('div');
            annotationContent.style.width = dimensions?.width ? `${dimensions.width}px` : '150px';
            annotationContent.style.height = dimensions?.height ? `${dimensions.height}px` : '20px';
            annotationContent.innerHTML = `
                <input type="checkbox"/>
                <span contenteditable="true" style="outline: none;">${this.placeholder || 'Editable Label'}</span>
            `;
        } else if (type === 'comment') {
            annotationContent = document.createElement('div');
            annotationContent.textContent = 'Comments';
            annotationContent.style.width = dimensions?.width ? `${dimensions.width}px` : '300px';
            annotationContent.style.height = dimensions?.height ? `${dimensions.height}px` : '100px';
            annotationContent.style.fontSize = `${this.selectedFontSize}px`;
            annotationContent.style.fontStyle = 'italic';
        }

        if (annotationContent) {
            annotationContent.style.pointerEvents = 'all';
            wrapper.appendChild(annotationContent);
        }

        const closeButton = document.createElement('div');
        closeButton.classList.add('close-btn');
        closeButton.style.position = 'absolute';
        closeButton.style.top = '-10px';
        closeButton.style.right = '-10px';
        closeButton.style.width = '20px';
        closeButton.style.height = '20px';
        closeButton.style.borderRadius = '50%';
        closeButton.style.backgroundColor = '#3f51b5';
        closeButton.style.color = 'white';
        closeButton.style.cursor = 'pointer';
        closeButton.style.display = 'flex';
        closeButton.style.alignItems = 'center';
        closeButton.style.justifyContent = 'center';
        closeButton.innerHTML = 'x';
        closeButton.onclick = (event) => {
            event.stopPropagation();
            wrapper.remove();
            if (this.selectedAnnotation && this.selectedAnnotation.element === wrapper) {
                this.selectedAnnotation = null;
                this.cdr.detectChanges();
            }
        };
        wrapper.appendChild(closeButton);

        interact(wrapper)
            .draggable({
                onmove: (event) => {
                    const target = event.target;
                    const x = (parseFloat(target.getAttribute('data-x')!) || 0) + event.dx;
                    const y = (parseFloat(target.getAttribute('data-y')!) || 0) + event.dy;

                    target.style.transform = `translate(${x}px, ${y}px)`;
                    target.setAttribute('data-x', x.toString());
                    target.setAttribute('data-y', y.toString());
                },
            })
            .resizable({
                edges: { left: true, right: true, top: true, bottom: true },
                listeners: {
                    move(event) {
                        const target = event.target;
                        const { width, height } = event.rect;
                        target.style.width = `${width}px`;
                        target.style.height = `${height}px`;

                        const x = (parseFloat(target.getAttribute('data-x')!) || 0) + event.deltaRect.left;
                        const y = (parseFloat(target.getAttribute('data-y')!) || 0) + event.deltaRect.top;

                        target.style.transform = `translate(${x}px, ${y}px)`;
                        target.setAttribute('data-x', x.toString());
                        target.setAttribute('data-y', y.toString());
                    },
                },
            });

        const pageElement = this.pdfContainer.nativeElement.querySelectorAll('.pdf-page')[(page || 1) - 1];
        if (pageElement) {
            pageElement.appendChild(wrapper); // Append the annotation directly to the page
        }
        // this.pdfContainer.nativeElement.appendChild(wrapper);
        const pageNumber = this.getPageNumberForAnnotation(wrapper);

        const defaultProperties = {
            placeholder: 'placeholder',
            fontSize: 14,
            user: this.tableService.tableInputData.length === 1 ? this.tableService.tableInputData[0].email : '',
            page: pageNumber || 1,
        };

        const annotation = {
            element: wrapper,
            type,
            properties: { ...defaultProperties },
        };

        this.annotations.push(annotation);
        this.selectAnnotation(wrapper, type);
    }

    submit(requesterDetails: {
        name: string;
        phone: string;
        email: string;
    }): void {
        const formattedData = {
            docContent: this.pdfBase64,
            docName: this.pdfFile?.name,
            ...(this.isDraftRequest ? {} : { appToken: this.appToken.Token }),
            requesterEmail: requesterDetails.email,
            requesterName: requesterDetails.name,
            requesterPhone: requesterDetails.phone,
            ...(this.isDraftRequest ? { draftSignRequestId: this._activatedRoute.snapshot.params["requestID"] } : {}),
            description: "Test03March",
            signers: this.tableService.tableInputData.map((user, index) => {
                // Filter annotations that belong to this user
                const userAnnotations = this.annotations
                    .filter(annotation => annotation.properties.user === user.email)
                    .map(annotation => {
                        const pageElement = annotation.element.closest('.pdf-page') as HTMLElement;

                        if (!pageElement) {
                            console.warn('Page element not found for annotation:', annotation);
                            return null;
                        }

                        const formatValue = (value: number) => parseFloat(value.toFixed(14));

                        // Get annotation position relative to the page
                        const annotationRect = annotation.element.getBoundingClientRect();
                        const pageRect = pageElement.getBoundingClientRect();

                        const x = ((annotationRect.left - pageRect.left) / pageRect.width) * 100;
                        const y = ((annotationRect.top - pageRect.top) / pageRect.height) * 100;
                        const width = (annotationRect.width / pageRect.width) * 100;
                        const height = (annotationRect.height / pageRect.height) * 100;

                        // Return values with cleaned precision
                        return {
                            Type: annotation.type.charAt(0).toUpperCase() + annotation.type.slice(1),
                            XLocation: formatValue(x),
                            YLocation: formatValue(y),
                            Width: formatValue(width),
                            Height: formatValue(height),
                            Text: annotation.type === 'text' || annotation.type === 'esign' || annotation.type === 'comment'
                                ? annotation.element.querySelector('div')?.textContent || ''
                                : annotation.type === 'checkbox'
                                    ? annotation.element.querySelector('input')?.checked || false
                                    : '',
                            Page: annotation.properties.page || 1
                        };
                    })
                    .filter(annotation => annotation !== null);

                const tagsString = userAnnotations.map(tag => JSON.stringify(tag)).join(',');

                return {
                    signerName: user.name,
                    signerEmail: user.email,
                    order: index + 1,
                    SignerRole: user.role,
                    phone: user.phone,
                    id: user.id ?? null,
                    Tags: tagsString
                };
            }),
            callbackUrl: "abc",
        };
        this._appService.updatePageLoaderVisibility(true, 'Submitting data...');

        // Create endpoint using HttpService
        const apiUrl = this.httpService.createEndpoint(
            Constants.API_URI_SIGN_REQUEST_STR,
            Constants.API_ENDPOINT
        );

        // Use HttpService to send a POST request
        this.httpService.post(apiUrl, formattedData).subscribe({
            next: (response) => {
                this._appService.updatePageLoaderVisibility(false);
                this.onSubmitComplete.emit();
            },
            error: (error) => {
                this.httpService.showHttpError(error);
                this._appService.updatePageLoaderVisibility(false);
            }
        });
    }

    private convertPdfToBase64(): void {
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64WithPrefix = event.target?.result as string;
            // Remove the prefix (data:application/pdf;base64,)
            this.pdfBase64 = base64WithPrefix.split(',')[1];
        };
        reader.readAsDataURL(this.pdfFile!);
    }

    selectAnnotation(wrapper: HTMLElement, type: string): void {
        // Remove highlight from all annotations
        this.annotations.forEach((annotation) => {
            annotation.element.style.border = '1px solid rgba(0, 0, 0, 0.5)';
            annotation.element.style.boxShadow = 'none';
        });

        // Highlight the selected annotation
        const annotation = this.annotations.find((a) => a.element === wrapper);
        if (annotation) {
            this.selectedAnnotation = annotation;
            this.selectedAnnotation.element.style.border = '2px solid #3f51b5';
            this.selectedAnnotation.element.style.boxShadow = '0 0 8px #3f51b5';
        }
    }

    copyAnnotationToPages(option: string): void {
        if (!this.selectedAnnotation) return;

        const currentAnnotation = this.selectedAnnotation;
        const annotationType = currentAnnotation.type;
        const currentPage = currentAnnotation.properties.page;

        const pagesToCopy =
            option === 'all'
                ? this.totalPages
                : option === 'even'
                    ? this.totalPages.filter((page) => page % 2 === 0)
                    : option === 'odd'
                        ? this.totalPages.filter((page) => page % 2 !== 0)
                        : [parseInt(option, 10)];

        const pdfPages = Array.from(this.pdfContainer.nativeElement.querySelectorAll('.pdf-page')) as HTMLElement[];

        for (const page of pagesToCopy) {
            if (page !== currentPage) {
                const pageElement = pdfPages[page - 1]; // Get the target page element
                if (!pageElement) {
                    console.error(`Page ${page} not found.`);
                    continue; // Skip to the next page
                }

                // Calculate the relative position of the annotation
                const currentAnnotationRect = currentAnnotation.element.getBoundingClientRect();
                const currentPageElement = pdfPages[currentPage - 1];
                const currentPageRect = currentPageElement.getBoundingClientRect();
                const targetPageRect = pageElement.getBoundingClientRect();

                const x = currentAnnotationRect.left - currentPageRect.left; // Horizontal offset
                const y = currentAnnotationRect.top - currentPageRect.top;   // Vertical offset within the current page

                // Capture the width and height of the current annotation
                const width = currentAnnotationRect.width;
                const height = currentAnnotationRect.height;

                // Add the annotation to the new page with matching dimensions
                this.addAnnotation(annotationType, x, y, page, { width, height });
            }
        }
    }

    updateAnnotationProperty(property: string, value: any): void {
        if (this.selectedAnnotation && this.selectedAnnotation.properties) {
            this.selectedAnnotation.properties[property] = value;

            const contentElement = this.selectedAnnotation.element.querySelector('div');
            if (property === 'fontSize' && contentElement) {
                contentElement.style.fontSize = `${value}px`;
            } else if (property === 'placeholder' && contentElement) {
                if (this.selectedAnnotation.type === 'text'
                    || this.selectedAnnotation.type === 'esign'
                    || this.selectedAnnotation.type === 'comment'
                    || this.selectedAnnotation.type === 'initials'
                    || this.selectedAnnotation.type === 'stamps'
                ) {
                    contentElement.textContent = value;
                } else if (this.selectedAnnotation.type === 'checkbox') {
                    const label = contentElement.querySelector('span');
                    if (label) label.textContent = value;
                } else if (this.selectedAnnotation.type === 'date') {
                    const input = contentElement.querySelector('input');
                    if (input) input.placeholder = value;
                }
            }

            // Update the annotations array to maintain properties uniquely
            const annotationIndex = this.annotations.findIndex((a) => a.element === this.selectedAnnotation.element);
            if (annotationIndex !== -1) {
                this.annotations[annotationIndex] = this.selectedAnnotation;
            }
        }
    }

    private getPageNumberForAnnotation(element: HTMLElement): number | null {
        const pages = Array.from(this.pdfContainer.nativeElement.querySelectorAll('.pdf-page'));

        for (let i = 0; i < pages.length; i++) {
            const pageElement = pages[i];
            const pageRect = pageElement.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();

            // Check if the annotation is within the bounds of this page
            if (
                elementRect.top >= pageRect.top &&
                elementRect.bottom <= pageRect.bottom &&
                elementRect.left >= pageRect.left &&
                elementRect.right <= pageRect.right
            ) {
                return i + 1; // Pages are 1-indexed
            }
        }

        return null;
    }

    goToPrevious(): void {
        this.previousStep.emit(); // Emit an event to the parent
    }

    protected readonly HTMLInputElement = HTMLInputElement;
}
