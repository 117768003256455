import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSort, MatSortModule, SortDirection } from "@angular/material/sort";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ITableActionRow } from "@modals/app.modal";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CommonModule } from "@angular/common";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TableDynamicInputComponent } from "../table-dynamic-input/table-dynamic-input.component";
import { TableService } from "@services/table.service";

@Component({
  selector: "kzn-table",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    CommonModule,
    TableDynamicInputComponent
  ],
  templateUrl: "./table.component.html",
  styleUrl: "./table.component.scss",
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements AfterViewInit, OnChanges {
  @Input({ required: true }) data!: any[];
  @Input({ required: true }) columns!: any[];
  @Input() showRefresh: boolean = false;
  @Input() showSearch: boolean = true;
  @Input() showPagination: boolean = true;
  @Input() showExpandedRows: boolean = false;
  @Input() defaultSortColumnDef: string = "";
  @Input() defaultSortDirection: SortDirection = "";
  @Input() rowActions: Array<ITableActionRow> = [];
  @Output() refreshEventFired: EventEmitter<null> = new EventEmitter();
  public pageSizeOptions = [5, 10, 25, 50];
  public displayedColumns!: string[];
  public dataSource!: MatTableDataSource<any>;
  public expandedElement: any;

  private _cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild("input", { read: ElementRef }) tableInput!: ElementRef;
  scrollUp(): void {
    setTimeout(() =>
      this.tableInput.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
      })
    );
  }

  constructor(public tableService: TableService) { }

  ngOnChanges(): void {
    if (this.data && this.columns) {
      this.displayedColumns = this.columns.map((c) => c.columnDef);
      if (this.showExpandedRows) this.displayedColumns.unshift('expand');
      this.rowActions.length && this.displayedColumns.push("actions"); // Add Action column conditionally
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.sortingDataAccessor = (data, columnDef) => {
        const isDate = this.columns.find(
          (column) => column.columnDef === columnDef
        )?.isDate;
        if (isDate) return new Date(data[columnDef]);
        else if (typeof data[columnDef] === 'string') return data[columnDef]?.toLowerCase();
        else return data[columnDef];
      };
      this.updatePaginatorAndSort();
    }
  }

  ngAfterViewInit() {
    this.updatePaginatorAndSort();
    this._cd.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private updatePaginatorAndSort() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onActionClick(action: any, row: any) {
    action.actionFn(row);
  }

  refreshTableData() {
    this.refreshEventFired.emit();
  }

  onTableDynamicInputValueUpdate(value: string, key: string, index: number) {
    this.tableService.tableInputData[index][key] = value;
    this.tableService.tableInputData = [...this.tableService.tableInputData];
  }
}
