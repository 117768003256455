import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { IUserDetails } from "@modals/app.modal";
import { AuthService } from "@services/auth.service";
import { HttpService } from "@services/http.service";
import { Constants } from "@config/constants";
import { StatusMessageComponent } from "@components/layouts/status-message/status-message.component";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { ToastService } from "@services/toast.service";
import { Messages } from "@config/messages";

@Component({
  selector: "kzn-header",
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    LoaderComponent,
  ],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input({ required: true }) sidebarRef: any;
  public userDetails: IUserDetails | undefined;
  public isLoggingOut: boolean = false;

  constructor(
    private _authService: AuthService,
    private _httpService: HttpService,
    private _tostr: ToastService
  ) {}

  ngOnInit(): void {
    this.userDetails = this._authService.getUserDetails();
  }

  toggleSidebar() {
    this.sidebarRef.toggle();
  }

  logout(): void {
    this.isLoggingOut = true;
    this._httpService
      .post(this._httpService.createEndpoint(Constants.API_URI_LOGOUT))
      .subscribe(
        (response) => {
          this.isLoggingOut = false;
          this._authService.afterLogoutSuccess();
          this._tostr.showSuccess(Messages.MSG_SUCCESS_LOGOUT);
        },
        (error) => {
          this._httpService.showHttpError(error);
          this.isLoggingOut = false;
        }
      );
  }
}
