@if(isLoading) {
<kzn-loader message="Getting email templates..."></kzn-loader>
} @else if(isError) {
<kzn-status-message [message]="errorMessage"></kzn-status-message>
} @else if(!isEdit && allEmailTemplates.length >= Constants.MAX_EMAIL_TEMPLATE_COUNT) {
<kzn-status-message [message]="Messages.MSG_INFO_MAX_EMAIL_TEMPLATE_LIMIT_REACHED"></kzn-status-message>
} @else if(isEdit && !selectedEmailTemplate) {
<kzn-status-message [message]="Messages.MSG_ERROR_EMAIL_TEMPLATE_NOT_EXIST"></kzn-status-message>
} @else {
<mat-card class="mat-elevation-z8 pd-1 new-email-template__container" [formGroup]="emailTemplateForm">
    <div class="new-file-upload-container">
        <div class="file-upload-container">
            <span class="file-upload-title">Logo to upload</span>
            <button type="button" mat-raised-button color="primary" (click)="emailLogoRef.click()"
                [disabled]="isCreatingUpdatingEmailtemplate">
                <mat-icon>upload</mat-icon>
                @if(emailTemplateForm.get('emailLogo')?.value?.fileName) { Change file }
                @else { Select file }
            </button>
            <span class="info-text">Allowed file types are png, jpg and jpeg</span>
            @if(emailTemplateForm.get('emailLogo')?.touched && emailTemplateForm.get('emailLogo')?.errors) {
            <div class="invalid">{{ Messages.MSG_ERROR_REQUIRED }}</div>
            }
        </div>

        @if(emailTemplateForm.get('emailLogo')?.value?.fileName) {
        <div class="selected-file-container">
            <mat-icon class="success">check_circle</mat-icon>
            <span class="file-upload-title">{{emailTemplateForm.get('emailLogo')?.value?.fileName}}</span>
        </div>
        }
        <input #emailLogoRef hidden type="file" accept="image/png, image/jpeg, image/jpg"
            (change)="onFileSelected($event)" />
    </div>

    <mat-form-field appearance="outline">
        <mat-label>Email template name</mat-label>
        <input matInput placeholder="Enter email template name" formControlName="emailTemplateName" />
        <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Email body header</mat-label>
        <textarea matInput placeholder="Enter email body header" formControlName="emailBodyHeader"></textarea>
        <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Email signature</mat-label>
        <textarea matInput placeholder="Enter email signature" formControlName="emailSignature"></textarea>
        <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>

    <button type="button" mat-raised-button color="primary" class="btn-new-email-template-submit" (click)="onSubmit()"
        [class.spinner]="isCreatingUpdatingEmailtemplate" [disabled]="isCreatingUpdatingEmailtemplate">
        Submit
    </button>
</mat-card>
}