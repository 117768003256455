import { Component, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { DialogComponent } from "@components/layouts/dialog/dialog.component";
import { Constants } from "@config/constants";
import { Messages } from "@config/messages";
import { ICreateAppTokenRequest, ICustomer } from "@modals/api.modal";
import { IUserDetails } from "@modals/app.modal";
import { AuthService } from "@services/auth.service";
import { HttpService } from "@services/http.service";
import { ToastService } from "@services/toast.service";

@Component({
  selector: "kzn-create-token",
  standalone: true,
  imports: [
    DialogComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
  ],
  templateUrl: "./create-token.component.html",
  styleUrl: "./create-token.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class CreateTokenComponent implements OnInit {
  private _authService = inject(AuthService);
  private _httpService = inject(HttpService);
  private _toastr = inject(ToastService);
  public isCreatingToken: boolean = false;
  public isLoadingCustomers: boolean = false;
  public isAdmin: boolean = this._authService.isAdminUser();
  public userDetails: IUserDetails | undefined =
    this._authService.getUserDetails();
  public customers: Array<ICustomer> = [];
  public appName: FormControl = new FormControl("", Validators.required);
  public customer: FormControl = new FormControl("", Validators.required);
  public Messages = Messages;

  constructor(private dialogRef: MatDialogRef<CreateTokenComponent>) {}

  ngOnInit(): void {
    if (this.isAdmin) {
      this.getAllCustomers();
    } else {
      this.customer.setValue(this.userDetails?.CustomerId);
    }
  }

  public getAllCustomers() {
    this.isLoadingCustomers = true;
    this.updateControlsStatus();
    this._httpService
      .get(this._httpService.createEndpoint(Constants.API_URI_CUSTOMER_GET_ALL))
      .subscribe(
        (response: any) => {
          this.isLoadingCustomers = false;
          this.customers = response.Data;
          this.customers.sort((a, b) =>
            a.CustomerName.localeCompare(b.CustomerName)
          );
          this.updateControlsStatus();
        },
        (error) => {
          this.isLoadingCustomers = false;
          this._httpService.showHttpError(error);
          this.updateControlsStatus();
        }
      );
  }

  public createToken() {
    this.markControlsAsTouched();
    if (this.appName.valid && this.customer.valid) {
      const request: ICreateAppTokenRequest = {
        AppName: this.appName.value,
        CustomerId: this.customer.value,
      };
      this.isCreatingToken = true;
      this.updateControlsStatus();

      this._httpService
        .post(
          this._httpService.createEndpoint(Constants.API_URI_APP_TOKEN_CREATE),
          request
        )
        .subscribe(
          (response) => {
            this.isCreatingToken = false;
            this._toastr.showSuccess(Messages.MSG_SUCCESS_CREATE_TOKEN);
            this.dialogRef.close({ isSuccess: true });
          },
          (error) => {
            this.isCreatingToken = false;
            this._httpService.showHttpError(error);
          }
        );
    }
  }

  private markControlsAsTouched() {
    !this.appName.touched && this.appName.markAsTouched();
    !this.customer.touched && this.customer.markAsTouched();
  }

  private updateControlsStatus() {
    this.appName.disabled ? this.appName.enable() : this.appName.disable();
    this.customer.disabled ? this.customer.enable() : this.customer.disable();
  }
}
