import { Component, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { HeaderComponent } from "@components/layouts/header/header.component";
import { SidebarComponent } from "@components/layouts/sidebar/sidebar.component";
import { Constants } from "@config/constants";
import { HttpService } from "@services/http.service";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { AuthService } from "@services/auth.service";

@Component({
  selector: "kzn-layout-after-login",
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidebarComponent, LoaderComponent],
  providers: [HttpService],
  templateUrl: "./layout-after-login.component.html",
  styleUrl: "./layout-after-login.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class LayoutAfterLoginComponent implements OnInit {
  private _httpService = inject(HttpService);
  private _authService = inject(AuthService);
  public isGettingUserDetails: boolean = true;

  ngOnInit(): void {
    this.getUserDetails();
  }

  private getUserDetails(): void {
    this._httpService
      .get(this._httpService.createEndpoint(Constants.API_URI_USER_DETAILS))
      .subscribe(
        (response: any) => {
          this._authService.setUserDetails(response.Data);
          this.isGettingUserDetails = false;
        },
        (error) => {
          this._httpService.showHttpError(error);
          this._authService.afterLogoutSuccess();
          this.isGettingUserDetails = false;
        }
      );
  }
}
