import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import {
  AnnotationLayerRenderedEvent,
  NgxExtendedPdfViewerModule,
  NgxExtendedPdfViewerService,
  PageViewModeType,
  PagesLoadedEvent,
} from "ngx-extended-pdf-viewer";
import { Constants } from "@config/constants";
import { MatDialog } from "@angular/material/dialog";
import { UploadSignComponent } from "@components/upload-sign/upload-sign.component";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { HttpService } from "@services/http.service";
import { ActivatedRoute } from "@angular/router";
import { ToastService } from "@services/toast.service";
import { Messages } from "@config/messages";
import { MatButtonModule } from "@angular/material/button";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { StatusMessageComponent } from "@components/layouts/status-message/status-message.component";
import FileSaver from "file-saver";
import { SignService } from "@services/sign.service";
import { IDeclineSignRequest, ISignUploadRequest } from "@modals/api.modal";
import { EnumRequestStatus, EnumSignerRoleType, EnumSignTagTypes } from "@modals/app.modal";
import { MatCardModule } from "@angular/material/card";
import { AppService } from "@services/app.service";
import { MatMenuModule } from "@angular/material/menu";
import { HeaderBeforeLoginService } from "@services/services/header-before-login.service";

@Component({
  selector: "kzn-sign",
  standalone: true,
  imports: [
    NgxExtendedPdfViewerModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    LoaderComponent,
    StatusMessageComponent,
    MatCardModule,
    MatMenuModule,
  ],
  providers: [HttpService],
  templateUrl: "./sign.component.html",
  styleUrl: "./sign.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SignComponent implements OnInit {
  base64!: string;
  isDownloading: boolean = true;
  isSubmitted: boolean = false;
  isDeclined: boolean = false;
  isSigned: boolean = false;
  isError: boolean = false;
  fileName: string = "";
  isView: boolean = false;
  statusMessage!: {
    heading: string;
    message: string;
    isSuccess: boolean;
  };
  pageViewMode: PageViewModeType = "infinite-scroll";
  signerRole: string = '';
  EnumSignerRoleType = EnumSignerRoleType;
  EnumSignTagTypes = EnumSignTagTypes;
  customSignTags: Array<{
    label: string;
    icon: string;
    type: EnumSignTagTypes;
    dimensions: {
      width: string;
      height: string;
    }
  }> = [
      {
        label: 'Initials',
        icon: 'short_text',
        type: EnumSignTagTypes.INITIALS,
        dimensions: {
          height: '48px',
          width: '140px'
        }
      },
      {
        label: 'Stamps',
        icon: 'check_circle',
        type: EnumSignTagTypes.STAMPS,
        dimensions: {
          height: '60px',
          width: '140px'
        }
      },
      {
        label: 'Text',
        icon: 'text_fields',
        type: EnumSignTagTypes.TEXT,
        dimensions: {
          height: '48px',
          width: '140px'
        }
      },
      {
        label: 'Date',
        icon: 'calendar_today',
        type: EnumSignTagTypes.DATE,
        dimensions: {
          height: '48px',
          width: '140px'
        }
      },
      {
        label: 'Checkbox',
        icon: 'check_box',
        type: EnumSignTagTypes.CHECKBOX,
        dimensions: {
          height: '48px',
          width: '140px'
        }
      }
    ];
  private _pagesCount!: number;
  private _pageNumber!: number;
  private _fileRequestID: string = "";
  private _signerID: string = "";
  private _selectedSpan: HTMLElement | undefined;
  private _signatureTag: string = "";
  private _requiredSignatureHereCount: any = {};
  private _pdfService = inject(NgxExtendedPdfViewerService);
  private _httpService = inject(HttpService);
  private _activatedRoute = inject(ActivatedRoute);
  private _toastService = inject(ToastService);
  private _headerBeforeLoginService = inject(HeaderBeforeLoginService);

  constructor(
    private dialog: MatDialog,
    private _viewContainerRef: ViewContainerRef,
    private _signService: SignService,
    private _appService: AppService
  ) { }

  ngOnInit(): void {
    this.isView = this._activatedRoute.snapshot.data["isView"] ?? false;
    this._fileRequestID = this._activatedRoute.snapshot.params["requestID"];
    this._signerID = this._activatedRoute.snapshot.params["signerID"];
    const params = {
      SignRequestId: this._fileRequestID,
      ...(!this.isView ? { SignerId: this._signerID } : {}),
    };
    this._httpService
      .get(
        this._httpService.createEndpoint(
          this.isView
            ? Constants.API_URI_SIGN_DOWNLOAD_VIEW
            : Constants.API_URI_SIGN_DOWNLOAD_SIGN,
          undefined,
          params
        )
      )
      .subscribe(
        (response: any) => {
          this.base64 = response.Data?.DocContent;
          this._signService.signTags = response.Data?.Tags ? JSON.parse(`[${response.Data?.Tags}]`) : [];
          const signerNameInitials = response.Data?.SignerName?.match(/\b(\w)/g)?.join('')?.trim()?.toUpperCase()?.substring(0, 2);
          this._headerBeforeLoginService.updateUserInitials(signerNameInitials);
          this.signerRole = response.Data?.SignerRole;
          this.isSigned =
            response.Data?.RequestStatus === EnumRequestStatus.SIGNED ||
            (response.Data?.RequestStatus ===
              EnumRequestStatus.PARTIAL_SIGNED &&
              response.Data?.SignedDate);
          if (this.isSigned) {
            this.statusMessage = {
              heading:
                "Thank you for sharing your valuable time. You have already signed this document",
              message:
                "No further action required from your end. You will receive the signed copy once all the signers sign the document",
              isSuccess: true,
            };
          } else {
            this._signService.getSignerSignatures(this._fileRequestID, this._signerID);
          }
          this.fileName =
            response.Data?.DocName || `eSign-${this._fileRequestID}`;
          this._signatureTag =
            Constants.SIGNATURE_TAG_PREFIX + response.Data.Order;
          this.isDownloading = false;
        },
        (errorResponse) => {
          this.isDownloading = false;
          this.isError = true;
          this.statusMessage = {
            isSuccess: false,
            heading:
              errorResponse.error?.FailRules?.[0]?.Code === "NOSIGNREQUEST"
                ? "Thank you for sharing your valuable time. The link you are trying to access is Invalid"
                : "",
            message:
              errorResponse.error?.FailRules?.[0]?.Code === "NOSIGNREQUEST"
                ? "Kindly check your link and try again."
                : errorResponse.error?.FailRules?.[0]?.Msg ||
                Messages.MSG_ERROR_UNEXPECTED,
          };
        }
      );
  }

  downloadPDF() {
    this._pdfService.getCurrentDocumentAsBlob().then((data) => {
      FileSaver.saveAs(data, this.fileName);
    });
  }

  async signPDF() {
    let isInvalid: boolean = false;
    this._appService.updatePageLoaderVisibility(true);
    const signatures = await this._signService.getPDFSignatures();

    if (!signatures.length) {
      this._toastService.showError(Messages.MSG_ERROR_NO_SIGNATURE_ADDED);
      this._appService.updatePageLoaderVisibility(false);
      return;
    }

    const pagesWithSignatureHere = Object.keys(
      this._requiredSignatureHereCount
    );
    if (pagesWithSignatureHere.length) {
      pagesWithSignatureHere.some((pageIndex, index) => {
        const count = signatures.filter(
          (signature) => signature.pageIndex === +pageIndex
        ).length;
        const signHereElements = (
          document.getElementsByClassName("page")[+pageIndex] as HTMLElement
        ).getElementsByClassName("box-sign-here sign");
        if (
          count < this._requiredSignatureHereCount[pageIndex] ||
          signHereElements.length
        ) {
          isInvalid = true;
          this._toastService.showError(
            "All required signatures are not added at page number " +
            (+pageIndex + 1)
          );
          return true;
        } else return false;
      });
    }

    if (isInvalid) {
      this._appService.updatePageLoaderVisibility(false);
      return;
    }
    const request: ISignUploadRequest = {
      signRequestId: this._fileRequestID,
      signerId: this._signerID,
      signatures,
    };
    this._httpService
      .post(
        this._httpService.createEndpoint(Constants.API_URI_SIGN_UPLOAD),
        request
      )
      .subscribe(
        (response: any) => {
          this._appService.updatePageLoaderVisibility(false);
          this.statusMessage = {
            heading:
              "Thank you for sharing your valuable time & signing the document",
            message:
              "Your requestor has been notified, and you will receive the signed copy once all the signers sign the document.",
            isSuccess: true,
          };
          this.isSubmitted = true;
        },
        (error) => {
          this._appService.updatePageLoaderVisibility(false);
          this._httpService.showHttpError(error);
        }
      );
  }


  async decline() {
    this._appService.updatePageLoaderVisibility(true);
    const request: IDeclineSignRequest = {
      signRequestId: this._fileRequestID,
      signerId: this._signerID
    };

    this._httpService
      .post(
        this._httpService.createEndpoint(Constants.API_URI_DECLINE_SIGN_REQUEST),
        request
      )
      .subscribe(
        (response: any) => {
          this._appService.updatePageLoaderVisibility(false);
          this.statusMessage = {
            heading:
              "Thank you for sharing your valuable time",
            message:
              "This sign request has benn successfully declined.",
            isSuccess: true,
          };
          this.isDeclined = true;
        },
        (error) => {
          this._appService.updatePageLoaderVisibility(false);
          this._httpService.showHttpError(error);
        }
      );
  }

  openSignDialog(
    isComment: boolean = false,
    shouldResetSelectedSpan: boolean = false,
    isResponsiveContainer: boolean = false
  ): void {
    if (shouldResetSelectedSpan) this._selectedSpan = undefined;
    const dialogRef = this.dialog.open(UploadSignComponent, {
      data: {
        isComment,
        isResponsiveContainer
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.imageBase64) {
        if (this._selectedSpan) {
          this._signService.addSignImage(
            data.imageBase64,
            "0",
            "0",
            this._pageNumber ?? 0,
            this._viewContainerRef,
            false,
            data.isCommentOnly,
            data.signComment,
            this._selectedSpan,
            data.comment,
            data.signatureId,
            data.isResponsiveContainer
          );
          this._selectedSpan.className = "";
          this._selectedSpan = undefined;
        } else {
          this._signService.addSign(
            data.imageBase64,
            this._viewContainerRef,
            data.isCommentOnly,
            data.signComment,
            data.comment,
            data.signatureId,
            data.isResponsiveContainer
          );
        }
      }
    });
  }

  pagesLoaded(event: PagesLoadedEvent) {
    this._pagesCount = event.pagesCount;
  }

  onAnnotationLayerRendered(event: AnnotationLayerRenderedEvent): void {
    if (!this.isSigned && event.pageNumber === this._pagesCount) {
      this.pageViewMode = "multiple";
      setTimeout(() => {
        const { signHereElements, commentHereElements } =
          this._signService.generateSignHereElements(this._signatureTag);

        const addEvent = (element: HTMLElement, isComment: boolean = false) => {
          const spanPageNumber = element.getAttribute("page") ?? "0";
          const currentCount =
            this._requiredSignatureHereCount[spanPageNumber] ?? 0;
          this._requiredSignatureHereCount[spanPageNumber] = isComment
            ? currentCount
            : currentCount + 1;
          const img = element.getElementsByTagName("img")[0];
          img.addEventListener("click", () => {
            this._selectedSpan = element;
            this._pageNumber = +spanPageNumber;
            this.openSignDialog(isComment, false, element.classList.contains('sign-tag-comment-container'));
          });
        };

        signHereElements.forEach((span: HTMLElement) => {
          addEvent(span);
        });
        commentHereElements.forEach((span: HTMLElement) => {
          addEvent(span, true);
        });
      }, 100);
    }
  }

  activateClickMode(type: EnumSignTagTypes, dimensions: { height: string; width: string }) {
    const viewerEl = document.getElementById('viewer');
    const pages = viewerEl?.getElementsByClassName('page');
    if (pages?.length) {
      for (let i = 0; i < pages.length; i++) {
        const textLayers = pages[i].getElementsByClassName("textLayer");
        for (let j = 0; j < textLayers.length; j++) {
          textLayers[j].addEventListener('click', (event) => {
            this.generateSignTag(event, textLayers[j] as HTMLElement, type, dimensions);
          }, { once: true });
        }
      }
    }
  }

  generateSignTag(event: any, textLayer: HTMLElement, type: EnumSignTagTypes, dimensions: { height: string; width: string }) {
    const rect = event.target.getBoundingClientRect();
    const element = document.createElement('span');
    element.style.width = dimensions.width;
    element.style.height = dimensions.height;
    element.style.left = event.clientX - rect.left + 'px';
    element.style.top = event.clientY - rect.top + 'px';
    element.className = 'sign-tag-element-container';
    textLayer.appendChild(element);

    const onRemoveElement = () => {
      element.remove();
    }

    this._signService.renderTagElement(element, type, type, false, onRemoveElement);
  }
}
