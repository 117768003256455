import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import {
  AnnotationLayerRenderedEvent,
  NgxExtendedPdfViewerModule,
  NgxExtendedPdfViewerService,
  PageViewModeType,
  PagesLoadedEvent,
} from "ngx-extended-pdf-viewer";
import { Constants } from "@config/constants";
import { MatDialog } from "@angular/material/dialog";
import { UploadSignComponent } from "@components/upload-sign/upload-sign.component";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { HttpService } from "@services/http.service";
import { ActivatedRoute } from "@angular/router";
import { ToastService } from "@services/toast.service";
import { Messages } from "@config/messages";
import { MatButtonModule } from "@angular/material/button";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { StatusMessageComponent } from "@components/layouts/status-message/status-message.component";
import FileSaver from "file-saver";
import { SignService } from "@services/sign.service";
import { IDeclineSignRequest, ISignUploadRequest } from "@modals/api.modal";
import { EnumRequestStatus, EnumSignerRoleType, EnumSignTagTypes } from "@modals/app.modal";
import { MatCardModule } from "@angular/material/card";
import { AppService } from "@services/app.service";
import { MatMenuModule } from "@angular/material/menu";
import { HeaderBeforeLoginService } from "@services/services/header-before-login.service";
import { Subscription } from "rxjs";
import { DeclineSignRequestConfirmationComponent } from "@components/decline-sign-request-confirmation/decline-sign-request-confirmation.component";

@Component({
  selector: "kzn-sign",
  standalone: true,
  imports: [
    NgxExtendedPdfViewerModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    LoaderComponent,
    StatusMessageComponent,
    MatCardModule,
    MatMenuModule,
  ],
  providers: [HttpService],
  templateUrl: "./sign.component.html",
  styleUrl: "./sign.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SignComponent implements OnInit {
  base64!: string;
  isDownloading: boolean = true;
  isSubmitted: boolean = false;
  isDeclined: boolean = false;
  isSigned: boolean = false;
  isError: boolean = false;
  fileName: string = "";
  isView: boolean = false;
  statusMessage!: {
    heading: string;
    message: string;
    isSuccess: boolean;
  };
  pageViewMode: PageViewModeType = "infinite-scroll";
  signerRole!: EnumSignerRoleType;
  signerName: string = '';
  EnumSignerRoleType = EnumSignerRoleType;
  EnumSignTagTypes = EnumSignTagTypes;
  private _pagesCount!: number;
  private _pageNumber!: number;
  private _fileRequestID: string = "";
  private _signerID: string = "";
  private _selectedSpan: HTMLElement | undefined;
  private _signatureTag: string = "";
  private _order: string = "";
  private _signatureDynamicTag: string = "";
  private _requiredSignatureHereCount: any = {};
  private _pdfService = inject(NgxExtendedPdfViewerService);
  private _httpService = inject(HttpService);
  private _activatedRoute = inject(ActivatedRoute);
  private _toastService = inject(ToastService);
  private _headerBeforeLoginService = inject(HeaderBeforeLoginService);

  constructor(
    private dialog: MatDialog,
    private _viewContainerRef: ViewContainerRef,
    public signService: SignService,
    private _appService: AppService
  ) { }

  ngOnInit(): void {
    this.isView = this._activatedRoute.snapshot.data["isView"] ?? false;
    this._fileRequestID = this._activatedRoute.snapshot.params["requestID"];
    this._signerID = this._activatedRoute.snapshot.params["signerID"];
    const params = {
      SignRequestId: this._fileRequestID,
      ...(!this.isView ? { SignerId: this._signerID } : {}),
    };
    this._httpService
      .get(
        this._httpService.createEndpoint(
          this.isView
            ? Constants.API_URI_SIGN_DOWNLOAD_VIEW
            : Constants.API_URI_SIGN_DOWNLOAD_SIGN,
          undefined,
          params
        )
      )
      .subscribe(
        (response: any) => {
          this.base64 = response.Data?.DocContent;
          this.signService.signTags = response.Data?.Tags ? JSON.parse(`[${response.Data?.Tags}]`) : [];
          const signerNameInitials = response.Data?.SignerName?.match(/\b(\w)/g)?.join('')?.trim()?.toUpperCase()?.substring(0, 2);
          this._headerBeforeLoginService.updateUserInitials(signerNameInitials);
          this.signerRole = response.Data?.SignerRole;
          this.signerName = response.Data?.SignerName;
          this.signService.roleText = this.signerRole?.toLowerCase() === EnumSignerRoleType.REVIEWER.toLowerCase() ? 'Reviewer' : 'Signer';
          this.isSigned =
            response.Data?.RequestStatus === EnumRequestStatus.SIGNED ||
            (response.Data?.RequestStatus ===
              EnumRequestStatus.PARTIAL_SIGNED &&
              response.Data?.SignedDate);
          if (this.isSigned) {
            this.statusMessage = {
              heading:
                "Thank you for sharing your valuable time. You have already signed this document",
              message:
                "No further action required from your end. You will receive the signed copy once all the signers sign the document",
              isSuccess: true,
            };
          } else {
            this.signService.getSignerSignatures(this._fileRequestID, this._signerID);
          }
          this.fileName =
            response.Data?.DocName || `eSign-${this._fileRequestID}`;
          this._order = response.Data.Order?.toString();
          this._signatureTag =
            Constants.SIGNATURE_TAG_PREFIX + response.Data.Order;
          this._signatureDynamicTag =
            Constants.SIGNATURE_DYNAMIC_TAG_PREFIX + response.Data.Order;
          this.isDownloading = false;
        },
        (errorResponse) => {
          this.isDownloading = false;
          this.isError = true;
          this.statusMessage = {
            isSuccess: false,
            heading:
              errorResponse.error?.FailRules?.[0]?.Code === "NOSIGNREQUEST"
                ? "Thank you for sharing your valuable time. The link you are trying to access is Invalid"
                : "",
            message:
              errorResponse.error?.FailRules?.[0]?.Code === "NOSIGNREQUEST"
                ? "Kindly check your link and try again."
                : errorResponse.error?.FailRules?.[0]?.Msg ||
                Messages.MSG_ERROR_UNEXPECTED,
          };
        }
      );
  }

  downloadPDF() {
    this._pdfService.getCurrentDocumentAsBlob().then((data) => {
      FileSaver.saveAs(data, this.fileName);
    });
  }

  async signPDF() {
    let isInvalid: boolean = false;
    this._appService.updatePageLoaderVisibility(true);
    const signatures = await this.signService.getPDFSignatures();

    if (this.signerRole.toLowerCase() === EnumSignerRoleType.SIGNER.toLowerCase() && !signatures.length) {
      this._toastService.showError(Messages.MSG_ERROR_NO_SIGNATURE_ADDED);
      this._appService.updatePageLoaderVisibility(false);
      return;
    }

    const pagesWithSignatureHere = Object.keys(
      this._requiredSignatureHereCount
    );
    if (pagesWithSignatureHere.length) {
      pagesWithSignatureHere.some((pageIndex, index) => {
        const count = signatures.filter(
          (signature) => signature.pageIndex === +pageIndex
        ).length;
        const signHereElements = (
          document.getElementsByClassName("page")[+pageIndex] as HTMLElement
        ).getElementsByClassName("box-sign-here sign");
        if (
          count < this._requiredSignatureHereCount[pageIndex] ||
          signHereElements.length
        ) {
          isInvalid = true;
          this._toastService.showError(
            "All required signatures are not added at page number " +
            (+pageIndex + 1)
          );
          return true;
        } else return false;
      });
    }

    if (isInvalid) {
      this._appService.updatePageLoaderVisibility(false);
      return;
    }

    const request: ISignUploadRequest = {
      signRequestId: this._fileRequestID,
      signerId: this._signerID,
      signatures,
    };
    this._httpService
      .post(
        this._httpService.createEndpoint(Constants.API_URI_SIGN_UPLOAD),
        request
      )
      .subscribe(
        (response: any) => {
          this._appService.updatePageLoaderVisibility(false);
          this.statusMessage = {
            heading:
              "Thank you for sharing your valuable time & signing the document",
            message:
              "Your requestor has been notified, and you will receive the signed copy once all the signers sign the document.",
            isSuccess: true,
          };
          this.isSubmitted = true;
        },
        (error) => {
          this._appService.updatePageLoaderVisibility(false);
          this._httpService.showHttpError(error);
        }
      );
  }

  async decline(declineReason: string) {
    this._appService.updatePageLoaderVisibility(true);
    const request: IDeclineSignRequest = {
      signRequestId: this._fileRequestID,
      signerId: this._signerID,
      declineReason
    };

    this._httpService
      .post(
        this._httpService.createEndpoint(Constants.API_URI_DECLINE_SIGN_REQUEST),
        request
      )
      .subscribe(
        (response: any) => {
          this._appService.updatePageLoaderVisibility(false);
          this.statusMessage = {
            heading:
              "Thank you for sharing your valuable time",
            message:
              "This sign request has been successfully declined.",
            isSuccess: true,
          };
          this.isDeclined = true;
        },
        (error) => {
          this._appService.updatePageLoaderVisibility(false);
          this._httpService.showHttpError(error);
        }
      );
  }

  openDeclineConfirmationDialog() {
    const dialogRef = this.dialog.open(DeclineSignRequestConfirmationComponent);

    const dialogSubscription: Subscription = dialogRef.componentInstance.confirmed.subscribe((reasonForDecline) => {
      this.decline(reasonForDecline);
    })
  }

  openSignDialog(
    isComment: boolean = false,
    shouldResetSelectedSpan: boolean = false,
    isResponsiveContainer: boolean = false
  ): void {
    if (shouldResetSelectedSpan) this._selectedSpan = undefined;
    const dialogRef = this.dialog.open(UploadSignComponent, {
      data: {
        isComment,
        isResponsiveContainer,
        signerName: this.signerName
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.imageBase64) {
        if (this._selectedSpan) {
          this.signService.addSignImage(
            data.imageBase64,
            "0",
            "0",
            this._pageNumber ?? 0,
            this._viewContainerRef,
            false,
            data.isCommentOnly,
            data.signComment,
            this._selectedSpan,
            data.comment,
            data.signatureId,
            data.isResponsiveContainer
          );
          this._selectedSpan.className = "";
          this._selectedSpan = undefined;
        } else {
          this.signService.addSign(
            data.imageBase64,
            this._viewContainerRef,
            data.isCommentOnly,
            data.signComment,
            data.comment,
            data.signatureId,
            data.isResponsiveContainer
          );
        }
      }
    });
  }

  pagesLoaded(event: PagesLoadedEvent) {
    this._pagesCount = event.pagesCount;
  }

  nextSignTagHandler() {
    const signElements = document.querySelectorAll('.box-sign-here.sign');

    const pageHeight = (document.getElementsByClassName('textLayer')[0] as HTMLElement).offsetHeight;
    if (signElements.length === 0) return;

    let currentIndex = -1;

    signElements.forEach((el, index) => {
      if (el.classList.contains('current-sign')) {
        currentIndex = index;
        el.classList.remove('current-sign');
      }
    });

    const nextIndex = currentIndex + 1 < signElements.length ? currentIndex + 1 : 0;
    const nextSignElement = signElements[nextIndex] as HTMLElement;
    nextSignElement.classList.add('current-sign');
    nextSignElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

    const pageNumber = Number(nextSignElement.getAttribute('page') ?? 0);
    const nextButton = document.getElementById('btn__next');
    if (nextButton) {
      nextButton.style.top = `${(pageNumber * pageHeight) + nextSignElement.offsetTop + (nextSignElement.offsetHeight / 2)}px`;
    }
  }

  onAnnotationLayerRendered(event: AnnotationLayerRenderedEvent): void {
    if (this.isView) {
      const element = document.querySelector('ngx-extended-pdf-viewer .zoom') as HTMLElement;
      if (element) element.style.height = '100%';
    }

    if (!this.isSigned && event.pageNumber === this._pagesCount) {
      this.pageViewMode = "multiple";

      const outerContainer = document.getElementById('outerContainer');
      const footer = document.querySelector('.footer-sign');
      if (outerContainer) {
        outerContainer.style.position = 'fixed';
        outerContainer.style.height = `calc(100% - ${footer?.clientHeight! * 2}px)`;
      }

      setTimeout(() => {
        const nextEl = document.getElementById('btn__next');
        if (this.signerRole.toLowerCase() === EnumSignerRoleType.REVIEWER.toLowerCase()) {
          nextEl!.style.display = 'none';
        } else {
          const pdfViewer = document.getElementsByClassName('pdfViewer')[0];
          pdfViewer.appendChild(nextEl!)
          const textLayerRect = document.getElementsByClassName('textLayer')[0]?.getBoundingClientRect();
          if (nextEl && textLayerRect) {
            nextEl.style.left = `calc(${textLayerRect.left}px - 5.5rem - 20px)`;
            nextEl.style.top = '20px';
            nextEl.style.display = 'block';
          }
        }

        const { signHereElements, commentHereElements } =
          this.signService.generateSignHereElements(this._signatureTag, this._order, this.signerRole, this._signatureDynamicTag);

        const addEvent = (element: HTMLElement, isComment: boolean = false) => {
          const spanPageNumber = element.getAttribute("page") ?? "0";
          const currentCount =
            this._requiredSignatureHereCount[spanPageNumber] ?? 0;
          this._requiredSignatureHereCount[spanPageNumber] = isComment
            ? currentCount
            : currentCount + 1;
          const img = element.getElementsByTagName("img")[0];
          img.addEventListener("click", () => {
            this._selectedSpan = element;
            this._pageNumber = +spanPageNumber;
            this.openSignDialog(isComment, false, element.classList.contains('sign-tag-comment-container'));
          });
        };

        signHereElements.forEach((span: HTMLElement) => {
          addEvent(span);
        });
        commentHereElements.forEach((span: HTMLElement) => {
          addEvent(span, true);
        });
      }, 100);
    }
  }

  activateClickMode(type: EnumSignTagTypes, dimensions: { height: string; width: string }) {
    const viewerEl = document.getElementById('viewer');
    const cursorEl = document.createElement('span');
    cursorEl.style.position = 'absolute';
    cursorEl.innerText = 'Click on the pdf page to drop the element';
    viewerEl?.appendChild(cursorEl);

    const showTextWithCursor = (event: any) => {
      const rect = viewerEl?.getBoundingClientRect();
      cursorEl.style.left = event.clientX - (rect?.left ?? 0) + 10 + "px";
      cursorEl.style.top = event.clientY - (rect?.top ?? 0) + 10 + "px";
    }

    viewerEl?.addEventListener('mousemove', showTextWithCursor);

    const pages = viewerEl?.getElementsByClassName('page');
    if (pages?.length) {
      for (let i = 0; i < pages.length; i++) {
        const textLayers = pages[i].getElementsByClassName("textLayer");
        for (let j = 0; j < textLayers.length; j++) {
          textLayers[j].addEventListener('click', (event) => {
            viewerEl?.removeEventListener('mousemove', showTextWithCursor);
            viewerEl?.removeChild(cursorEl);
            this.generateSignTag(event, textLayers[j] as HTMLElement, type, dimensions);
          }, { once: true });
        }
      }
    }
  }

  generateSignTag(event: any, textLayer: HTMLElement, type: EnumSignTagTypes, dimensions: { height: string; width: string }) {
    const rect = event.target.getBoundingClientRect();
    const element = document.createElement('span');
    element.style.width = dimensions.width;
    element.style.height = dimensions.height;
    element.style.left = event.clientX - rect.left + 'px';
    element.style.top = event.clientY - rect.top + 'px';
    element.className = 'sign-tag-element-container';
    textLayer.appendChild(element);

    const onRemoveElement = () => {
      element.remove();
    }

    this.signService.renderTagElement(element, type === EnumSignTagTypes.CHECKBOX ? '' : type, type, false, onRemoveElement);
  }
}
