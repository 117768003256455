import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Constants } from '@config/constants';
import { ToastService } from './toast.service';
import { Messages } from '@config/messages';
import { IFailRule } from '@modals/api.modal';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private _http = inject(HttpClient);
  private _toastr = inject(ToastService)

  public createEndpoint(uri: string, baseUrl: string = Constants.API_ENDPOINT, params?: any) {
    let url = `${baseUrl}/${uri}?code=${Constants.API_AUTH_CODE}`;
    if (params && Object.keys(params)?.length) {
      Object.keys(params).forEach((key: string) => {
        url = `${url}&${key}=${params[key]}`;
      })
    }
    return url;
  }

  public showHttpError(errorResponse: any) {
    if (errorResponse?.error?.FailRules?.length) {
      errorResponse.error.FailRules.forEach((failRule: IFailRule) => {
        this._toastr.showError(failRule.Msg);
      })
    } else {
      this._toastr.showError(Messages.MSG_ERROR_UNEXPECTED);
    }
  }

  public get(url: string, options?: any) {
    return this._http.get(url, options);
  }

  public post(url: string, data?: any, options?: any) {
    return this._http.post(url, data, options);
  }

  public put(url: string, data: any, options?: any) {
    return this._http.put(url, data, options);
  }

  public delete(url: string, options?: any) {
    return this._http.delete(url, options);
  }
}
