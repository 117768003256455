import { Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderBeforeLoginComponent } from '@components/layouts/header-before-login/header-before-login.component';
import { FooterComponent } from '@components/layouts/footer/footer.component';

@Component({
  selector: 'kzn-layout-guest-sign',
  standalone: true,
  imports: [RouterOutlet, HeaderBeforeLoginComponent, FooterComponent],
  templateUrl: './layout-guest-sign.component.html',
  styleUrl: './layout-guest-sign.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LayoutGuestSignComponent {

}
