import { MediaMatcher } from "@angular/cdk/layout";
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from "@angular/router";
import { Subscription, filter, startWith } from "rxjs";
import { Title } from "@angular/platform-browser";
import { AuthService } from "@services/auth.service";
import { StatusMessageComponent } from "../status-message/status-message.component";
import { Messages } from "@config/messages";
import { FooterComponent } from "../footer/footer.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "kzn-sidebar",
  standalone: true,
  imports: [
    RouterOutlet,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    StatusMessageComponent,
    FooterComponent,
    MatButtonModule,
    MatTooltipModule,
  ],
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnDestroy {
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _changeDetectorRef = inject(ChangeDetectorRef);
  private _authService = inject(AuthService);
  private _media = inject(MediaMatcher);
  private _titleService = inject(Title);
  private _routerSubscription!: Subscription;

  @ViewChild("sidenav") sidenav!: any;
  mobileQuery: MediaQueryList;
  public Messages = Messages;
  public currentRoute: string = "";
  public pageTitle: string = "";
  public isAdminOnly: boolean = false;
  public isAdminUser: boolean = this._authService.isAdminUser();
  public menus: Array<any> = [
    {
      label: "Customers",
      url: "/customers",
      icon: "supervisor_account",
      show: this.isAdminUser,
    },
    {
      label: "My Requests",
      url: "/requests",
      icon: "open_in_browser",
      show: true,
    },
    {
      label: "Settings",
      url: "/settings",
      icon: "settings",
      show: true,
    },
    {
      label: "Email Templates",
      url: "/email-templates",
      icon: "email",
      show: true,
    },
    {
      label: "Blogs",
      url: "/blogs",
      icon: "local_library",
      show: true,
    },
  ];

  private _mobileQueryListener: () => void;

  constructor() {
    this._routerSubscription = this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        startWith(this._router)
      )
      .subscribe((event) => {
        this.currentRoute = this._router.url;
        this.isAdminOnly =
          this._activatedRoute.snapshot.firstChild?.data["isAdminOnly"] ??
          false;
        setTimeout(() => {
          const title = this._titleService.getTitle();
          this.updatePageTitle(title);
        });
      });

    this.mobileQuery = this._media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  updatePageTitle(title: string) {
    this.pageTitle = title.split("|")[0].trim();
  }

  toggle() {
    this.sidenav.toggle();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this._routerSubscription?.unsubscribe();
  }
}
