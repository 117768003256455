import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoaderComponent } from '@components/layouts/loader/loader.component';
import { StatusMessageComponent } from '@components/layouts/status-message/status-message.component';
import { Constants } from '@config/constants';
import { Messages } from '@config/messages';
import { ICreateEmailTemplateRequest, IEmailTemplate, IUpdateEmailTemplateRequest } from '@modals/api.modal';
import { HttpService } from '@services/http.service';
import { ToastService } from '@services/toast.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'kzn-create-edit-email-template',
  standalone: true,
  imports: [LoaderComponent, StatusMessageComponent, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, ReactiveFormsModule, MatIconModule, RouterModule, MatSlideToggleModule],
  templateUrl: './create-edit-email-template.component.html',
  styleUrl: './create-edit-email-template.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CreateEditEmailTemplateComponent implements OnInit, OnDestroy {
  private _httpService = inject(HttpService);
  private _toastr = inject(ToastService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _activatedRouteSubscription: Subscription | undefined;

  Messages = Messages;
  Constants = Constants;
  public isLoading: boolean = false;
  public isError: boolean = false;
  public errorMessage: string = '';
  public emailTemplateForm!: FormGroup;
  public isCreatingUpdatingEmailtemplate: boolean = false;
  public isEdit: boolean = false;
  public allEmailTemplates: Array<IEmailTemplate> = [];
  public emailTemplateID!: string;
  public selectedEmailTemplate: IEmailTemplate | undefined;

  @ViewChild('emailLogoRef') emailLogoRef!: ElementRef<HTMLInputElement>;

  constructor(private fb: FormBuilder) {
    this.emailTemplateForm = this.fb.group({
      emailTemplateName: new FormControl('', Validators.required),
      emailLogo: new FormControl({}, Validators.required),
      emailBodyHeader: new FormControl('', Validators.required),
      emailSignature: new FormControl('', Validators.required)
    });
    const templates = this._router.getCurrentNavigation()?.extras?.state?.['allEmailTemplates'];
    if (templates?.length) this.allEmailTemplates = [...templates];
    else this.getAllEmailTemplates();
  }

  ngOnInit(): void {
    this.emailTemplateID = this._activatedRoute.snapshot.params["emailTemplateID"];
    this._activatedRouteSubscription = this._activatedRoute.data.subscribe(data => {
      this.isEdit = data['isEdit'] ?? false;
      this.updateFormDefaultValues();
    });
  }

  updateFormDefaultValues(): void {
    this.selectedEmailTemplate = this.allEmailTemplates.find(emailTemplate => emailTemplate.ID === this.emailTemplateID);
    if (this.selectedEmailTemplate) {
      this.emailTemplateForm.patchValue({
        emailTemplateName: this.selectedEmailTemplate.TemplateName,
        emailLogo: JSON.parse(this.selectedEmailTemplate.LogoImageData),
        emailBodyHeader: this.selectedEmailTemplate.EmailHeader,
        emailSignature: this.selectedEmailTemplate.EmailSignature
      });
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.emailTemplateForm.patchValue({
          emailLogo: {
            fileName: file.name,
            image: base64String?.split("base64,")[1]
          }
        });
      };
      reader.readAsDataURL(file);
    }
  }

  onSubmit() {
    this.emailTemplateForm.markAllAsTouched();

    if (this.emailTemplateForm.valid) {
      this.emailTemplateForm.disable();
      const values = this.emailTemplateForm.value;
      const request: ICreateEmailTemplateRequest | IUpdateEmailTemplateRequest = {
        ...(this.isEdit && this.selectedEmailTemplate ? this.selectedEmailTemplate : {}),
        LogoImageData: JSON.stringify(values.emailLogo),
        EmailHeader: values.emailBodyHeader,
        EmailSignature: values.emailSignature,
        TemplateName: values.emailTemplateName
      };
      this.isCreatingUpdatingEmailtemplate = true;

      (this.isEdit ? this.editEmailtemplate(request as IUpdateEmailTemplateRequest) : this.createEmailTemplate(request as ICreateEmailTemplateRequest))
        .subscribe(
          (response: any) => {
            this.emailTemplateForm.enable();
            this.isCreatingUpdatingEmailtemplate = false;
            this._toastr.showSuccess(Messages.MSG_SUCCESS_CREATE_EMAIL_TEMPLATE);
            this._router.navigate(['email-templates']);
          },
          (errorResponse) => {
            this.emailTemplateForm.enable();
            this.isCreatingUpdatingEmailtemplate = false;
            this._httpService.showHttpError(errorResponse);
          }
        );
    }
  }

  createEmailTemplate(request: ICreateEmailTemplateRequest): Observable<any> {
    return this._httpService.post(this._httpService.createEndpoint(Constants.API_URI_EMAIL_TEMPLATE_CREATE), request);
  }

  editEmailtemplate(request: IUpdateEmailTemplateRequest): Observable<any> {
    return this._httpService.put(this._httpService.createEndpoint(Constants.API_URI_EMAIL_TEMPLATE_UPDATE), request);
  }

  getAllEmailTemplates(): void {
    this.isLoading = true;
    this._httpService
      .get(this._httpService.createEndpoint(Constants.API_URI_EMAIL_TEMPLATE_GET_ALL))
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isError = false;
          this.allEmailTemplates = response.Data;
          if (this.isEdit && this.emailTemplateID) this.updateFormDefaultValues();
        },
        (errorResponse) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage =
            errorResponse.error?.FailRules?.[0]?.Msg ||
            Messages.MSG_ERROR_UNEXPECTED;
        }
      );
  }

  ngOnDestroy(): void {
    this._activatedRouteSubscription?.unsubscribe();
  }
}
