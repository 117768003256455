import { Component, ViewEncapsulation, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { LoaderComponent } from '@components/layouts/loader/loader.component';
import { SignService } from '@services/sign.service';

@Component({
  selector: 'kzn-upload-sign-recent-uploads',
  standalone: true,
  imports: [MatRadioModule, MatCardModule, ReactiveFormsModule, LoaderComponent],
  templateUrl: './upload-sign-recent-uploads.component.html',
  styleUrl: './upload-sign-recent-uploads.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class UploadSignRecentUploadsComponent {
  recentUploadImgCtrl: FormControl = new FormControl({});
  onRecentUploadImageChange = output<{
    image: string,
    signatureId: string
  }>();

  constructor(public signService: SignService) { }

  onImageSelected() {
    this.onRecentUploadImageChange.emit({
      image: this.recentUploadImgCtrl.value.SignImagePath,
      signatureId: this.recentUploadImgCtrl.value.Id
    });
  }

  resetRecentUpload() {
    this.recentUploadImgCtrl.reset();
  }
}
