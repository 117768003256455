export const FONT_FAMILIES = [
    'arty-signature',
    'dr-sugiyama',
    'may-queen',
    'monsieur-la-doulaise',
    'mr-dafoe',
    'mr-de-haviland',
    'mrs-saint-delafield',
    'pwsignaturetwo',
    'sarina',
    'the-suavity'
];
