import { ApplicationRef, ComponentRef, createComponent, EmbeddedViewRef, Injectable, ViewContainerRef } from "@angular/core";
import { SignDragResizeComponent } from "@components/sign-drag-resize/sign-drag-resize.component";
import { Constants } from "@config/constants";
import { IGetSignerSignature, ISignUploadSignature } from "@modals/api.modal";
import * as htmlToImage from "html-to-image";
import { HttpService } from "./http.service";
import { EnumSignerRoleType, EnumSignTagTypes, ISignTag } from "@modals/app.modal";
import { SignTagElementComponent } from "@components/sign-tag-element/sign-tag-element.component";

@Injectable({
  providedIn: "root",
})
export class SignService {
  public isLoadingSignerSignatures: boolean = false;
  public signerSignatures: Array<IGetSignerSignature> = [];
  public signTags: Array<ISignTag> = [];
  public roleText: string = 'Signer';
  public customSignTags: Array<{
    label: string;
    icon: string;
    type: EnumSignTagTypes;
    dimensions: {
      width: string;
      height: string;
    }
  }> = [
      {
        label: 'Initials',
        icon: 'short_text',
        type: EnumSignTagTypes.INITIALS,
        dimensions: {
          height: '48px',
          width: '140px'
        }
      },
      {
        label: 'Stamp',
        icon: 'image',
        type: EnumSignTagTypes.STAMP,
        dimensions: {
          height: '60px',
          width: '140px'
        }
      },
      {
        label: 'Text',
        icon: 'text_fields',
        type: EnumSignTagTypes.TEXT,
        dimensions: {
          height: '48px',
          width: '140px'
        }
      },
      {
        label: 'Date',
        icon: 'calendar_today',
        type: EnumSignTagTypes.DATE,
        dimensions: {
          height: '48px',
          width: '140px'
        }
      },
      {
        label: 'Checkbox',
        icon: 'check_box',
        type: EnumSignTagTypes.CHECKBOX,
        dimensions: {
          height: '48px',
          width: '40px'
        }
      }
    ];

  constructor(
    private _httpService: HttpService,
    private _appRef: ApplicationRef) { }

  generateUUID(): string {
    var d = new Date().getTime();
    var d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16;
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }

  makeResizableElement(
    elementSelector: string,
    minHeight: number,
    minWidth: number
  ) {
    const element: HTMLElement | null = document.querySelector(elementSelector);
    const resizers = document.querySelectorAll(elementSelector + " .resizer");
    let original_width = 0;
    let original_height = 0;
    let original_x = 0;
    let original_y = 0;
    let original_mouse_x = 0;
    let original_mouse_y = 0;

    if (element) {
      for (let i = 0; i < resizers.length; i++) {
        const currentResizer = resizers[i];
        const resizeStart = (event: any) => {
          event.preventDefault();
          original_width = parseFloat(
            getComputedStyle(element, null)
              .getPropertyValue("width")
              .replace("px", "")
          );
          original_height = parseFloat(
            getComputedStyle(element, null)
              .getPropertyValue("height")
              .replace("px", "")
          );
          original_x = element.offsetLeft;
          original_y = element.offsetTop;
          const pageX =
            event.type === "touchstart"
              ? event.changedTouches[0].pageX
              : event.pageX;
          const pageY =
            event.type === "touchstart"
              ? event.changedTouches[0].pageY
              : event.pageY;
          original_mouse_x = pageX;
          original_mouse_y = pageY;
          window.addEventListener("mousemove", resize);
          window.addEventListener("mouseup", stopResize);
          window.addEventListener("touchmove", resize);
          window.addEventListener("touchend", stopResize);
        };

        currentResizer.addEventListener("mousedown", resizeStart);
        currentResizer.addEventListener("touchstart", resizeStart);

        const resize = (event: any) => {
          const pageX =
            event.type === "touchmove"
              ? event.changedTouches[0].pageX
              : event.pageX;
          const pageY =
            event.type === "touchmove"
              ? event.changedTouches[0].pageY
              : event.pageY;

          if (element) {
            if (currentResizer.classList.contains("bottom-right")) {
              const width = original_width + (pageX - original_mouse_x);
              const height = original_height + (pageY - original_mouse_y);
              if (width > minWidth) {
                element.style.width = width + "px";
              }
              if (height > minHeight) {
                element.style.height = height + "px";
              }
            } else if (currentResizer.classList.contains("bottom-left")) {
              const height = original_height + (pageY - original_mouse_y);
              const width = original_width - (pageX - original_mouse_x);
              if (height > minHeight) {
                element.style.height = height + "px";
              }
              if (width > minWidth) {
                element.style.width = width + "px";
                element.style.left =
                  original_x + (pageX - original_mouse_x) + "px";
              }
            } else if (currentResizer.classList.contains("top-right")) {
              const width = original_width + (pageX - original_mouse_x);
              const height = original_height - (pageY - original_mouse_y);
              if (width > minWidth) {
                element.style.width = width + "px";
              }
              if (height > minHeight) {
                element.style.height = height + "px";
                element.style.top =
                  original_y + (pageY - original_mouse_y) + "px";
              }
            } else {
              const width = original_width - (pageX - original_mouse_x);
              const height = original_height - (pageY - original_mouse_y);
              if (width > minWidth) {
                element.style.width = width + "px";
                element.style.left =
                  original_x + (pageX - original_mouse_x) + "px";
              }
              if (height > minHeight) {
                element.style.height = height + "px";
                element.style.top =
                  original_y + (pageY - original_mouse_y) + "px";
              }
            }
          }
        };

        const stopResize = () => {
          window.removeEventListener("mousemove", resize);
          window.removeEventListener("touchmove", resize);
        };
      }
    }
  }

  private async getElementImage(element: Element): Promise<string> {
    const commentOnlyInput = element.getElementsByClassName(
      Constants.SIGN_COMMENT_CLASS
    )[0] as HTMLElement;
    const imgStr = commentOnlyInput
      ? await htmlToImage.toPng(commentOnlyInput)
      : await element.getElementsByTagName("img")[0]?.src;
    return imgStr?.split("base64,")[1];
  }

  async getRequestDetails(signImagesOnPage: any, pageElement: any): Promise<any> {
    const pageRect = pageElement.getBoundingClientRect();
    const signImageRect = signImagesOnPage.getBoundingClientRect();
    const pageWidth = pageElement.clientWidth;
    const pageHeight = pageElement.clientHeight;
    const xPercent = ((signImageRect.x - pageRect.x) / pageWidth) * 100;
    const yPercent = ((signImageRect.y - pageRect.y) / pageHeight) * 100;
    const commentOnlyInput = signImagesOnPage.getElementsByClassName(
      Constants.SIGN_COMMENT_CLASS
    )[0];
    const ele = commentOnlyInput
      ? commentOnlyInput
      : signImagesOnPage.getElementsByTagName("img")[0];
    const signatureId = ele.getAttribute('signatureid') ?? '';
    const isValueAvailable = !!ele.querySelectorAll('[data-value-available="true"]')?.[0];
    const type = signImagesOnPage.querySelectorAll('[data-type]')?.[0]?.getAttribute('data-type') || '';
    if (!isValueAvailable && type !== 'SIGNATURE' && type !== 'COMMENT') return;
    const imgWidthPercent = (ele?.clientWidth / pageWidth) * 100;
    const imgHeightPercent = (ele?.clientHeight / pageHeight) * 100;
    const signComment =
      signImagesOnPage.getElementsByClassName("sign-comment")[0]
        ?.innerHTML || "";

    const suffixIcon = signImagesOnPage.getElementsByClassName("mat-mdc-form-field-icon-suffix");
    if (suffixIcon.length > 0) suffixIcon[0].style.display = "none";
    const signImgStr = signatureId ? '' : await this.getElementImage(signImagesOnPage);
    if (suffixIcon.length > 0) suffixIcon[0].style.display = "block";

    return {
      xPercent,
      yPercent,
      signImgStr,
      imgWidthPercent,
      imgHeightPercent,
      signComment,
      signatureId,
      type
    }
  }

  async getPDFSignatures(): Promise<Array<ISignUploadSignature>> {
    const request: Array<ISignUploadSignature> = [];
    const pages = document.getElementsByClassName("page");
    for (let i = 0; i < pages.length; i++) {
      const signImagesOnPage = pages[i].getElementsByClassName("cdk-drag");
      if (signImagesOnPage.length) {
        for (let j = 0; j < signImagesOnPage?.length; j++) {
          const requestObj = await this.getRequestDetails(signImagesOnPage[j], pages[i]);
          if (requestObj) request.push({ pageIndex: i, ...requestObj });
        }
      }
    }

    return request;
  }

  addSignImage(
    imageBase64: string,
    left: string,
    top: string,
    pageIndex: number = 0,
    viewContainerRef: ViewContainerRef,
    isDraggable: boolean = true,
    isCommentOnly: boolean = false,
    signComment?: string,
    signHereContainer?: HTMLElement,
    comment?: string,
    signatureId?: string,
    isResponsiveContainer?: boolean
  ) {
    const pages = document.getElementsByClassName("page");
    const element: HTMLElement = this.getSignDragResizeElement(
      imageBase64,
      left,
      top,
      viewContainerRef,
      isDraggable,
      isCommentOnly,
      signComment,
      comment,
      signatureId,
      isResponsiveContainer
    );
    if (signHereContainer) {
      const img = signHereContainer.getElementsByTagName("img")[0];
      if (img) img.style.display = "none";
      signHereContainer.appendChild(element);
    } else {
      pages[pageIndex].appendChild(element);
    }

    for (let i = 0; i < pages.length; ++i) {
      pages[i].removeEventListener("click", () => { });
      pages[i].removeEventListener("mousemove", () => { });
    }
  }

  getSignDragResizeElement(
    imageBase64: string,
    left: string,
    top: string,
    viewContainerRef: ViewContainerRef,
    isDraggable: boolean = true,
    isCommentOnly: boolean,
    signComment?: string,
    comment?: string,
    signatureId?: string,
    isResponsiveContainer?: boolean
  ): HTMLElement {
    const componentRef = viewContainerRef.createComponent(
      SignDragResizeComponent
    );
    componentRef.instance.imageBase64 = imageBase64;
    componentRef.instance.signComment = signComment;
    componentRef.instance.left = left;
    componentRef.instance.top = top;
    componentRef.instance.isCommentOnly = isCommentOnly;
    componentRef.instance.comment = comment;
    componentRef.instance.isDraggable = isDraggable;
    componentRef.instance.signatureId = signatureId ?? '';
    componentRef.instance.isResponsiveContainer = isResponsiveContainer;
    componentRef.instance.destroyComponent = () => {
      let htmlElement = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      if (
        htmlElement.parentElement &&
        htmlElement.parentElement.nodeName === "SPAN"
      )
        htmlElement.parentElement.className = "box-sign-here sign";
      componentRef.destroy();
    };
    return componentRef.location.nativeElement;
  }

  addSign(
    imageBase64: string,
    viewContainerRef: ViewContainerRef,
    isCommentOnly: boolean = false,
    signComment?: string,
    comment?: string,
    signatureId?: string,
    isResponsiveContainer?: boolean
  ) {
    let img: HTMLElement | undefined = document.createElement("img");
    img.setAttribute("src", imageBase64);
    img.style.position = "absolute";
    if (!isCommentOnly) {
      img.style.width = Constants.DEFAULT_SIGN_IMAGE_WIDTH;
      img.style.height = Constants.DEFAULT_SIGN_IMAGE_HEIGHT;
    }

    const textLayers = document.getElementsByClassName("textLayer");
    for (let i = 0; i < textLayers?.length; i++) {
      (textLayers[i] as HTMLElement).style.pointerEvents = "none";
    }

    const pages = document.getElementsByClassName("page");
    for (let i = 0; i < pages.length; i++) {
      pages[i].addEventListener("mouseenter", () => {
        if (img) {
          pages[i].appendChild(img);
        }
      });

      pages[i].addEventListener("mousemove", (event: any) => {
        if (img) {
          img!.style.left = event.layerX + 10 + "px";
          img!.style.top = event.layerY + 10 + "px";
        }
      });

      pages[i].addEventListener("click", (event: any) => {
        if (img) {
          this.addSignImage(
            imageBase64,
            img!.style.left,
            img!.style.top,
            i,
            viewContainerRef,
            true,
            isCommentOnly,
            signComment,
            undefined,
            comment,
            signatureId,
            isResponsiveContainer
          );
          pages[i].removeChild(img);
          img = undefined;

          for (let i = 0; i < textLayers?.length; i++) {
            (textLayers[i] as HTMLElement).style.pointerEvents = "all";
          }
        }
      });
    }
  }

  renderSignHereElement(element: HTMLElement): HTMLElement {
    element.innerHTML = "";
    element.className = "box-sign-here sign";
    const img = document.createElement("img");
    img.setAttribute("src", "./../assets/sign-here.png");
    img.className = "img-sign-here";
    img.style.width = "100%";
    img.style.height = "100%";
    img.style.display = "none";
    element.appendChild(img);

    return element;
  }

  renderCommentElement(element: HTMLElement, className: string = ''): HTMLElement {
    element.innerHTML = "";
    element.className = (`${className} box-sign-here`).trim();
    const img = document.createElement("img");
    img.setAttribute("src", "./../assets/comment-here.PNG");
    img.className = "img-sign-here";
    img.style.width = "100%";
    img.style.height = "100%";
    img.style.display = "none";
    element.appendChild(img);

    return element;
  }

  renderTagElement(element: HTMLElement, label: string, tag: EnumSignTagTypes, isCustomTagElementStatic = true, onRemoveElement?: () => void): HTMLElement {
    const componentRef: ComponentRef<SignTagElementComponent> = createComponent(
      SignTagElementComponent,
      {
        environmentInjector: this._appRef.injector,
      }
    );
    componentRef.instance.label = label;
    componentRef.instance.tag = tag;
    componentRef.instance.isCustomTagElementStatic = isCustomTagElementStatic;
    componentRef.instance.destroyComponent = onRemoveElement;

    this._appRef.attachView(componentRef.hostView);
    const domElement = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
    element.appendChild(domElement);

    return element;
  }

  generateSignHereElements(signatureTag: string, order: string, role: EnumSignerRoleType = EnumSignerRoleType.SIGNER, alternateSignatureTag?: string): {
    signHereElements: Array<HTMLElement>;
    commentHereElements: Array<HTMLElement>;
  } {
    const signHereElements: Array<HTMLElement> = [];
    const commentHereElements: Array<HTMLElement> = [];
    const textLayers = document.getElementsByClassName("textLayer");

    let dynamicTags: Array<keyof typeof EnumSignTagTypes> = Object.keys(EnumSignTagTypes) as Array<keyof typeof EnumSignTagTypes>;
    dynamicTags = dynamicTags.filter((tag) => EnumSignTagTypes[tag] !== EnumSignTagTypes.COMMENT && EnumSignTagTypes[tag] !== EnumSignTagTypes.SIGNATURE);

    for (let i = 0; i < textLayers.length; i++) {
      if (this.signTags.length) {
        this.signTags.forEach((pageTag, index) => {
          if (pageTag.Page === i + 1 && !pageTag.IsRendered) {
            const element = document.createElement('span');
            element.style.width = pageTag.Width + '%';
            element.style.height = pageTag.Height + '%';
            element.style.left = pageTag.XLocation + '%';
            element.style.top = pageTag.YLocation + '%';
            textLayers[i].appendChild(element);

            switch (pageTag.Type) {
              case EnumSignTagTypes.SIGNATURE:
                const signElement = this.renderSignHereElement(element);
                element.setAttribute("page", (pageTag.Page - 1).toString());
                signHereElements.push(signElement);
                break;
              case EnumSignTagTypes.COMMENT:
                const commentElement = this.renderCommentElement(element, 'sign-tag-comment-container');
                commentHereElements.push(commentElement);
                break;
              case EnumSignTagTypes.DATE:
              case EnumSignTagTypes.CHECKBOX:
              case EnumSignTagTypes.INITIALS:
              case EnumSignTagTypes.STAMP:
              case EnumSignTagTypes.TEXT:
                element.className = (element.classList + ' sign-tag-element-container').trim();
                this.renderTagElement(element, pageTag.Type === EnumSignTagTypes.CHECKBOX && !pageTag.Text ? 'Checkbox' : pageTag.Text, pageTag.Type);
            }

            this.signTags[index]['IsRendered'] = true;
          }
        });
      }

      const textElements = textLayers[i].children;
      for (let j = 0; j < textElements.length; j++) {
        if (
          textElements[j].textContent
            ?.toLowerCase()
            .includes(`${Constants.COMMENT_TAG_PREFIX}${order}`.toLowerCase())
        ) {
          const element = textElements[j] as HTMLElement;
          element.setAttribute("page", i.toString());
          element.style.width = Constants.DEFAULT_SIGN_IMAGE_WIDTH;
          element.style.height = Constants.DEFAULT_SIGN_IMAGE_HEIGHT;
          commentHereElements.push(this.renderCommentElement(element));
        } else if (
          textElements[j].textContent
            ?.toLowerCase()
            .includes(signatureTag.toLowerCase()) || (alternateSignatureTag && textElements[j].textContent
              ?.toLowerCase()
              .includes(alternateSignatureTag)) && role.toLowerCase() === EnumSignerRoleType.SIGNER.toLowerCase()
        ) {
          const element = textElements[j] as HTMLElement;
          element.setAttribute("page", i.toString());
          element.style.width = Constants.DEFAULT_SIGN_IMAGE_WIDTH;
          element.style.height = Constants.DEFAULT_SIGN_IMAGE_HEIGHT;
          signHereElements.push(this.renderSignHereElement(element));
        }

        if (order && role.toLowerCase() === EnumSignerRoleType.SIGNER.toLowerCase()) {
          dynamicTags?.forEach(tag => {
            if (
              textElements[j].textContent
                ?.toLowerCase()
                .includes(`${Constants.SIGNATURE_TAG_PREFIX}${tag}${order}`?.toLowerCase())
            ) {
              const element = textElements[j] as HTMLElement;
              element.innerHTML = '';
              element.innerText = '';
              const customTagElement = this.customSignTags.find(customTag => customTag.type === EnumSignTagTypes[tag]);
              element.style.width = customTagElement?.dimensions.width || '';
              element.style.height = customTagElement?.dimensions.height || '';
              element.className = 'sign-tag-element-container';
              this.renderTagElement(element, EnumSignTagTypes[tag] === EnumSignTagTypes.CHECKBOX ? '' : EnumSignTagTypes[tag], EnumSignTagTypes[tag] as EnumSignTagTypes, false, () => element.remove());
            }
          });
        }
      }
    }
    return { signHereElements, commentHereElements };
  }

  getSignerSignatures(signRequestId: string, signerId: string): void {
    const params = {
      SignRequestId: signRequestId,
      SignerId: signerId
    }

    this.isLoadingSignerSignatures = true;
    this.signerSignatures = [];

    this._httpService.get(
      this._httpService.createEndpoint(
        Constants.API_URI_SIGN_GET_SIGNER_SIGNATURE,
        undefined,
        params
      )
    ).subscribe((response: any) => {
      this.isLoadingSignerSignatures = false;
      this.signerSignatures = response?.Data?.map((sign: IGetSignerSignature) => ({
        ...sign,
        SignImagePath: 'data:image;base64,' + sign.SignImagePath
      }));
    },
      (errorResponse) => {
        this.isLoadingSignerSignatures = false;
        this._httpService.showHttpError(errorResponse);
      })
  }
}
