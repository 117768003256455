import { ISignRequest } from "@modals/api.modal";

export interface IUserDetails {
  CustomerId: string;
  CustomerName: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Mobile: string;
}

export enum EnumRequestStatus {
  ALL = "All",
  OPEN = "Open",
  SIGNED = "Signed",
  PARTIAL_SIGNED = "PartialSigned",
  CANCELLED = "Cancelled",
}

export type RequestStatus = keyof typeof EnumRequestStatus;

export interface IRequestStatus {
  status: string;
  count: number;
  label: string;
}

export interface IExpandedRowsData {
  data: Array<any>;
  columns: Array<any>;
  actions?: Array<ITableActionRow>
}

export type TSignRequestTableRow = ISignRequest & {
  isDownloading?: boolean;
  expandedRowsData?: IExpandedRowsData;
};

export interface ITableActionRow {
  icon: string;
  color: string;
  title: string;
  isLoading?: (row: any) => boolean;
  isDisabled?: (row: any) => boolean;
  actionFn: (row: any) => void;
}
