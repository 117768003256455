<kzn-dialog
  [title]="!!blogDetails ? 'Update Blog' : 'Create Blog'"
  [isActionInProgress]="isCreatingOrUpdatingBlog"
  [actionLabel]="!!blogDetails ? 'Save' : 'Create'"
  (actionFired)="createOrUpdateBlog()"
>
  <form [formGroup]="createEditBlogForm" class="form-create-edit-blog">
    <mat-form-field appearance="outline">
      <mat-label>Image URL</mat-label>
      <input
        matInput
        placeholder="Enter image url"
        formControlName="imageURL"
      />
      <mat-hint>{{ Messages.MSG_VALID_IMAGE_URL_EXAMPLES }}</mat-hint>
      @if(createEditBlogForm.get('imageURL')?.errors?.['required']) {
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
      } @else {
      <mat-error>{{ Messages.MSG_ERROR_INVALID_URL }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Short Description</mat-label>
      <input
        matInput
        placeholder="Enter short description"
        formControlName="shortDescription"
      />
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Blog URL</mat-label>
      <input matInput placeholder="Enter blog url" formControlName="blogURL" />
      <mat-hint>{{ Messages.MSG_VALID_URL_EXAMPLES }}</mat-hint>
      @if(createEditBlogForm.get('blogURL')?.errors?.['required']) {
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
      } @else {
      <mat-error>{{ Messages.MSG_ERROR_INVALID_URL }}</mat-error>
      }
    </mat-form-field>
  </form>
</kzn-dialog>
