import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { AlertComponent } from "@components/layouts/alert/alert.component";
import { OtpInputComponent } from "@components/otp-input/otp-input.component";
import { Constants } from "@config/constants";
import { Messages } from "@config/messages";
import { IOtpGenerateRequest, IOtpValidateRequest } from "@modals/api.modal";
import { AuthService } from "@services/auth.service";
import { HttpService } from "@services/http.service";
import { ToastService } from "@services/toast.service";

@Component({
  selector: "kzn-otp-verification",
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    OtpInputComponent,
    AlertComponent,
  ],
  templateUrl: "./otp-verification.component.html",
  styleUrl: "./otp-verification.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class OtpVerificationComponent {
  private _httpService = inject(HttpService);
  private _authService = inject(AuthService);
  private _toastService = inject(ToastService);
  private _timerInterval: any;
  private _timerTimeout: any;
  public isResendOTPDisabled: boolean = false;
  public isOTPExpired: boolean = false;
  public Messages = Messages;

  @Input({ required: true }) userName!: string;
  @Input({ required: true }) requestId!: string;
  @Output() otpVerified = new EventEmitter<string>();
  @Output() navigateBackEvent = new EventEmitter<null>();
  @ViewChild("otpInputComponentRef") otpInputComponentRef!: OtpInputComponent;

  public isLoading: boolean = false;
  public resendingOTP: boolean = false;
  public timerValue: string = "00:00"; // Default time for OTP validation

  constructor() {
    this.startTimer();
  }

  public verifyOTP(): void {
    if (this.otpInputComponentRef.isOtpInputValid()) {
      const request: IOtpValidateRequest = {
        LoginId: this.userName,
        RequestId: this.requestId,
        Otp: this.otpInputComponentRef.getOtpValue(),
      };
      this.isLoading = true;
      this._httpService
        .post(
          this._httpService.createEndpoint(Constants.API_URI_OTP_VALIDATE),
          request
        )
        .subscribe(
          (response: any) => {
            this.isLoading = false;
            this.otpVerified.emit(this.otpInputComponentRef.getOtpValue());
            this._authService.afterLoginSuccess(response.Data.Token);
          },
          (error) => {
            this.isLoading = false;
            this._httpService.showHttpError(error);
          }
        );
    }
  }

  public startTimer(): void {
    let timer = Constants.OTP_TIME_VALIDITY,
      minutes,
      seconds;
    this.stopTimer();
    this._timerInterval = setInterval(() => {
      minutes = parseInt((timer / 60).toString(), 10);
      seconds = parseInt((timer % 60).toString(), 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      this.timerValue = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = Constants.OTP_TIME_VALIDITY;
        this.stopTimer();
        this.isOTPExpired = true;
      }
    }, 1000);

    if (this._timerTimeout) clearTimeout(this._timerTimeout);
    this.isResendOTPDisabled = true;
    this._timerTimeout = setTimeout(() => {
      this.isResendOTPDisabled = false;
    }, 1000 * Constants.RESEND_OTP_DISABLE_TIMER_SECONDS);
  }

  public stopTimer() {
    if (this._timerInterval) clearInterval(this._timerInterval);
  }

  public resendOTP(): void {
    this.resendingOTP = true;
    this.isOTPExpired = false;

    const request: IOtpGenerateRequest = {
      LoginId: this.userName,
      RequestId: this.requestId,
    };
    this._httpService
      .post(this._httpService.createEndpoint(Constants.API_URI_OTP), request)
      .subscribe(
        (response) => {
          this.resendingOTP = false;
          this.startTimer();
        },
        (error) => {
          this.resendingOTP = false;
          this._httpService.showHttpError(error);
        }
      );
  }

  public navigateBack() {
    this.stopTimer();
    this.navigateBackEvent.emit();
  }
}
