import { Component, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CreateEditBlogComponent } from "@components/create-edit-blog/create-edit-blog.component";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { StatusMessageComponent } from "@components/layouts/status-message/status-message.component";
import { Constants } from "@config/constants";
import { Messages } from "@config/messages";
import { IBlog } from "@modals/api.modal";
import { AuthService } from "@services/auth.service";
import { HttpService } from "@services/http.service";

@Component({
  selector: "kzn-blogs",
  standalone: true,
  imports: [
    MatCardModule,
    LoaderComponent,
    StatusMessageComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: "./blogs.component.html",
  styleUrl: "./blogs.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class BlogsComponent implements OnInit {
  private _httpService = inject(HttpService);
  private _authService = inject(AuthService);

  public Messages = Messages;
  public isAdminUser: boolean = this._authService.isAdminUser();
  public isLoading: boolean = true;
  public isError: boolean = false;
  public errorMessage: string = "";
  public blogs: Array<IBlog> = [];
  public blogsToDisplay: Array<IBlog> = [];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getBlogs();
  }

  public getBlogs(): void {
    this._httpService
      .get(this._httpService.createEndpoint(Constants.API_URI_BLOG_LIST))
      .subscribe(
        (response: any) => {
          this.blogs = response.Data;
          this.blogsToDisplay = response.Data;
          this.isLoading = false;
          this.isError = false;
        },
        (errorResponse) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage =
            errorResponse.error?.FailRules?.[0]?.Msg ||
            Messages.MSG_ERROR_UNEXPECTED;
        }
      );
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value?.trim();
    this.blogsToDisplay = filterValue
      ? this.blogs.filter((blog) =>
          blog.Description?.toLowerCase().includes(filterValue.toLowerCase())
        )
      : JSON.parse(JSON.stringify(this.blogs));
  }

  public openCreateEditBlogDialog(blogDetails?: IBlog) {
    const dialogRef = this.dialog.open(CreateEditBlogComponent, {
      data: {
        ...(blogDetails ? { blogDetails } : {}),
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.isSuccess) {
        this.getBlogs();
      }
    });
  }
}
