<div class="status-message-container">
  <img
    [src]="
      isSuccess
        ? './../../../assets/doc-signed.png'
        : './../../../assets/error.png'
    "
  />
  @if(heading) {
  <h1 class="status-message-heading">
    {{ heading }} {{ isSuccess ? "😊" : "😔" }}
  </h1>
  }
  <span class="status-message">{{ message }}</span>
</div>
