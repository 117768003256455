<div class="upload-sign-initials__container">
    <div class="upload-sign-initials__top">
        <canvas #canvas hidden></canvas>
        <mat-form-field appearance="outline" class="input-initials">
            <mat-label>Initials</mat-label>
            <input matInput placeholder="Enter your initials" [formControl]="initialsCtrl" autocomplete
                [value]="initialsCtrl.value" />
            <mat-error>
                @if(initialsCtrl.errors?.['minlength']) {
                Min 2 characters are required
                } @else if(initialsCtrl.errors?.['maxlength']) {
                Max 12 characters are allowed
                } @else {
                {{Messages.MSG_ERROR_REQUIRED}}
                }
            </mat-error>
            <mat-hint>After entering valid initials, random signatures would be generated</mat-hint>
        </mat-form-field>
    </div>
    <div class="upload-sign-initials__bottom">
        <mat-radio-group class="image-initials__container" [formControl]="initialImgCtrl"
            (change)="initialImageChange()">
            @for(initial of initials; track initial.fontFamily; let index = $index) {
            <mat-card class="mat-elevation-z8 image-initial__container">
                <mat-radio-button [value]="initial.image" color="primary">
                    <img class="image-initial" [src]="initial.image" />
                </mat-radio-button>
            </mat-card>
            }
        </mat-radio-group>
    </div>
</div>