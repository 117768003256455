<kzn-dialog [title]="isComment ? 'Insert Comment' : 'Insert Signature'" [isActionDisabled]="
    (!isComment && (!selectedImage || signComment.invalid)) ||
    (isComment && docComment.invalid)
  " actionLabel="Insert" (actionFired)="insertImage()" [showReset]="true" (onReset)="resetForm()">
  @if(isComment) {
  <div id="draw-tab-content" class="tab-content-upload-sign">
    <canvas #commentCanvas height="16" hidden></canvas>
    <mat-form-field appearance="outline">
      <mat-label>{{Constants.DEFAULT_COMMENT_PREFIX.replace('{ROLE}', signService.roleText)}}</mat-label>
      <textarea matInput [formControl]="docComment" autocomplete [value]="docComment.value" placeholder="Enter Comment"
        rows="10" autofocus></textarea>
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
  </div>
  } @else {
  <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>keyboard</mat-icon>
        Initials
      </ng-template>
      <div id="initials-tab-content" class="tab-content-upload-sign">
        <kzn-upload-sign-initials [signerName]="signerName" (onInitialImageChange)="handleInitialImageChange($event)"></kzn-upload-sign-initials>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>draw</mat-icon>
        Draw
      </ng-template>
      <div id="draw-tab-content" class="tab-content-upload-sign">
        <signature-pad style="background-color: transparent" #signature [options]="signaturePadOptions"
          (drawEnd)="drawComplete($event)"></signature-pad>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>cloud_upload</mat-icon>
        Upload
      </ng-template>
      <div class="tab-content-upload-sign">
        <input #uploadSignFileInput type="file" accept="image/png, image/jpeg, image/jpg"
          (change)="onFileInputChange($event)" hidden />
        <div class="file-input-sign-container">
          @if (selectedImage) {
          <button mat-button mat-raised-button (click)="uploadSign()">
            Change file
          </button>
          } @else {
          <button mat-button mat-raised-button color="primary" (click)="uploadSign()">
            Choose file
          </button>
          }
          <div class="info-text sign-upload-info-text">
            <span>Ensure that the size of the file should not exceed 200KB</span>
            <span>Allowed file types are .png, .jpg and .jpeg</span>
          </div>
          @if(showMaxFileSizeError) {
          <div class="invalid">{{ Messages.MSG_ERROR_MAX_FILE_SIZE }}</div>
          } @else if(showInvalidFileTypeError) {
          <div class="invalid">{{ Messages.MSG_ERROR_INVALID_FILE_TYPE }}</div>
          }
        </div>
        <!-- <mat-form-field appearance="outline">
          <mat-label>Sign Comment</mat-label>
          <input
            matInput
            placeholder="Enter sign comment"
            [formControl]="signComment"
            autocomplete
            [value]="signComment.value"
          />
          <mat-error>{{ Messages.MSG_ERROR_MAX_LENGTH_100 }}</mat-error>
        </mat-form-field> -->
        <img class="image-sign-preview" [src]="selectedImage" />
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>history</mat-icon>
        <span>Recently Used Signs</span>
      </ng-template>
      <div id="recent-uploads-tab-content" class="tab-content-upload-sign">
        <kzn-upload-sign-recent-uploads
          (onRecentUploadImageChange)="handleRecentUploadImageChange($event)"></kzn-upload-sign-recent-uploads>
      </div>
    </mat-tab>
  </mat-tab-group>
  }
</kzn-dialog>