export class Messages {
  public static readonly MSG_NO_RECORDS = "No records found";
  public static readonly MSG_VALID_URL_EXAMPLES =
    "Example : https://example.com or https://example.com/abc";
  public static readonly MSG_VALID_IMAGE_URL_EXAMPLES =
    "Example : https://example.com/abc.jpeg";
  public static readonly MSG_SUCCESS_SIGNED = "File successfully signed";
  public static readonly MSG_SUCCESS_PASSWORD_CHANGED =
    "Password has been successfully changed";
  public static readonly MSG_SUCCESS_LOGOUT = "Logged out successfully";
  public static readonly MSG_SUCCESS_CREATE_TOKEN =
    "App token created successfully";
  public static readonly MSG_SUCCESS_CREATE_BLOG = "Blog created successfully";
  public static readonly MSG_SUCCESS_UPDATE_BLOG = "Blog updated successfully";
  public static readonly MSG_SUCCESS_DOC_SIGNED = "PDF is signed successfully";
  public static readonly MSG_SUCCESS_CUSTOMER_SUBSCRIBE =
    "Subscription added successfully";
  public static readonly MSG_SUCCESS_CUSTOMER_SUBSCRIPTION_UPDATE =
    "Subscription updated successfully";
  public static readonly MSG_SUCCESS_CUSTOMER_CREATE =
    "Customer created successfully";
  public static readonly MSG_SUCCESS_CUSTOMER_UPDATE =
    "Customer updated successfully";
  public static readonly MSG_SUCCESS_SIGN_REQUEST_URL_COPIED_TO_CLIPBOARD = "Request URL copied to clipboard";
  public static readonly MSG_ERROR_REQUIRED = "This field is required";
  public static readonly MSG_ERROR_UNEXPECTED =
    "Something unexpected happened. Please try again.";
  public static readonly MSG_ERROR_PASSWORD_MISMATCH =
    "Password and confirm password doesn't match";
  public static readonly MSG_ERROR_MAX_LENGTH_100 =
    "Max 100 characters are allowed";
  public static readonly MSG_ERROR_NO_SIGNATURE_ADDED =
    "No signature added in file";
  public static readonly MSG_ERROR_INVALID_EMAIL = "Enter a valid email";
  public static readonly MSG_ERROR_INVALID_URL = "Enter a valid URL";
  public static readonly MSG_ERROR_INVALID_PHONE_NUMBER =
    "Enter a valid phone number";
  public static readonly MSG_ERROR_INVALID_ZIP_CODE = "Enter a valid zip code";
  public static readonly MSG_ERROR_TITLE_NOT_AUTHORIZED = "Sorry!";
  public static readonly MSG_ERROR_NOT_AUTHORIZED =
    "You're not authorized to access this page. Please check your login credentials or contact the administrator for access";
  public static readonly MSG_ERROR_OTP_EXPIRED =
    "OTP has expired, click on back button to re-login";
  public static readonly MSG_ERROR_MAX_FILE_SIZE = "File size is greater than the allowed limit";
  public static readonly MSG_SUCCESS_CREATE_EMAIL_TEMPLATE = "Email template created successfully";
  public static readonly MSG_SUCCESS_DELETE_EMAIL_TEMPLATE = "Email template deleted successfully";
  public static readonly MSG_SUCCESS_ACTIVE_EMAIL_TEMPLATE = "Activation updated successfully";
  public static readonly MSG_INFO_MAX_EMAIL_TEMPLATE_LIMIT_REACHED = "Maximum 2 email templates are allowed";
  public static readonly MSG_ERROR_EMAIL_TEMPLATE_NOT_EXIST = "Email template doesn't exist";
}
