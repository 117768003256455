import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { EnumSignTagTypes } from '@modals/app.modal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { SignDragResizeComponent } from '@components/sign-drag-resize/sign-drag-resize.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'kzn-sign-tag-element',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatButtonModule,
    SignDragResizeComponent,
    FormsModule
  ],
  templateUrl: './sign-tag-element.component.html',
  styleUrl: './sign-tag-element.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SignTagElementComponent {
  label: string | undefined = '';
  className: string = '';
  tag!: EnumSignTagTypes;
  EnumSignTagTypes = EnumSignTagTypes;
  stamp: string | undefined;
  height: string = '100%';
  width: string = '100%';
  isCustomTagElementStatic = true;
  destroyComponent: (() => void) | undefined;
  tagValue: string = '';

  @ViewChild('stampRef') stampRef!: ElementRef<HTMLInputElement>;

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.stamp = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
}
