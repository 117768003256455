@if(isDownloading) {
<kzn-loader message="Downloading file..."></kzn-loader>
} @else if(!isView && (isSigned || isSubmitted || isDeclined)) {
<kzn-status-message [heading]="statusMessage.heading" [message]="statusMessage.message"
  [isSuccess]="statusMessage.isSuccess"></kzn-status-message>
} @else if(isError) {
<kzn-status-message [heading]="statusMessage.heading" [message]="statusMessage.message"
  [isSuccess]="statusMessage.isSuccess"></kzn-status-message>
} @else if(base64) {
<div class="btn__next__conatiner" id="btn__next" (click)="nextSignTagHandler()">Next</div>
<ngx-extended-pdf-viewer [base64Src]="base64" [handTool]="false" [showHandToolButton]="true"
  (annotationLayerRendered)="onAnnotationLayerRendered($event)" [customToolbar]="additionalButtons"
  [showUnverifiedSignatures]="true" (pagesLoaded)="pagesLoaded($event)" [pageViewMode]="pageViewMode">
</ngx-extended-pdf-viewer>

<ng-template #additionalButtons>
  <div id="toolbarViewer">
    <div id="toolbarViewerLeft">
      @if(!isView) {
      <div class="toolbar__left-custom">
        <div class="toolbar__left-custom-item" (click)="openSignDialog(false, true)"
          [ngClass]="{'disabled': signerRole.toLowerCase() !== EnumSignerRoleType.SIGNER.toLowerCase()}">
          <mat-icon color="primary">add</mat-icon><span>Signature</span>
        </div>
        @for(signTag of signService.customSignTags; track signTag.type) {
        <div class="toolbar__left-custom-item" (click)="activateClickMode(signTag.type, signTag.dimensions)"
          [ngClass]="{'disabled': signerRole.toLowerCase() !== EnumSignerRoleType.SIGNER.toLowerCase()}">
          <mat-icon color="primary">{{signTag.icon}}</mat-icon><span>{{signTag.label}}</span>
        </div>
        }
        <div class="toolbar__left-custom-item" (click)="openSignDialog(true, true)">
          <mat-icon color="primary">add_comment</mat-icon><span>Comment</span>
        </div>
      </div>
      }
    </div>
    <div id="toolbarViewerMiddle">
      <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
      <pdf-paging-area></pdf-paging-area>
    </div>
    <div id="toolbarViewerRight">
      @if(!isView) {
      <div class="document-action-btn-container">
        @if(isView) {
        <button mat-button color="primary" (click)="downloadPDF()" [disabled]="isDownloading || !base64">
          Download
        </button>
        } @else {
        <button mat-button mat-button (click)="openDeclineConfirmationDialog()"
          [disabled]="isSubmitted || isDeclined || isSigned || !base64">
          Decline
        </button>
        <button mat-button mat-raised-button color="primary" (click)="signPDF()"
          [disabled]="isSubmitted || isDeclined || isSigned || !base64">
          Submit
        </button>
        }
      </div>
      }
    </div>
  </div>
</ng-template>
}