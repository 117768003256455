import { HttpInterceptorFn } from "@angular/common/http";
import { AuthService } from "@services/auth.service";

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = new AuthService();
  const token = authService.getAuthTokenFromLocalStorage();
  if (token) {
    req = req.clone({
      setHeaders: {
        authorization: "Bearer " + token,
      },
    });
  }

  return next(req);
};
