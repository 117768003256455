import { Component, ViewEncapsulation, inject } from "@angular/core";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { HttpService } from "@services/http.service";
import { Constants } from "@config/constants";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Messages } from "@config/messages";
import { ILoginRequest } from "@modals/api.modal";
import { Observable } from "rxjs";
import { OtpVerificationComponent } from "@components/otp-verification/otp-verification.component";
import { AuthService } from "@services/auth.service";
import { RouterModule } from "@angular/router";

@Component({
  selector: "kzn-login",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    OtpVerificationComponent,
    RouterModule,
  ],
  providers: [HttpService],
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
  private _httpService = inject(HttpService);
  private _authService = inject(AuthService);

  Messages = Messages;
  isLoading: boolean = false;
  userName: string = "";
  requestId: string = "";
  loginForm: FormGroup = new FormGroup({
    userName: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });

  public showOtpVerification: boolean = false;
  public showPassword: boolean = false;

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public login(): Observable<any> {
    const request: ILoginRequest = {
      LoginId: this.loginForm.get("userName")?.value,
      Password: this.loginForm.get("password")?.value,
    };

    return this._httpService.post(
      this._httpService.createEndpoint(Constants.API_URI_LOGIN),
      request
    );
  }

  public onLogin(): void {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.valid) {
      this.isLoading = true;
      this.loginForm.disable();

      const request: ILoginRequest = {
        LoginId: this.loginForm.get("userName")?.value,
        Password: this.loginForm.get("password")?.value,
      };

      this._httpService
        .post(
          this._httpService.createEndpoint(Constants.API_URI_LOGIN),
          request
        )
        .subscribe(
          (response: any) => {
            this.isLoading = false;
            this.loginForm.enable();
            this.userName = response?.Data.LoginId;
            this.requestId = response?.Data.RequestId;
            if (response?.Data.IsMfaForced) {
              this.showOtpVerification = true;
            } else {
              this._authService.afterLoginSuccess(response.Data.Token);
            }
          },
          (error) => {
            this.isLoading = false;
            this.loginForm.enable();
            this._httpService.showHttpError(error);
          }
        );
    }
  }

  public cancelOTPVerification() {
    this.showOtpVerification = false;
  }
}
