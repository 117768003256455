import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NativeDateAdapter } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DialogComponent } from "@components/layouts/dialog/dialog.component";
import { Constants } from "@config/constants";
import { Messages } from "@config/messages";
import {
  ICustomerSubscribeRequest,
  ICustomerSubscribtionUpdateRequest,
  ICustomerSubscription,
  ISubscription,
} from "@modals/api.modal";
import { HttpService } from "@services/http.service";
import { ToastService } from "@services/toast.service";
import { Observable } from "rxjs";

@Component({
  selector: "kzn-create-edit-customer-subscription",
  standalone: true,
  imports: [
    DialogComponent,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
  ],
  providers: [NativeDateAdapter],
  templateUrl: "./create-edit-customer-subscription.component.html",
  styleUrl: "./create-edit-customer-subscription.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class CreateEditCustomerSubscriptionComponent implements OnInit {
  private _httpService = inject(HttpService);
  private _toastr = inject(ToastService);

  public Messages = Messages;
  public customerID!: string;
  public customerSubscription!: ICustomerSubscription;
  public customerSubscriptionForm!: FormGroup;
  public isLoadingCustomerSubscriptions: boolean = false;
  public isCreatingOrUpdatingCustomerSubscription: boolean = false;
  public customerSubscriptions: Array<ISubscription> = [];

  constructor(
    private dialogRef: MatDialogRef<CreateEditCustomerSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      customerID: string;
      customerSubscription: ICustomerSubscription;
    }
  ) {
    if (data && Object.keys(data).length) {
      this.customerID = data.customerID;
      if (data.customerSubscription)
        this.customerSubscription = data.customerSubscription;
    }
    this.initCreateSubscriptionForm();
  }

  ngOnInit(): void {
    this.getAllCustomerSubscriptions();
  }

  public initCreateSubscriptionForm() {
    this.customerSubscriptionForm = new FormGroup({
      name: new FormControl(
        {
          value: null,
          disabled: !!this.customerSubscription,
        },
        Validators.required
      ),
      startDate: new FormControl(
        this.customerSubscription?.EffectiveDate ?? "",
        Validators.required
      ),
      endDate: new FormControl(
        this.customerSubscription?.EndDate ?? "",
        Validators.required
      ),
      isActive: new FormControl(
        this.customerSubscription?.IsActive ?? true,
        Validators.required
      ),
    });
  }

  public getAllCustomerSubscriptions() {
    this.isLoadingCustomerSubscriptions = true;
    this.enableDisbableForm();
    this._httpService
      .get(
        this._httpService.createEndpoint(Constants.API_URI_SUBSCRIPTION_GET_ALL)
      )
      .subscribe(
        (response: any) => {
          this.isLoadingCustomerSubscriptions = false;
          this.customerSubscriptions = response.Data;
          if (!!this.customerSubscription) {
            const subscription = this.customerSubscriptions.find(
              (subscription) =>
                subscription.SubscriptionId ===
                this.customerSubscription.SubscriptionId
            );
            if (subscription)
              this.customerSubscriptionForm.get("name")?.setValue(subscription);
          }
          this.enableDisbableForm();
        },
        (error) => {
          this.isLoadingCustomerSubscriptions = false;
          this._httpService.showHttpError(error);
          this.enableDisbableForm();
        }
      );
  }

  private createOrUpdateApiCall(
    request: ICustomerSubscribeRequest | ICustomerSubscribtionUpdateRequest
  ): Observable<any> {
    if (!!this.customerSubscription) {
      return this._httpService.put(
        this._httpService.createEndpoint(
          !!this.customerSubscription
            ? Constants.API_URI_CUSTOMER_SUBSCRIPTION_UPDATE
            : Constants.API_URI_CUSTOMER_SUBSCRIBE
        ),
        request
      );
    } else {
      return this._httpService.post(
        this._httpService.createEndpoint(
          !!this.customerSubscription
            ? Constants.API_URI_CUSTOMER_SUBSCRIPTION_UPDATE
            : Constants.API_URI_CUSTOMER_SUBSCRIBE
        ),
        request
      );
    }
  }

  public createOrUpdateCustomerSubscription() {
    this.customerSubscriptionForm.markAllAsTouched();
    if (this.customerSubscriptionForm.valid) {
      this.enableDisbableForm();
      this.isCreatingOrUpdatingCustomerSubscription = true;
      const newCustomerSubscription = this.customerSubscriptionForm.value;
      const request:
        | ICustomerSubscribeRequest
        | ICustomerSubscribtionUpdateRequest = {
        subscriptionId: newCustomerSubscription.name.SubscriptionId,
        customerId: this.customerID,
        startDate: newCustomerSubscription.startDate,
        endDate: newCustomerSubscription.endDate,
        isActive: newCustomerSubscription.isActive,
        isEnabled: true,
        isUsable: true,
        ...(!!this.customerSubscription
          ? {
              customerSubscriptionId:
                this.customerSubscription.CustomerSubscriptionId,
            }
          : {}),
      };

      this.createOrUpdateApiCall(request).subscribe(
        (response) => {
          this.isCreatingOrUpdatingCustomerSubscription = false;
          this._toastr.showSuccess(
            !!this.customerSubscription
              ? Messages.MSG_SUCCESS_CUSTOMER_SUBSCRIPTION_UPDATE
              : Messages.MSG_SUCCESS_CUSTOMER_SUBSCRIBE
          );
          this.dialogRef.close({ isSuccess: true });
          this.enableDisbableForm();
        },
        (error) => {
          this.isCreatingOrUpdatingCustomerSubscription = false;
          this._httpService.showHttpError(error);
          this.enableDisbableForm();
        }
      );
    }
  }

  private enableDisbableForm() {
    if (this.customerSubscriptionForm.enabled) {
      this.customerSubscriptionForm.disable();
    } else {
      this.customerSubscriptionForm.enable();
    }

    if (!!this.customerSubscription)
      this.customerSubscriptionForm.get("name")?.disable();
  }
}
