<kzn-dialog
  title="Create App Token"
  [isActionDisabled]="isLoadingCustomers"
  [isActionInProgress]="isCreatingToken"
  actionLabel="Create"
  (actionFired)="createToken()"
>
  <div class="form-create-token-container">
    <mat-form-field appearance="outline">
      <mat-label>App Name</mat-label>
      <input matInput placeholder="Enter app name" [formControl]="appName" />
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    @if(isAdmin) {
    <mat-form-field appearance="outline" [class.spinner]="isLoadingCustomers">
      <mat-label>Customer</mat-label>
      <mat-select [formControl]="customer">
        @for(customer of customers; track customer.CustomerId; let index =
        $index) {
        <mat-option [value]="customer.CustomerId"
          >{{ customer.CustomerName }} ({{ customer.Email }})</mat-option
        >
        }
      </mat-select>
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    }
  </div>
</kzn-dialog>
