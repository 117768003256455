import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { Constants } from "@config/constants";
import { SignService } from "@services/sign.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "kzn-sign-drag-resize",
  standalone: true,
  imports: [
    CommonModule,
    CdkDrag,
    CdkDragHandle,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: "./sign-drag-resize.component.html",
  styleUrl: "./sign-drag-resize.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SignDragResizeComponent implements OnInit, AfterViewInit {
  @ContentChild('tagContent', { static: false }) tagContent!: ElementRef;
  @Input() isCustomTagElement: boolean = false;
  @Input() isCustomTagElementStatic: boolean = false;
  @Input() onRemoveElement: (() => void) | undefined;

  Constants = Constants;
  imageBase64!: string;
  signatureId: string = '';
  signComment: string | undefined;
  left!: string;
  top!: string;
  id!: string;
  isDraggable: boolean = true;
  isCommentOnly: boolean = false;
  comment: string | undefined = "";
  height: string = '';
  width: string = '';
  isResponsiveContainer?: boolean = false;
  destroyComponent!: () => void;

  private _idPrefix: string = "sign-drag-resize-";

  constructor(public signService: SignService) { }

  ngOnInit(): void {
    if (this.isResponsiveContainer) {
      this.width = '100%';
      this.height = '100%';
    } else {
      const commentBoxDefaultSize = this.getCommentBoxDefaultSize();

      this.height = this.isCommentOnly
        ? commentBoxDefaultSize.height + "px"
        : (
          this.isDraggable
            ? Constants.DEFAULT_SIGN_IMAGE_HEIGHT
            : "100%"
        );
      this.width = this.isCommentOnly
        ? commentBoxDefaultSize.width + "px"
        : (
          this.isDraggable
            ? Constants.DEFAULT_SIGN_IMAGE_WIDTH
            : "100%"
        );
    }

    this.id = this._idPrefix + this.signService.generateUUID();
  }

  private getCommentBoxDefaultSize() {
    let height = Constants.DEFAULT_SIGN_COMMENT_HEIGHT;
    let width = Constants.DEFAULT_SIGN_COMMENT_WIDTH;

    const textLayer = document.getElementsByClassName("textLayer");
    if (textLayer.length) {
      const textLayerHeight = textLayer[0].clientHeight;
      const textLayerWidth = textLayer[0].clientWidth;

      height =
        (Constants.DEFAULT_SIGN_COMMENT_HEIGHT_PERCENTAGE / 100) *
        textLayerHeight;
      width =
        (Constants.DEFAULT_SIGN_COMMENT_WIDTH_PERCENTAGE / 100) *
        textLayerWidth;
    }

    return {
      height,
      width,
    };
  }

  ngAfterViewInit(): void {
    this.signService.makeResizableElement(
      `#${this.id}`,
      this.isCommentOnly
        ? Constants.MINIMUM_SIGN_COMMENT_HEIGHT
        : Constants.MINIMUM_SIGN_IMAGE_HEIGHT,
      this.isCommentOnly
        ? Constants.MINIMUM_SIGN_COMMENT_WIDTH
        : Constants.MINIMUM_SIGN_IMAGE_WIDTH
    );
  }
}
