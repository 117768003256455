@if(isLoading) {
<kzn-loader message="Getting email templates..."></kzn-loader>
} @else if(isError) {
<kzn-status-message [message]="errorMessage"></kzn-status-message>
} @else {
<mat-card class="mat-elevation-z8">
    <div class="email-templates-content">
        <kzn-table [data]="allEmailTemplates" [columns]="tableColumns" style="width: 100%" [showRefresh]="true"
            (refreshEventFired)="getAllEmailTemplates()" [rowActions]="tableRowActions"
            [defaultSortColumnDef]="defaultSortColumnDef" [defaultSortDirection]="defaultSortDirection">
            <ng-container header-right>
                <button type="button" mat-raised-button color="primary" (click)="onCreateEmailTemplate()" [disabled]="allEmailTemplates.length >= Constants.MAX_EMAIL_TEMPLATE_COUNT">
                    <mat-icon>add</mat-icon>Create Email Template
                </button>
            </ng-container>
        </kzn-table>
    </div>
</mat-card>
}