@if(isLoading) {
<kzn-loader message="Getting requests..."></kzn-loader>
} @else if(isError) {
<kzn-status-message [message]="errorMessage"></kzn-status-message>
} @else {
<mat-card class="mat-elevation-z8">
  <div class="requests-content">
    <kzn-table
      [data]="tableRows"
      [columns]="tableColumns"
      style="width: 100%"
      [rowActions]="tableRowActions"
      [showRefresh]="true"
      (refreshEventFired)="getAllRequests(true)"
      [showExpandedRows]="true"
    >
      <mat-chip-listbox class="chips-requests" header-right>
        @for(requestStatus of requestStatuses; track requestStatus.status; let
        index = $index) {
        <mat-chip-option
          [selected]="index === 0"
          (click)="generateTableData(requestStatus.status)"
          [disabled]="!requestStatus.count"
          >{{ requestStatus.label }} ({{
            requestStatus.count
          }})</mat-chip-option
        >
        }
      </mat-chip-listbox>
    </kzn-table>
  </div>
</mat-card>
}
