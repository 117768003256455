import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { CreateEditCustomerComponent } from "@components/create-edit-customer/create-edit-customer.component";
import { CustomerSubscriptionsComponent } from "@components/customer-subscriptions/customer-subscriptions.component";
import { CustomerComponent } from "@components/customer/customer.component";
import { ICustomer } from "@modals/api.modal";

@Component({
  selector: "kzn-view-customer",
  standalone: true,
  imports: [
    MatCardModule,
    MatTabsModule,
    CustomerComponent,
    CustomerSubscriptionsComponent,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: "./view-customer.component.html",
  styleUrl: "./view-customer.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class ViewCustomerComponent {
  @Input({ required: true }) customerDetails!: ICustomer;
  @Output() customerSubscriptionCreated: EventEmitter<null> =
    new EventEmitter();
  @Output() customerUpdated: EventEmitter<null> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  public openEditCustomerDialog() {
    const dialogRef = this.dialog.open(CreateEditCustomerComponent, {
      data: {
        customerDetails: this.customerDetails,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.isSuccess) {
        this.customerUpdated.emit();
      }
    });
  }
}
