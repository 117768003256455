import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DialogComponent } from "@components/layouts/dialog/dialog.component";

@Component({
  selector: "kzn-confirmation-dialog",
  standalone: true,
  imports: [DialogComponent],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialogComponent {
  @Output() confirmed = new EventEmitter<void>();
  isActionDisabled: boolean = false;
  isActionInProgress: boolean = false;
  @Input() title: string = 'Confirm Deletion';
  @Input() actionLabel: string = 'Delete';
  @Input() message: string = 'Are you sure you want to delete this item?';
  @Input() actionColor: string = 'primary';

  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  onAction() {
    this.confirmed.emit();
    this.dialogRef.close();
  }
}
