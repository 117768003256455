import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SortDirection } from '@angular/material/sort';
import { Router, RouterModule } from '@angular/router';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { LoaderComponent } from '@components/layouts/loader/loader.component';
import { StatusMessageComponent } from '@components/layouts/status-message/status-message.component';
import { TableComponent } from '@components/layouts/table/table.component';
import { Constants } from '@config/constants';
import { Messages } from '@config/messages';
import { IEmailTemplate, IUpdateEmailTemplateRequest } from '@modals/api.modal';
import { ITableActionRow } from '@modals/app.modal';
import { AppService } from '@services/app.service';
import { HttpService } from '@services/http.service';
import { ToastService } from '@services/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kzn-email-template',
  standalone: true,
  imports: [LoaderComponent, StatusMessageComponent, TableComponent, MatCardModule, MatIconModule, MatButtonModule, RouterModule],
  templateUrl: './email-template.component.html',
  styleUrl: './email-template.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class EmailTemplateComponent implements OnInit {
  private _httpService = inject(HttpService);
  private _appService = inject(AppService);
  private _router = inject(Router);
  private _toastr = inject(ToastService);
  private _shouldDisableActions: boolean = false;
  private _isUpdating: boolean = false;

  isLoading: boolean = false;
  isError: boolean = false;
  errorMessage: string = '';
  Constants = Constants;
  public allEmailTemplates: Array<IEmailTemplate> = [];
  public defaultSortColumnDef: string = "CreatedOn";
  public defaultSortDirection: SortDirection = "desc";

  public tableColumns = [
    {
      columnDef: "TemplateName",
      header: "Name",
    },
    {
      columnDef: "EmailHeader",
      header: "Header",
    },
    {
      columnDef: "EmailSignature",
      header: "Signature",
    },
    {
      columnDef: "CreatedOn",
      header: "Creation Date",
      cell: (row: IEmailTemplate) =>
        this._appService.getFormattedDate(row.CreatedOn),
    },
    {
      columnDef: "IsActive",
      header: "Active",
      isSortable: false,
      isToggle: true,
      isLoading: (row: IEmailTemplate): boolean => !!row.isUpdating,
      isDisabled: (row: IEmailTemplate): boolean => this._shouldDisableActions || this._isUpdating,
      onToggle: (row: IEmailTemplate, event: MatSlideToggleChange) => this.onActiveToggle(row, event)
    },
  ];

  public tableRowActions: Array<ITableActionRow> = [
    {
      icon: "edit",
      color: "primary",
      title: "Edit",
      actionFn: (row: IEmailTemplate) => this._router.navigate([`email-templates/edit/${row.ID}`], { state: { allEmailTemplates: this.allEmailTemplates } }),
      isDisabled: (row: IEmailTemplate): boolean => this._shouldDisableActions || this._isUpdating
    },
    {
      icon: "delete",
      color: "warn",
      title: "Delete",
      actionFn: (row: IEmailTemplate) => {
        this.onDeleteEmailTemplate(row);
      },
      isLoading: (row: IEmailTemplate): boolean => !!row.isDeleting,
      isDisabled: (row: IEmailTemplate): boolean => this._shouldDisableActions || this._isUpdating
    },
  ];

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAllEmailTemplates();
  }

  getAllEmailTemplates(): void {
    this.isLoading = true;
    this._httpService
      .get(this._httpService.createEndpoint(Constants.API_URI_EMAIL_TEMPLATE_GET_ALL))
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isError = false;
          this.allEmailTemplates = response.Data;
        },
        (errorResponse) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage =
            errorResponse.error?.FailRules?.[0]?.Msg ||
            Messages.MSG_ERROR_UNEXPECTED;
        }
      );
  }

  onCreateEmailTemplate(): void {
    this._router.navigate(['email-templates/new'], { state: { allEmailTemplates: this.allEmailTemplates } });
  }

  onDeleteEmailTemplate(template: IEmailTemplate): void {
    this.openConfirmationDialog(template);
  }

  openConfirmationDialog(template: IEmailTemplate): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.actionLabel = 'Delete';
    dialogRef.componentInstance.actionColor = 'warn';
    dialogRef.componentInstance.title = 'Confirm Deletion';
    dialogRef.componentInstance.message = `Are you sure you want to delete <strong>${template.TemplateName}</strong>?`;

    const dialogSubscription: Subscription = dialogRef.componentInstance.confirmed.subscribe(() => {
      const index = this.allEmailTemplates.findIndex(item => item.ID === template.ID);
      this.allEmailTemplates[index]['isDeleting'] = true;
      this._shouldDisableActions = true;
      const { isDeleting, ...templateClone } = template;
      this._httpService.delete(this._httpService.createEndpoint(Constants.API_URI_EMAIL_TEMPLATE_DELETE), {
        body: templateClone
      }).subscribe(
        (response: any) => {
          this.allEmailTemplates.splice(index, 1);
          this.allEmailTemplates = [...this.allEmailTemplates];
          this._toastr.showSuccess(Messages.MSG_SUCCESS_DELETE_EMAIL_TEMPLATE);
          this._shouldDisableActions = false;
          dialogSubscription.unsubscribe();
        },
        (errorResponse) => {
          this.allEmailTemplates[index]['isDeleting'] = false;
          this._httpService.showHttpError(errorResponse);
          this._shouldDisableActions = false;
          dialogSubscription.unsubscribe();
        },
      )
    });
  }

  onActiveToggle(template: IEmailTemplate, event: MatSlideToggleChange): void {
    this._isUpdating = true;
    const index = this.allEmailTemplates.findIndex(emailTemplate => emailTemplate.ID === template.ID);
    this.allEmailTemplates[index]['isUpdating'] = true;

    const { isUpdating, ...templateRequest } = template;
    const request: IUpdateEmailTemplateRequest = {
      ...templateRequest,
      IsActive: event.checked
    };
    this._httpService.put(this._httpService.createEndpoint(Constants.API_URI_EMAIL_TEMPLATE_UPDATE), request)
      .subscribe(
        (response: any) => {
          this._isUpdating = false;
          this.allEmailTemplates[index]['isUpdating'] = false;
          if (event.checked) {
            this.allEmailTemplates = this.allEmailTemplates.map((emailTemplate, emailTemplateIndex) => ({
              ...emailTemplate,
              IsActive: emailTemplateIndex === index
            }));
          } else {
            this.allEmailTemplates[index]['IsActive'] = event.checked;
          }
          this._toastr.showSuccess(Messages.MSG_SUCCESS_ACTIVE_EMAIL_TEMPLATE);
        },
        (errorResponse) => {
          this._isUpdating = false;
          this.allEmailTemplates[index]['isUpdating'] = false;
          this._httpService.showHttpError(errorResponse);
        }
      );;
  }
}
