<div class="upload-sign-recent-upload-container">
    @if(signService.isLoadingSignerSignatures) {
    <kzn-loader message="Getting recent signs..."></kzn-loader>
    } @else {
    <mat-radio-group class="recent-uploads__container" [formControl]="recentUploadImgCtrl"
        (change)="onImageSelected()">
        @for(signature of signService.signerSignatures; track signature.Id; let index = $index) {
        <mat-card class="mat-elevation-z8 recent-upload__container">
            <mat-radio-button [value]="signature" color="primary">
                <img class="image-recent-upload" [src]="signature.SignImagePath" />
            </mat-radio-button>
        </mat-card>
        }
    </mat-radio-group>
    }
</div>