import { Component, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatChipsModule } from "@angular/material/chips";
import { TableComponent } from "@components/layouts/table/table.component";
import {
  EnumRequestStatus,
  IRequestStatus,
  ITableActionRow,
  TSignRequestTableRow,
} from "@modals/app.modal";
import { HttpService } from "@services/http.service";
import { Constants } from "@config/constants";
import { ISignDownloadViewRequest, ISignRequest, ISigner } from "@modals/api.modal";
import { LoaderComponent } from "@components/layouts/loader/loader.component";
import { Messages } from "@config/messages";
import { StatusMessageComponent } from "@components/layouts/status-message/status-message.component";
import FileSaver from "file-saver";
import { AppService } from "@services/app.service";
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { ToastService } from "@services/toast.service";

@Component({
  selector: "kzn-requests",
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatChipsModule,
    TableComponent,
    LoaderComponent,
    StatusMessageComponent,
    ClipboardModule
  ],
  templateUrl: "./requests.component.html",
  styleUrl: "./requests.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class RequestsComponent implements OnInit {
  private _httpService = inject(HttpService);
  private _appService = inject(AppService);
  private _clipboard = inject(Clipboard);
  private _toastr = inject(ToastService)
  public requestStatuses: Array<IRequestStatus> = [];
  public isLoading: boolean = true;
  public allRequests: Array<ISignRequest> = [];
  public tableRows: Array<TSignRequestTableRow> = [];
  public isError: boolean = false;
  public errorMessage: string = "";
  public tableColumns = [
    {
      columnDef: "RequestNo",
      header: "Request No",
    },
    {
      columnDef: "CustomerName",
      header: "Customer Name",
    },
    {
      columnDef: "RequesterName",
      header: "Requester Name",
    },
    {
      columnDef: "RequesterEmail",
      header: "Requester Email",
    },
    {
      columnDef: "RequestDate",
      header: "Request Date",
      cell: (row: TSignRequestTableRow) =>
        this._appService.getFormattedDate(row.RequestDate),
    },
    {
      columnDef: "RequestStatus",
      header: "Request Status",
    },
  ];
  private _expandedTableColumns = [
    {
      columnDef: "SignerEmail",
      header: "Signer Email",
    },
    {
      columnDef: "SignDate",
      header: "Sign Date",
      cell: (row: ISigner) =>
        this._appService.getFormattedDate(row.SignDate),
    }
  ];
  public tableRowActions: Array<ITableActionRow> = [
    {
      icon: "download",
      color: "primary",
      title: "Download",
      isLoading: (row: TSignRequestTableRow): boolean => {
        return row.isDownloading ? true : false;
      },
      isDisabled: (row: TSignRequestTableRow): boolean => {
        return row.RequestStatus !== EnumRequestStatus.SIGNED ||
          row.isDownloading
          ? true
          : false;
      },
      actionFn: (row: TSignRequestTableRow) => {
        this.downloadSignedFile(row);
      },
    },
  ];
  private _expandedTableRowActions: Array<ITableActionRow> = [
    {
      icon: "file_copy",
      color: "primary",
      title: "Copy request URL",
      actionFn: (row: ISigner) => {
        const baseURL = window.location.origin;
        const requestURL = `${baseURL}/${row.SignDate ? 'view' : 'sign'}/${row.SignRequestId}${row.SignDate ? '' : '/' + row.Id}`;
        this._clipboard.copy(requestURL);
        this._toastr.showSuccess(Messages.MSG_SUCCESS_SIGN_REQUEST_URL_COPIED_TO_CLIPBOARD);
      },
    }
  ]

  ngOnInit(): void {
    this.generateAllStatuses();
    this.getAllRequests();
  }

  private generateAllStatuses(): void {
    this.requestStatuses = [];
    Object.values(EnumRequestStatus).forEach((value) => {
      const label = value.split(/(?=[A-Z])/).join(" ");
      this.requestStatuses.push({
        status: value,
        label,
        count:
          value === EnumRequestStatus.ALL
            ? this.allRequests.length
            : this.allRequests.filter(
              (request) =>
                request.RequestStatus?.toLowerCase() === value?.toLowerCase()
            ).length,
      });
    });
  }

  getAllRequests(showLoader?: boolean): void {
    if (showLoader) this.isLoading = true;
    this._httpService
      .get(this._httpService.createEndpoint(Constants.API_URI_SIGN_REQUEST_ALL))
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isError = false;
          this.allRequests = response.Data?.map((request: ISignRequest) => ({
            ...request,
            Signers: request.Signers?.map((signer: ISigner) => ({ ...signer, SignRequestId: request.SignRequestId }))
          })
          );
          this.generateAllStatuses();
          this.generateTableData(this.requestStatuses[0].status);
        },
        (errorResponse) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage =
            errorResponse.error?.FailRules?.[0]?.Msg ||
            Messages.MSG_ERROR_UNEXPECTED;
        }
      );
  }

  public generateTableData(status: string): void {
    const data =
      status === EnumRequestStatus.ALL
        ? this.allRequests
        : this.allRequests.filter(
          (request) => request.RequestStatus === status
        );
    this.tableRows = data.map((item: ISignRequest) => {
      const index: number = item.Signers?.findIndex(signer => !signer.SignDate);
      
      return {
        ...item, expandedRowsData: {
          data: (index > -1 ? item.Signers?.slice(0, index + 1) : item.Signers) ?? [],
          columns: this._expandedTableColumns,
          actions: this._expandedTableRowActions
        }
      }
    });
  }

  private downloadSignedFile(row: TSignRequestTableRow): void {
    row["isDownloading"] = true;
    const request: ISignDownloadViewRequest = {
      SignRequestId: row.SignRequestId,
    };

    this._httpService
      .get(
        this._httpService.createEndpoint(
          Constants.API_URI_SIGN_DOWNLOAD_VIEW,
          undefined,
          request
        )
      )
      .subscribe(
        (response: any) => {
          const byteCharacters = atob(response.Data.DocContent);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          FileSaver.saveAs(blob, response.Data.DocName);
          row["isDownloading"] = false;
        },
        (error) => {
          this._httpService.showHttpError(error);
          row["isDownloading"] = false;
        }
      );
  }
}
