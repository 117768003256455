import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Messages } from "@config/messages";
import { AlertComponent } from "../alert/alert.component";

@Component({
  selector: "kzn-status-message",
  standalone: true,
  imports: [AlertComponent],
  templateUrl: "./status-message.component.html",
  styleUrl: "./status-message.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class StatusMessageComponent {
  @Input() isSuccess: boolean = false;
  @Input() heading!: string;
  @Input({ required: true }) message!: string;
}
