import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { CreateEditCustomerSubscriptionComponent } from "@components/create-edit-customer-subscription/create-edit-customer-subscription.component";
import { TableComponent } from "@components/layouts/table/table.component";
import { ICustomerSubscription } from "@modals/api.modal";
import { ITableActionRow } from "@modals/app.modal";
import { AppService } from "@services/app.service";

@Component({
  selector: "kzn-customer-subscriptions",
  standalone: true,
  imports: [TableComponent, MatButtonModule, MatIconModule],
  templateUrl: "./customer-subscriptions.component.html",
  styleUrl: "./customer-subscriptions.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class CustomerSubscriptionsComponent {
  @Input({ required: true })
  customerSubscriptions: Array<ICustomerSubscription> = [];
  @Input({ required: true }) customerID!: string;
  @Output() customerSubscriptionCreated: EventEmitter<null> =
    new EventEmitter();

  private _appService = inject(AppService);
  public tableColumns = [
    {
      columnDef: "SubscriptionName",
      header: "Subscription Name",
    },
    {
      columnDef: "EffectiveDate",
      header: "Effective Date",
      cell: (row: ICustomerSubscription) => {
        return this._appService.getFormattedDate(row.EffectiveDate);
      },
    },
    {
      columnDef: "EndDate",
      header: "End Date",
      cell: (row: ICustomerSubscription) => {
        return this._appService.getFormattedDate(row.EndDate);
      },
    },
    {
      columnDef: "IsActive",
      header: "Active",
      cell: (row: ICustomerSubscription) => {
        return row.IsActive ? "Yes" : "No";
      },
    },
  ];

  public tableRowActions: Array<ITableActionRow> = [
    {
      icon: "edit",
      color: "primary",
      title: "Edit",
      actionFn: (row: ICustomerSubscription) => {
        this.openCreateSubscriptionDialog(row);
      },
    },
  ];

  constructor(private dialog: MatDialog) {}

  public openCreateSubscriptionDialog(
    customerSubscription?: ICustomerSubscription
  ) {
    const dialogRef = this.dialog.open(
      CreateEditCustomerSubscriptionComponent,
      {
        data: {
          customerID: this.customerID,
          ...(customerSubscription ? { customerSubscription } : {}),
        },
      }
    );
    dialogRef.afterClosed().subscribe((data) => {
      if (data.isSuccess) {
        this.customerSubscriptionCreated.emit();
      }
    });
  }
}
