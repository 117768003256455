<ng-otp-input
  [formCtrl]="otpFormCtrl"
  [config]="config"
  (focusout)="onFocusOut()"
></ng-otp-input>
@if(otpFormCtrl.touched && otpFormCtrl.invalid) {
<span class="invalid">Please enter a valid OTP</span>
} @else {
<span>&nbsp;</span>
}
