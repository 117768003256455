import { Component, EventEmitter, OnChanges, OnDestroy, Output, SimpleChanges, inject, input, viewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { SharedMaterialModule } from "@config/shared-material.module";
import { ToastService } from "@services/toast.service";
import { Constants } from '@config/constants';
import { HttpService } from '@services/http.service';
import { IAppToken } from '@modals/api.modal';
import { Messages } from '@config/messages';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { TableComponent } from '@components/layouts/table/table.component';
import { MatCardModule } from '@angular/material/card';
import { EnumTableDynamicInputType, ITableActionRow, IUploadPDFUser } from '@modals/app.modal';
import { TableService } from '@services/table.service';
import { SignService } from '@services/sign.service';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-pdf-upload-users',
    templateUrl: './pdf-upload-users.component.html',
    styleUrl: './pdf-upload-users.component.scss',
    standalone: true,
    imports: [SharedMaterialModule, MatFormFieldModule, MatSelectModule, TableComponent, MatCardModule, MatExpansionModule, NgxMatIntlTelInputComponent]
})
export class PdfUploadUsersComponent implements OnDestroy, OnChanges {
    isDraftRequest: boolean = false;

    requesterDetails = input({});
    pdfDetails = input({
        content: '',
        name: ''
    });
    @Output() pdfUploaded = new EventEmitter<File>();
    @Output() appTokenUpdated = new EventEmitter<IAppToken>();

    private _httpService = inject(HttpService);
    private _activatedRoute = inject(ActivatedRoute);
    private _appTokenSubscription!: Subscription;

    requesterForm!: FormGroup;
    accordion = viewChild.required(MatAccordion);
    pdfFileName: string = '';
    tableColumns = [
        {
            columnDef: "name",
            header: "Name",
            isEditable: true,
            inputType: EnumTableDynamicInputType.TEXT,
        },
        {
            columnDef: "email",
            header: "Email",
            isEditable: true,
            inputType: EnumTableDynamicInputType.EMAIL,
        },
        {
            columnDef: "phone",
            header: "Phone",
            isEditable: true,
            inputType: EnumTableDynamicInputType.TEL,
        },
        {
            columnDef: "role",
            header: "Role",
            isEditable: true,
            inputType: EnumTableDynamicInputType.DROPDOWN,
            dropdownOptions: ['signer', 'reviewer']
        },
    ];
    public tableRowActions: Array<ITableActionRow> = [
        {
            icon: "delete",
            color: "warn",
            title: "Delete",
            isDisabled: (row: IUploadPDFUser): boolean => !(this.tableService.tableInputData?.length > 1),
            actionFn: (row: IUploadPDFUser) => {
                const index = this.tableService.tableInputData.findIndex(data => data.tempID === row.tempID);
                this.tableService.tableInputData.splice(index, 1);
                this.tableService.tableInputData = [...this.tableService.tableInputData];
            },
        },
    ];
    allUsers: Array<IUploadPDFUser> = [];
    isLoadingAppTokens: boolean = false;
    allTokens: Array<IAppToken> = [];
    appToken: FormControl;
    Messages = Messages;

    constructor(private _tostr: ToastService, public tableService: TableService, private _signService: SignService) {
        if (!this.tableService.tableInputData.length) this.addNewUser();
        this.isDraftRequest = this._activatedRoute.snapshot.data["isDraftRequest"] ?? false;
        if (!this.isDraftRequest) this.getAllTokens();
        this.appToken = new FormControl("", [...(this.isDraftRequest ? [] : [Validators.required])]);

        this.requesterForm = new FormGroup({
            name: new FormControl("", Validators.required),
            email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
            phone: new FormControl("", Validators.required),
        });

        this._appTokenSubscription = this.appToken.valueChanges.pipe(
            distinctUntilChanged(),
        ).subscribe(() => {
            this.appTokenUpdated.emit(this.appToken.value);
        })
    }

    ngOnChanges(): void {
        if (Object.keys(this.requesterDetails()).length) this.requesterForm.setValue(this.requesterDetails());
        if (Object.keys(this.pdfDetails()).length) {
            this.pdfFileName = this.pdfDetails()?.name;
        }
    }

    addNewUser(): void {
        const user: IUploadPDFUser = {
            name: '',
            email: '',
            phone: '',
            role: '',
            tempID: this._signService.generateUUID()
        };
        this.tableService.tableInputData = [user, ...this.tableService.tableInputData];
    }

    getAllTokens(): void {
        this.isLoadingAppTokens = true;
        this.allTokens = [];
        this._httpService
            .get(
                this._httpService.createEndpoint(Constants.API_URI_APP_TOKEN_GET_ALL)
            )
            .subscribe(
                (response: any) => {
                    this.isLoadingAppTokens = false;
                    this.allTokens = response.Data;
                },
                (errorResponse) => {
                    this.isLoadingAppTokens = false;
                }
            );
    }

    onPdfUpload(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            if (file.type !== 'application/pdf') {
                this._tostr.showWarning('Only PDF files are allowed.');
                return;
            }
            this.pdfFileName = file.name;
            this.pdfUploaded.emit(file);
        }
    }

    handleDrop(event: DragEvent): void {
        event.preventDefault();
        if (event.dataTransfer && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];
            if (file.type !== 'application/pdf') {
                this._tostr.showWarning('Only PDF files are allowed.');
                return;
            }
            this.pdfFileName = file.name;
            this.pdfUploaded.emit(file);

            const fileURL = URL.createObjectURL(file);
        }
    }

    allowDrop(event: DragEvent): void {
        event.preventDefault();
    }

    ngOnDestroy(): void {
        this._appTokenSubscription?.unsubscribe();
    }
}
