import { CommonModule } from "@angular/common";
import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DialogComponent } from "@components/layouts/dialog/dialog.component";
import { Constants } from "@config/constants";
import { Messages } from "@config/messages";
import {
  IBlog,
  ICreateBlogRequest,
  IUpdateBlogRequest,
} from "@modals/api.modal";
import { HttpService } from "@services/http.service";
import { ToastService } from "@services/toast.service";
import { Observable } from "rxjs";

@Component({
  selector: "kzn-create-edit-blog",
  standalone: true,
  imports: [
    DialogComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
  ],
  templateUrl: "./create-edit-blog.component.html",
  styleUrl: "./create-edit-blog.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class CreateEditBlogComponent implements OnInit {
  private _httpService = inject(HttpService);
  private _toastr = inject(ToastService);

  public Messages = Messages;
  public isCreatingOrUpdatingBlog: boolean = false;
  public createEditBlogForm!: FormGroup;
  public blogDetails!: IBlog;

  constructor(
    private dialogRef: MatDialogRef<CreateEditBlogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { blogDetails: IBlog }
  ) {
    if (data?.blogDetails && Object.keys(data.blogDetails).length) {
      this.blogDetails = data.blogDetails;
    }
  }

  ngOnInit(): void {
    this.initCreateEditBlogForm();
  }

  private initCreateEditBlogForm() {
    this.createEditBlogForm = new FormGroup({
      imageURL: new FormControl(this.blogDetails?.Title ?? "", [
        Validators.required,
        Validators.pattern(
          "^(https|ftp|http|ftps):\\/\\/([a-z\\d_]+\\.)?(([a-zA-Z\\d_]+)(\\.[a-zA-Z]{2,6}))(\\/[a-zA-Z\\d_\\%\\-=\\+]+)*(\\?)?([a-zA-Z\\d=_\\+\\%\\-&\\{\\}\\:]+)?.(jpeg|jpg|png|gif)$"
        ),
      ]),
      shortDescription: new FormControl(
        this.blogDetails?.Description ?? "",
        Validators.required
      ),
      blogURL: new FormControl(this.blogDetails?.Url ?? "", [
        Validators.required,
        Validators.pattern(
          "^(https|ftp|http|ftps):\\/\\/([a-z\\d_]+\\.)?(([a-zA-Z\\d_]+)(\\.[a-zA-Z]{2,6}))(\\/[a-zA-Z\\d_\\%\\-=\\+]+)*(\\?)?([a-zA-Z\\d=_\\+\\%\\-&\\{\\}\\:]+)?"
        ),
      ]),
    });
  }

  private createUpdateBlogApiCall(): Observable<any> {
    const blogDetails = this.createEditBlogForm.value;
    if (!!this.blogDetails) {
      const request: IUpdateBlogRequest = {
        blogId: this.blogDetails.BlogId,
        title: blogDetails.imageURL,
        description: blogDetails.shortDescription,
        url: blogDetails.blogURL,
      };

      return this._httpService.put(
        this._httpService.createEndpoint(Constants.API_URI_BLOG_UPDATE),
        request
      );
    } else {
      const request: ICreateBlogRequest = {
        title: blogDetails.imageURL,
        description: blogDetails.shortDescription,
        url: blogDetails.blogURL,
      };

      return this._httpService.post(
        this._httpService.createEndpoint(Constants.API_URI_BLOG_CREATE),
        request
      );
    }
  }

  public createOrUpdateBlog() {
    this.createEditBlogForm.markAllAsTouched();
    if (this.createEditBlogForm.valid) {
      this.isCreatingOrUpdatingBlog = true;
      this.createEditBlogForm.disable();

      this.createUpdateBlogApiCall().subscribe(
        (response) => {
          this.isCreatingOrUpdatingBlog = false;
          this._toastr.showSuccess(
            !!this.blogDetails
              ? Messages.MSG_SUCCESS_UPDATE_BLOG
              : Messages.MSG_SUCCESS_CREATE_BLOG
          );
          this.dialogRef.close({ isSuccess: true });
        },
        (error) => {
          this.isCreatingOrUpdatingBlog = false;
          this._httpService.showHttpError(error);
          this.createEditBlogForm.enable();
        }
      );
    }
  }
}
