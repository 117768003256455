import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { Messages } from "@config/messages";
import { ICustomer } from "@modals/api.modal";

@Component({
  selector: "kzn-customer",
  standalone: true,
  imports: [
    MatCardModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSlideToggleModule,
  ],
  templateUrl: "./customer.component.html",
  styleUrl: "./customer.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class CustomerComponent implements OnInit {
  @Input() customerDetails!: ICustomer;
  @Input() isViewOnly: boolean = false;
  @Input() isEdit: boolean = false;

  public Messages = Messages;
  public showPassword: boolean = false;
  public customerForm!: FormGroup;

  ngOnInit(): void {
    this.initCustomerForm();
  }

  private isValidJSONString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  private initCustomerForm() {
    const customerAddress = this.isValidJSONString(
      this.customerDetails?.AddressJson
    )
      ? JSON.parse(this.customerDetails?.AddressJson)
      : {};
    this.customerForm = new FormGroup({
      companyName: new FormControl(
        this.customerDetails?.CustomerName ?? "",
        Validators.required
      ),
      companyPhone: new FormControl(this.customerDetails?.Phone ?? null, [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]),
      companyEmail: new FormControl(this.customerDetails?.Email ?? "", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]),
      isCustomerActive: new FormControl(
        this.customerDetails?.IsEnabled ?? true
      ),
      companyAddress: new FormGroup({
        street: new FormControl(
          customerAddress?.street ?? "",
          Validators.required
        ),
        city: new FormControl(customerAddress?.city ?? "", Validators.required),
        state: new FormControl(
          customerAddress?.state ?? "",
          Validators.required
        ),
        country: new FormControl(
          customerAddress?.country ?? "",
          Validators.required
        ),
        zipCode: new FormControl(customerAddress?.zipCode ?? "", [
          Validators.required,
          Validators.pattern("[0-9]{6}"),
        ]),
      }),
      userFirstName: new FormControl(
        this.customerDetails?.Users?.[0]?.FirstName ?? "",
        Validators.required
      ),
      userLastName: new FormControl(
        this.customerDetails?.Users?.[0]?.LastName ?? "",
        Validators.required
      ),
      userEmail: new FormControl(
        this.customerDetails?.Users?.[0]?.Email ?? "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]
      ),
      userPhone: new FormControl(
        this.customerDetails?.Users?.[0]?.Phone ?? "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]
      ),
      username: new FormControl(
        {
          value: this.customerDetails?.Users?.[0]?.LoginId ?? "",
          disabled: this.isEdit,
        },
        Validators.required
      ),
      userPassword: new FormControl(
        { value: "", disabled: this.isEdit },
        Validators.required
      ),
      userID: new FormControl(this.customerDetails?.Users?.[0]?.UserId ?? ""),
      isMFAForced: new FormControl(
        {
          value: this.customerDetails?.Users?.[0]?.IsMfaForced ?? true,
          disabled: this.isViewOnly,
        },
        Validators.required
      ),
    });

    if (this.isViewOnly) this.customerForm.disable();
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
