@if(isLoading) {
<kzn-loader message="Getting request details..."></kzn-loader>
} @else if (isError) {
<kzn-status-message heading="Something wrong happened" message="Not able to download request details. Please try again."
    [isSuccess]="false"></kzn-status-message>
} @else {
<mat-card class="mat-elevation-z8 pd-1">
    <div class="page-container">
        <div class="content-wrapper">
            <mat-stepper linear #stepper>
                @for(step of steps; track step; let index = $index) {
                <mat-step [label]="step" completed="false" [editable]="isEditable">
                    @if(index === 0) {
                    <div class="step-buttons">
                        <button mat-button (click)="resetStepper()">
                            Reset
                        </button>
                        <button mat-raised-button color="primary" matStepperNext (click)="nextStep()">
                            Next
                        </button>
                    </div>
                    <app-pdf-upload-users #pdfUploadUsersComponentRef (pdfUploaded)="handlePdfUploaded($event)"
                        (appTokenUpdated)="handleAppTokenUpdated($event)"
                        [requesterDetails]="requesterDetails" [pdfDetails]="pdfDetails"></app-pdf-upload-users>
                    } @else if (index === 1) {
                    <div class="step-buttons">
                        <button mat-button (click)="goToPrevious()" matStepperPrevious>Back</button>
                        <button mat-raised-button color="primary" matStepperNext (click)="submitData()">
                            Submit
                        </button>
                    </div>
                    <app-pdf-view-edit [pdfFile]="pdfFile" [appToken]="appToken" (previousStep)="previousStep()"
                        (onSubmitComplete)="handleSubmitComplete()" [topPosition]="topPosition"
                        style="display: flex; gap: 1rem; margin-top: 0.5rem;"></app-pdf-view-edit>
                    } @else if (index === 2) {
                    <app-pdf-thank-you></app-pdf-thank-you>
                    }
                </mat-step>
                }
            </mat-stepper>
        </div>
    </div>
</mat-card>
}