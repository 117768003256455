import {
  Component,
  Inject,
  ViewChild,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import { DialogComponent } from "@components/layouts/dialog/dialog.component";
import { Messages } from "@config/messages";
import {
  ICreateCustomerRequest,
  ICustomer,
  IUpdateCustomerRequest,
} from "@modals/api.modal";
import { HttpService } from "@services/http.service";
import { Constants } from "@config/constants";
import { ToastService } from "@services/toast.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomerComponent } from "@components/customer/customer.component";
import { Observable } from "rxjs";

@Component({
  selector: "kzn-create-edit-customer",
  standalone: true,
  imports: [DialogComponent, CustomerComponent],
  templateUrl: "./create-edit-customer.component.html",
  styleUrl: "./create-edit-customer.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class CreateEditCustomerComponent {
  @ViewChild("customerCompRef") customerCompRef!: CustomerComponent;
  private _httpService = inject(HttpService);
  private _toastr = inject(ToastService);

  public customerID!: string;
  public customerDetails!: ICustomer;
  public isCreatingOrUpdatingCustomer: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<CreateEditCustomerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { customerID: string; customerDetails: ICustomer }
  ) {
    if (data?.customerDetails && Object.keys(data.customerDetails).length) {
      this.customerDetails = data.customerDetails;
    }
  }

  private createUpdateCustomerApiCall(): Observable<any> {
    const newCustomerDetails = this.customerCompRef.customerForm.value;
    if (!!this.customerDetails) {
      const request: IUpdateCustomerRequest = {
        customerId: this.customerDetails.CustomerId,
        customerName: newCustomerDetails.companyName!,
        isEnabled: newCustomerDetails.isCustomerActive,
        isDeleted: this.customerDetails.IsDeleted,
        email: newCustomerDetails.companyEmail!,
        phone: newCustomerDetails.companyPhone!,
        addressJson: JSON.stringify(newCustomerDetails.companyAddress!),
        userFirstName: newCustomerDetails.userFirstName!,
        userLastName: newCustomerDetails.userLastName!,
        userEmail: newCustomerDetails.userEmail!,
        userPhone: newCustomerDetails.userPhone!,
        userId: newCustomerDetails.userID!,
        isMfaForced: newCustomerDetails.isMFAForced!,
        isUserEnabled: newCustomerDetails.isCustomerActive,
        isUserDeleted: false,
      };
      return this._httpService.post(
        this._httpService.createEndpoint(Constants.API_URI_CUSTOMER_UPDATE),
        request
      );
    } else {
      const request: ICreateCustomerRequest = {
        customerName: newCustomerDetails.companyName!,
        contactEmail: newCustomerDetails.companyEmail!,
        contactPhone: newCustomerDetails.companyPhone!,
        addressJson: JSON.stringify(newCustomerDetails.companyAddress!),
        userFirstName: newCustomerDetails.userFirstName!,
        userLastName: newCustomerDetails.userLastName!,
        userEmail: newCustomerDetails.userEmail!,
        userPhone: newCustomerDetails.userPhone!,
        userLoginId: newCustomerDetails.username!,
        userPassword: newCustomerDetails.userPassword!,
        isMfaForced: newCustomerDetails.isMFAForced!,
      };
      return this._httpService.post(
        this._httpService.createEndpoint(Constants.API_URI_CUSTOMER_CREATE),
        request
      );
    }
  }

  public createOrUpdateCustomer() {
    this.customerCompRef.customerForm.markAllAsTouched();
    if (this.customerCompRef.customerForm.valid) {
      this.customerCompRef.customerForm.disable();
      this.isCreatingOrUpdatingCustomer = true;

      this.createUpdateCustomerApiCall().subscribe(
        (response) => {
          this.isCreatingOrUpdatingCustomer = false;
          this._toastr.showSuccess(
            !!this.customerDetails
              ? Messages.MSG_SUCCESS_CUSTOMER_UPDATE
              : Messages.MSG_SUCCESS_CUSTOMER_CREATE
          );
          this.dialogRef.close({ isSuccess: true });
          this.customerCompRef.customerForm.enable();
        },
        (error) => {
          this.isCreatingOrUpdatingCustomer = false;
          this._httpService.showHttpError(error);
          this.customerCompRef.customerForm.enable();
        }
      );
    }
  }
}
