<kzn-sign-drag-resize [isCustomTagElement]="true" [onRemoveElement]="destroyComponent" [isCustomTagElementStatic]="isCustomTagElementStatic">
    <ng-container tag-content>
        @switch (tag) {
        @case (EnumSignTagTypes.DATE) {
        <mat-form-field appearance="outline" [class]="className" data-type="DATE">
            <mat-label>{{ label || 'Date' }}</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="tagValue" [attr.data-value-available]="!!tagValue" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        } @case (EnumSignTagTypes.CHECKBOX) {
        <mat-checkbox color="primary" [class]="className" data-type="CHECKBOX" [(ngModel)]="tagValue" [attr.data-value-available]="!!tagValue">{{label}}</mat-checkbox>
        } @case (EnumSignTagTypes.INITIALS) {
        <mat-form-field appearance="outline" [class]="className" data-type="INITIALS">
            <mat-label>{{label || 'Initials'}}</mat-label>
            <input matInput [(ngModel)]="tagValue" [attr.data-value-available]="!!tagValue">
        </mat-form-field>
        } @case (EnumSignTagTypes.TEXT) {
        <mat-form-field appearance="outline" [class]="className" data-type="TEXT">
            <mat-label>{{label || 'Text'}}</mat-label>
            <input matInput [(ngModel)]="tagValue" [attr.data-value-available]="!!tagValue">
        </mat-form-field>
        } @case (EnumSignTagTypes.STAMP) {
        <div [class]="className + 'custom-tag__stamp-container'" data-type="STAMP">
            @if(stamp) {
            <img [src]="stamp" height="100%" width="100%" data-value-available="true" (click)="stampRef.click()" />
            } @else {
            <button mat-button mat-raised-button (click)="stampRef.click()">
                {{label || 'Select file'}}
            </button>
            }
        </div>
        <input #stampRef hidden type="file" accept="image/png, image/jpeg, image/jpg"
            (change)="onFileSelected($event)" />
        }
        }
    </ng-container>
</kzn-sign-drag-resize>