<div
  [id]="id"
  class="resizable"
  cdkDrag
  cdkDragBoundary=".page"
  [ngStyle]="{ left: left, top: top, height: height, width: width }"
>
  <div class="action-handles-container">
    <mat-icon
      color="primary"
      class="drag-handle"
      cdkDragHandle
      matTooltip="Drag"
      [ngClass]="{ disabled: !isDraggable }"
      >open_with</mat-icon
    >
    <mat-icon color="warn" (click)="destroyComponent()" matTooltip="Delete"
      >delete</mat-icon
    >
  </div>
  <div class="resizers" [ngClass]="{ 'resizers-comment-only sign-comment-container': isCommentOnly }">
    <div class="sign-container">
      @if(isCommentOnly) {
      <div [class]="Constants.SIGN_COMMENT_CLASS">
        <mat-form-field appearance="outline">
          <mat-label>{{ Constants.DEFAULT_COMMENT_PREFIX }}</mat-label>
          <textarea
            matInput
            [value]="comment"
            placeholder="Enter Comment"
            autofocus
          ></textarea>
        </mat-form-field>
        <label class="sign-comment-suffix">{{
          Constants.DEFAULT_COMMENT_SUFFIX
        }}</label>
      </div>
      } @else {
      <img height="100%" width="100%" [src]="imageBase64" [attr.signatureId]="signatureId" alt="" resize />
      }
      <!-- @if(signComment) {
      <div class="sign-comment-container">
        <div class="sign-comment-prefix">Comment :&nbsp;</div>
        <div class="sign-comment">{{ signComment }}</div>
      </div>
      } -->
    </div>
    <div class="resizer top-left"></div>
    <div class="resizer top-right"></div>
    <div class="resizer bottom-left"></div>
    <div class="resizer bottom-right"></div>
  </div>
</div>
