@if(displayedColumns.length) {
<div class="table-header">
  <div class="table-header__left">
    <input type="text" class="input-sm" placeholder="Search..." (keyup)="applyFilter($event)"
      [ngClass]="showSearch ? '' : 'hidden'" #input />
  </div>
  <div class="table-header__right">
    @if(showRefresh) {
    <button mat-icon-button color="primary" matTooltip="Refresh table data" (click)="refreshTableData()">
      <mat-icon>refresh</mat-icon>
    </button>
    }
    <ng-content select="[header-right]"></ng-content>
  </div>
</div>

<div class="table-container">
  <table mat-table [dataSource]="dataSource" matSort [matSortActive]="defaultSortColumnDef" multiTemplateDataRows
    [matSortDirection]="defaultSortDirection">
    @if(showExpandedRows) {
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row"
        [ngClass]="{'border-bottom-none': showExpandedRows && expandedElement === row}">
        <mat-icon class="clickable" (click)="expandedElement = expandedElement === row ? null : row">{{expandedElement
          === row ?
          'expand_less' : 'expand_more'}}</mat-icon>
      </td>
    </ng-container>
    }
    @for (column of columns; track column) {
    <ng-container [matColumnDef]="column.columnDef">
      @if(column.isSortable ?? true) {
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ column.header }}
      </th>
      } @else {
      <th mat-header-cell *matHeaderCellDef>
        {{ column.header }}
      </th>
      }

      <td mat-cell *matCellDef="let row; let i = dataIndex"
        [ngClass]="{'border-bottom-none': showExpandedRows && expandedElement === row}">
        @if(column.isToggle) {
        @if(column.isLoading && column.isLoading(row)) {
        <mat-spinner [strokeWidth]="3" [diameter]="24" color="primary"></mat-spinner>
        } @else {
        <mat-slide-toggle [checked]="row[column.columnDef]"
          (change)="column.onToggle ? column.onToggle(row, $event) : ''"
          [disabled]="column.isDisabled && column.isDisabled(row)" color="primary"></mat-slide-toggle>
        }
        } @else if(column.isEditable) {
        <kzn-table-dynamic-input [type]="column.inputType" [label]="column.header"
          [options]="column.dropdownOptions || []" [value]="row[column.columnDef]" (onTableDynamicInputValueUpdate)="onTableDynamicInputValueUpdate($event, column.columnDef, i)"></kzn-table-dynamic-input>
        }
        @else {
        <pre>{{ column.cell ? column.cell(row) : row[column.columnDef] }}</pre>
        }
      </td>
    </ng-container>
    }

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row"
        [ngClass]="{'border-bottom-none': showExpandedRows && expandedElement === row}">
        <div class="table-column-actions-wrapper">
          @for (action of rowActions; track action.icon) {
          @if(action.isLoading && action.isLoading(row)) {
          <mat-spinner style="margin-left: 12px;" [strokeWidth]="3" [diameter]="24"
            [color]="action.color"></mat-spinner>
          } @else {
          <button mat-icon-button [color]="action.color" (click)="onActionClick(action, row)"
            [matTooltip]="action.title" [disabled]="action.isDisabled && action.isDisabled(row)">
            <mat-icon>
              {{ action.icon }}
            </mat-icon>
          </button>
          }
          }
        </div>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    @if(showExpandedRows) {
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
        <div [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
          <kzn-table [data]="row.expandedRowsData?.data" [columns]="row.expandedRowsData?.columns"
            [rowActions]="row.expandedRowsData?.actions ?? []" [showSearch]="false"
            [showPagination]="false"></kzn-table>
        </div>
      </td>
    </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    @if(showExpandedRows) {
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
      [ngClass]="expandedElement === row ? 'row-expanded' : 'row-collapsed'"></tr>
    }

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data matching the filter "{{ showSearch ? input.value : '' }}"
      </td>
    </tr>
  </table>
</div>
@if(showPagination) {
<mat-paginator (page)="scrollUp()" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
}
}