import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { IUserDetails } from "@modals/app.modal";
import { AuthService } from "@services/auth.service";
import { HttpService } from "@services/http.service";
import { Constants } from "@config/constants";
import { ToastService } from "@services/toast.service";
import { Messages } from "@config/messages";
import { AppService } from "@services/app.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, Subscription } from "rxjs";

@Component({
  selector: "kzn-header",
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input({ required: true }) sidebarRef: any;

  public userDetails: IUserDetails | undefined;
  public isSidebarToggleDisabled: boolean = false;

  private routeSubscription!: Subscription;

  constructor(
    private _authService: AuthService,
    private _httpService: HttpService,
    private _tostr: ToastService,
    private _appService: AppService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.updateRouteData(this._activatedRoute);
    this.routeSubscription = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateRouteData(this._activatedRoute);
    });

    this.userDetails = this._authService.getUserDetails();
  }

  private updateRouteData(route: ActivatedRoute) {
    let activeRoute = route;
    while (activeRoute.firstChild) {
      activeRoute = activeRoute.firstChild;
    }
    this.isSidebarToggleDisabled = activeRoute.snapshot.data?.['isSidebarToggleDisabled'] || false;
  }

  toggleSidebar() {
    this.sidebarRef.toggle();
  }

  logout(): void {
    this._appService.updatePageLoaderVisibility(true);
    this._httpService
      .post(this._httpService.createEndpoint(Constants.API_URI_LOGOUT))
      .subscribe(
        (response) => {
          this._appService.updatePageLoaderVisibility(false);
          this._authService.afterLogoutSuccess();
          this._tostr.showSuccess(Messages.MSG_SUCCESS_LOGOUT);
        },
        (error) => {
          this._httpService.showHttpError(error);
          this._appService.updatePageLoaderVisibility(false);
        }
      );
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }
}
