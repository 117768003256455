import { Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'kzn-layout-before-login',
  standalone: true,
  imports: [RouterOutlet, MatCardModule, MatDividerModule],
  templateUrl: './layout-before-login.component.html',
  styleUrl: './layout-before-login.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LayoutBeforeLoginComponent {

}
