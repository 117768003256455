<form [formGroup]="form" class="form__table__dynamic-input">
    <mat-form-field class="table__dynamic-input">
        @switch (type) {
        @case ("email") {
        <ng-container>
            <input matInput formControlName="control" type="email" [placeholder]="'Enter ' + label" />
        </ng-container>
        }
        @case ("dropdown") {
        <ng-container>
            <mat-select formControlName="control" [placeholder]="'Select ' + label">
                <mat-option *ngFor="let option of options" [value]="option">{{ option | titlecase }}</mat-option>
            </mat-select>
        </ng-container>
        }
        @case ("tel") {
        <ngx-mat-intl-tel-input [preferredCountries]="['in', 'us', 'gb']" [enablePlaceholder]="false"
            [enableSearch]="true" describedBy="phoneInput" formControlName="control"
            [inputPlaceholder]="'Enter ' + label"></ngx-mat-intl-tel-input>
        }
        @default {
        <ng-container>
            <input matInput formControlName="control" type="email" [placeholder]="'Enter ' + label" />
        </ng-container>
        }
        }
    </mat-form-field>
</form>