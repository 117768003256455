import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public tableInputData: Array<any> = [];

  getFormattedNumber(phone: string, countryCode: string) {
    return `${countryCode}-${phone.split(countryCode)[1]}`
  }

  constructor() { }
}
