import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "kzn-footer",
  standalone: true,
  imports: [],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  currentYear: number = new Date().getFullYear();
  
  @Input() hideLogo: boolean = false;
}
