<mat-card class="mat-elevation-z8">
  <mat-tab-group>
    <mat-tab label="Customer Details">
      <div class="customer-details-container">
        <div class="customer-details-action-buttons">
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="openEditCustomerDialog()"
          >
            <mat-icon>edit</mat-icon>Edit Customer
          </button>
        </div>
        <kzn-customer
          [customerDetails]="customerDetails"
          [isViewOnly]="true"
        ></kzn-customer>
      </div>
    </mat-tab>
    <mat-tab label="Customer Subscriptions">
      <kzn-customer-subscriptions
        [customerID]="customerDetails.CustomerId"
        [customerSubscriptions]="customerDetails.Subscriptions"
        (customerSubscriptionCreated)="customerSubscriptionCreated.emit()"
      ></kzn-customer-subscriptions>
    </mat-tab>
  </mat-tab-group>
</mat-card>
