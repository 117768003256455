import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OpenMobileAppComponent } from '@components/open-mobile-app/open-mobile-app.component';
import { LoaderComponent } from '@components/layouts/loader/loader.component';
import { AppService } from '@services/app.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kzn-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LoaderComponent],
  templateUrl: './root.component.html',
  styleUrl: './root.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RootComponent implements OnInit, OnDestroy {
  private _pageLoaderSubscription!: Subscription;
  title = 'kaizen';
  isAndroid: boolean = false;
  isiOS: boolean = false;
  isAppReady: boolean = false;
  pageLoaderDetails = {
    show: false,
    message: '',
  }

  constructor(private dialog: MatDialog, private _appService: AppService) { }

  ngOnInit(): void {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    this.isAndroid = /android/i.test(userAgent);
    this.isiOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;

    if (this.isAndroid || this.isiOS) this.openMobileAppDialog();
    else {
      this.isAppReady = true;
      this._pageLoaderSubscription = this._appService.pageLoader$.subscribe(details => {
        this.pageLoaderDetails = details;
      });
    }
  }

  public openMobileAppDialog() {
    const dialogRef = this.dialog.open(OpenMobileAppComponent, {
      data: {
        isAndroid: this.isAndroid,
        isiOS: this.isiOS,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.isAppReady = true;
    });
  }

  ngOnDestroy(): void {
    this._pageLoaderSubscription?.unsubscribe();
  }
}
