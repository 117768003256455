import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Constants } from '@config/constants';
import { FONT_FAMILIES } from '@config/font-families.constant';
import { Messages } from '@config/messages';
import { AppService } from '@services/app.service';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'kzn-upload-sign-initials',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, ReactiveFormsModule, CommonModule, MatRadioModule, MatCardModule, FormsModule],
  templateUrl: './upload-sign-initials.component.html',
  styleUrl: './upload-sign-initials.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class UploadSignInitialsComponent implements OnInit, OnDestroy {
  Messages = Messages;
  @ViewChild('canvas') canvas!: ElementRef;
  @Input() signerName: string = '';
  private initialsCtrlValidations = [Validators.required, Validators.minLength(2), Validators.maxLength(12)]
  initialsCtrl: FormControl = new FormControl('', this.initialsCtrlValidations);
  initialImgCtrl: FormControl = new FormControl('');
  initials: Array<{
    image: string,
    fontFamily: string
  }> = [];
  onInitialImageChange = output<string>();
  initialsCtrlSubscription!: Subscription;
  
  constructor(private appService: AppService) {
    this.initialsCtrlSubscription = this.initialsCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(() => {
      if (!this.initialsCtrl.touched) this.initialsCtrl.markAsTouched();
      if (this.initialsCtrl.valid) this.generateInitials();
    })
  }

  ngOnInit(): void {
    if(this.signerName) this.initialsCtrl.setValue(this.signerName);
  }

  async generateTextToImage(text: string, fontFamily: string): Promise<string> {
    const tCtx: any = this.canvas.nativeElement.getContext("2d");
    const font = `600 50px ${fontFamily}`;

    // Ensure the font is loaded
    await document.fonts.load(font);

    // Set canvas size
    tCtx.canvas.width = tCtx.measureText(text).width + 200;
    tCtx.canvas.height = 80;

    // Clear the canvas
    tCtx.clearRect(0, 0, tCtx.canvas.width, tCtx.canvas.height);

    // Set font and draw text
    tCtx.font = font;
    tCtx.fillStyle = "#000";
    tCtx.textBaseline = "middle";
    tCtx.textAlign = "center";
    tCtx.fillText(text, tCtx.canvas.width / 2, tCtx.canvas.height / 2);

    return tCtx.canvas.toDataURL();
  }

  generateInitials() {
    this.initialsCtrl.markAsTouched();
    this.initials = [];

    if (this.initialsCtrl.valid && this.initialsCtrl.value) {
      const fontFamilies = this.appService.getRandomElements(FONT_FAMILIES, Constants.DEFAULT_SIGN_INITIALS_COUNT);
      for (let i = 0; i < Constants.DEFAULT_SIGN_INITIALS_COUNT; i++) {
        let image;
        (async () => {
          image = await this.generateTextToImage(this.initialsCtrl.value, fontFamilies[i]);
          this.initials.push({ image, fontFamily: fontFamilies[i] });
        })();
      }
    }
  }

  initialImageChange() {
    this.onInitialImageChange.emit(this.initialImgCtrl.value);
  }

  resetInitials() {
    this.initialsCtrl.reset();
    this.initialsCtrl.setErrors(null);
    this.initialImgCtrl.reset();
    this.initials = [];
  }

  ngOnDestroy(): void {
    this.initialsCtrlSubscription?.unsubscribe();
  }
}
