<mat-card class="mat-elevation-z8 pd-1 new-request-container">
    <div class="new-request-file-upload-container">
        <div class="file-upload-container">
            <span class="file-upload-title">Document to upload</span>
            <button type="button" mat-raised-button color="primary" (click)="newRequestFile.click()">
                <mat-icon>upload</mat-icon>
                @if(fileName) { Change file }
                @else { Select file }
            </button>
        </div>

        @if(fileName) {
        <div class="selected-file-container">
            <mat-icon class="success">check_circle</mat-icon>
            <span class="file-upload-title">{{fileName}}</span>
        </div>
        }
        <input #newRequestFile hidden type="file" (change)="onFileSelected($event)" accept=".pdf" />
    </div>

    <div class="new-request-form" [formGroup]="form">
        <button type="button" mat-raised-button (click)="addRow()"
            class="button-add-new-signer"><mat-icon>add</mat-icon>Add signer/reviewer</button>
        @for(row of rows.controls; track row; let i = $index) {
        <div class="new-request-form-row" [formGroup]="getFormGroupAtIndex(i)">
            <span class="mg-bt-22">{{i + 1}}</span>
            <mat-form-field appearance="outline">
                <mat-label>Role</mat-label>
                <mat-select formControlName="signer">
                    @for (option of signerOptions; track option.value) {
                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
                <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input type="email" matInput placeholder="Enter email" formControlName="email" />
                @if(getFormGroupAtIndex(i).get('email')?.errors?.['required']) {
                <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
                } @else {
                <mat-error>{{ Messages.MSG_ERROR_INVALID_EMAIL }}</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter name" formControlName="name" />
                <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
            </mat-form-field>
            <mat-icon color="warn" class="icon-delete mg-bt-22" [ngClass]="{'disabled': rows.controls.length === 1}"
                (click)="removeRow(i)">delete</mat-icon>
        </div>
        }
        <button type="button" mat-raised-button (click)="createRequest()" color="primary"
            class="button-add-new-signer">Submit</button>
    </div>
</mat-card>