<mat-toolbar class="wrapper-header">
  <button mat-icon-button color="primary" (click)="toggleSidebar()">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="header-logo" src="./../../../assets/logo.png" />
  <span class="header-spacer"></span>
  @if(userDetails) {
  <span class="user-welcome-message"
    >Welcome back, {{ userDetails.FirstName }}!</span
  >
  }
  <button mat-icon-button color="primary" [matMenuTriggerFor]="profileMenu">
    <mat-icon>account_circle</mat-icon>
  </button>

  <mat-menu #profileMenu="matMenu">
    <!-- <button mat-menu-item>
            <mat-icon color="primary">person</mat-icon>
            <span>Profile</span>
        </button> -->
    <button mat-menu-item (click)="logout()">
      <mat-icon color="primary">logout</mat-icon>
      <span>Log Out</span>
    </button>
  </mat-menu>
</mat-toolbar>
@if(isLoggingOut) {
<kzn-loader [isOverlay]="true"></kzn-loader>
}
