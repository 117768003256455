import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Messages } from '@config/messages';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { Subscription } from 'rxjs';
import { EnumTableDynamicInputType } from '@modals/app.modal';
import { Country } from 'ngx-mat-intl-tel-input/lib/model/country.model';
import { TableService } from '@services/table.service';

@Component({
  selector: 'kzn-table-dynamic-input',
  standalone: true,
  imports: [CommonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    ReactiveFormsModule,
    NgxMatIntlTelInputComponent],
  templateUrl: './table-dynamic-input.component.html',
  styleUrl: './table-dynamic-input.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TableDynamicInputComponent implements OnInit, OnDestroy {
  @Input() type: EnumTableDynamicInputType = EnumTableDynamicInputType.TEXT;
  @Input() label: string = '';
  @Input() options: string[] = [];
  @Input() value: string = '';
  @Output() onTableDynamicInputValueUpdate = new EventEmitter<string>();

  private _countryCode: string = '';
  private _tableService = inject(TableService);
  Messages = Messages;
  form!: FormGroup;
  controlValueChangeSubscription!: Subscription;

  get control() {
    return this.form.get('control') as FormControl;
  }

  ngOnInit() {
    this.form = new FormGroup({
      control: new FormControl(this.value, [Validators.required, ...(this.type === 'email' ? [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")] : [])]),
    });

    this.controlValueChangeSubscription = this.control?.valueChanges.subscribe((value) => {
      let controlValue = value;
      if (this.type === 'tel' && controlValue) {
        controlValue = this._tableService.getFormattedNumber(controlValue, this._countryCode);
      }
      this.onTableDynamicInputValueUpdate.emit(controlValue);
    });
  }

  onCountryChanged(event: Country) {
    this._countryCode = '+' + event.dialCode;
  }

  ngOnDestroy(): void {
    this.controlValueChangeSubscription?.unsubscribe();
  }
}
