import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppService {
  private _defaultPageLoaderMessage = 'Please wait...';
  private _pageLoader = new BehaviorSubject({
    show: false,
    message: this._defaultPageLoaderMessage
  });
  pageLoader$ = this._pageLoader.asObservable();

  constructor() { }

  public updatePageLoaderVisibility(show: boolean, message: string = this._defaultPageLoaderMessage) {
    this._pageLoader.next({
      show,
      message
    });
  }

  public getFormattedDate(dateStringinUTC: string): string {
    if (dateStringinUTC) {
      const date = new Date(dateStringinUTC + "Z");
      return date.toLocaleString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      return "-";
    }
  }

  getRandomElements(arr: Array<any>, count: number): Array<any> {
    const uniqueElements = new Set(arr);
    const uniqueArray = Array.from(uniqueElements);
    const shuffled = uniqueArray.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  }
}
