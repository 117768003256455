import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Constants } from "@config/constants";
import { IUserDetails } from "@modals/app.modal";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _router = inject(Router);
  private _userDetails: IUserDetails | undefined;

  public afterLoginSuccess(authToken?: string): void {
    this.navigateAfterLogin();
    if (authToken) this.setAuthTokenToLocalStorage(authToken);
  }

  public afterLogoutSuccess(): void {
    this.removeAuthTokenFromLocalStorage();
    this.navigateAfterLogout();
  }

  public navigateAfterLogin(uri: string = Constants.DEFAULT_ROUTE_AFTER_LOGIN): void {
    this._router.navigate([uri]);
  }

  public navigateAfterLogout(): void {
    this._router.navigate(["login"]);
  }

  public setAuthTokenToLocalStorage(authToken: string): void {
    localStorage.setItem("token", authToken);
  }

  public getAuthTokenFromLocalStorage(): string | null {
    return localStorage.getItem("token");
  }

  public removeAuthTokenFromLocalStorage(): void {
    localStorage.removeItem("token");
  }

  public setUserDetails(details: IUserDetails): void {
    this._userDetails = details;
  }

  public getUserDetails(): IUserDetails | undefined {
    return this._userDetails;
  }

  public isAdminUser(): boolean {
    return this._userDetails?.CustomerId ? false : true;
  }
}
