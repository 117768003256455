<div class="wrapper-layout-before-login">
    <div class="wrapper-layout-before-login__left">
        <mat-card class="card-layout-before-login">
            <mat-card-header class="a-center">
                <img class="img-logo" src="./../../../assets/logo.png" />
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="card-layout-before-login-content">
                <router-outlet></router-outlet>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="wrapper-layout-before-login__right">
        <div class="wrapper-layout-before-login__right-bg"></div>
        <img src="./../../../assets/login-side.png" />
    </div>
</div>