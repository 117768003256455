import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderBeforeLoginService {
  public userInitials = signal('');

  constructor() { }

  public updateUserInitials(initials: string) {
    this.userInitials.set(initials);
  }
}
