import {
  Component,
  ElementRef,
  inject,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DialogComponent } from "@components/layouts/dialog/dialog.component";
import { MatIconModule } from "@angular/material/icon";
import { MatTabChangeEvent, MatTabsModule } from "@angular/material/tabs";
import {
  AngularSignaturePadModule,
  NgSignaturePadOptions,
  SignaturePadComponent,
} from "@almothafar/angular-signature-pad";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { Messages } from "@config/messages";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Constants } from "@config/constants";
import { UploadSignInitialsComponent } from "@components/upload-sign-initials/upload-sign-initials.component";
import { UploadSignRecentUploadsComponent } from "@components/upload-sign-recent-uploads/upload-sign-recent-uploads.component";
import { SignService } from "@services/sign.service";

@Component({
  selector: "kzn-upload-sign",
  standalone: true,
  imports: [
    DialogComponent,
    MatTabsModule,
    MatIconModule,
    AngularSignaturePadModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    UploadSignInitialsComponent,
    UploadSignRecentUploadsComponent
  ],
  templateUrl: "./upload-sign.component.html",
  styleUrl: "./upload-sign.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class UploadSignComponent {
  @ViewChild(UploadSignInitialsComponent) uploadSignInitialsComponentRef!: UploadSignInitialsComponent;
  @ViewChild(UploadSignRecentUploadsComponent) uploadSignRecentUploadsComponentRef!: UploadSignRecentUploadsComponent;
  @ViewChild("uploadSignFileInput") uploadSignFileInputRef!: ElementRef;
  @ViewChild("commentCanvas") commentCanvas!: ElementRef;
  @ViewChild("signature")
  public signaturePad!: SignaturePadComponent;
  public signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: document.getElementById("draw-tab-content")?.offsetWidth!,
    canvasHeight: 150,
  };
  public selectedImage: string | null = null;
  public signatureId: string = '';
  public Messages = Messages;
  public signComment = new FormControl("", Validators.maxLength(100));
  public docComment = new FormControl("", Validators.required);
  public isComment!: boolean;
  public signerName: string = '';
  private _isResponsiveContainer: boolean = false;
  public Constants = Constants;
  public showMaxFileSizeError: boolean = false;
  public showInvalidFileTypeError: boolean = false;

  public signService = inject(SignService);

  constructor(
    private dialogRef: MatDialogRef<UploadSignComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { isComment: boolean, isResponsiveContainer: boolean, signerName: string }
  ) {
    this.isComment = data.isComment ?? false;
    this.signerName = data.signerName ?? '';
    this._isResponsiveContainer = data.isResponsiveContainer ?? false;
  }

  selectedTabChange(event: MatTabChangeEvent): void {
    this.resetForm();

    if (event.index === 1) {
      setTimeout(() => {
        this.signaturePad?.set(
          "canvasWidth",
          document.getElementById("draw-tab-content")?.offsetWidth! - 5
        );
        this.signaturePad?.clear();
      });
    }
  }

  updateSelectedImage(image: string, signatureId?: string) {
    this.selectedImage = image;
    this.signatureId = signatureId ?? '';
  }

  drawComplete(event: MouseEvent | Touch) {
    this.updateSelectedImage(this.signaturePad?.toDataURL());
  }

  uploadSign(): void {
    this.uploadSignFileInputRef.nativeElement.click();
  }

  onFileInputChange(event: any) {
    const file = event.target?.files[0];

    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (!allowedTypes.includes(file.type)) {
        this.showMaxFileSizeError = false;
        this.showInvalidFileTypeError = true;
        return;
      }

      this.showInvalidFileTypeError = false;
      const reader = new FileReader();
      if (file.size > Constants.MAX_SIGN_UPLOAD_FILE_SIZE_IN_KB) {
        this.showMaxFileSizeError = true;
        return;
      } else {
        this.showMaxFileSizeError = false;
      }

      reader.onloadend = async () => {
        this.updateSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }

  insertImage() {
    let data = {};
    if (this.isComment) {
      const comment = this.docComment.value;
      const cCtx = this.commentCanvas.nativeElement.getContext("2d");
      cCtx.font = Constants.DEFAULT_SIGN_COMMENT_FONT;
      cCtx.canvas.width = cCtx.measureText(comment).width;
      cCtx.fillText(comment, 0, 16);
      const commentImage = cCtx.canvas.toDataURL();
      data = {
        imageBase64: commentImage,
        comment,
        signComment: "",
        isCommentOnly: true,
        isResponsiveContainer: this._isResponsiveContainer
      };
    } else {
      data = {
        imageBase64: this.selectedImage,
        signComment: this.signComment.value,
        isCommentOnly: false,
        signatureId: this.signatureId
      };
    }
    this.dialogRef.close(data);
  }

  handleInitialImageChange(selectedInitialImage: string) {
    this.updateSelectedImage(selectedInitialImage);
  }

  handleRecentUploadImageChange(selectedRecentUploadImageData: {
    image: string,
    signatureId: string
  }) {
    this.updateSelectedImage(selectedRecentUploadImageData.image, selectedRecentUploadImageData.signatureId);
  }

  resetForm() {
    this.signComment.reset();
    this.docComment.reset();
    this.selectedImage = null;
    this.signatureId = '';
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
    const cCtx = this.commentCanvas?.nativeElement?.getContext("2d");
    if (cCtx) {
      cCtx.clearRect(0, 0, this.commentCanvas.nativeElement.width, this.commentCanvas.nativeElement.height);
    }
    this.showMaxFileSizeError = false;
    this.showInvalidFileTypeError = false;
    if (this.uploadSignFileInputRef?.nativeElement) this.uploadSignFileInputRef.nativeElement.value = '';
    this.uploadSignInitialsComponentRef.resetInitials();
    this.uploadSignRecentUploadsComponentRef.resetRecentUpload();
  }

}
