<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <ng-content></ng-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="isActionInProgress">
    Cancel
  </button>
  @if(showReset) {
    <button mat-button mat-raised-button (click)="reset()" [disabled]="isActionInProgress">
      Reset
    </button>
  }
  <button
    mat-button
    mat-raised-button
    [color]="actionColor"
    cdkFocusInitial
    (click)="saveData()"
    [class.spinner]="isActionInProgress"
    [disabled]="isActionDisabled || isActionInProgress"
  >
    {{ actionLabel }}
  </button>
</mat-dialog-actions>
