import { Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { HeaderBeforeLoginService } from '@services/services/header-before-login.service';

@Component({
  selector: 'kzn-header-before-login',
  standalone: true,
  imports: [MatCardModule],
  templateUrl: './header-before-login.component.html',
  styleUrl: './header-before-login.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HeaderBeforeLoginComponent {
  private _headerBeforeLoginService = inject(HeaderBeforeLoginService);

  signerNameInitials = computed(() => this._headerBeforeLoginService.userInitials());
}
