<kzn-dialog
  [title]="!!customerDetails ? 'Update Customer' : 'Create Customer'"
  [isActionInProgress]="isCreatingOrUpdatingCustomer"
  [actionLabel]="customerDetails ? 'Save' : 'Create'"
  (actionFired)="createOrUpdateCustomer()"
  class="dialog-create-customer"
>
  <kzn-customer
    [isEdit]="!!customerDetails"
    [customerDetails]="customerDetails"
    #customerCompRef
  ></kzn-customer>
</kzn-dialog>
