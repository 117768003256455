<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    mode="side"
    [opened]="!mobileQuery.matches"
    class="sidenav"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
    fixedTopGap="56"
  >
    <mat-nav-list>
      @for(menu of menus; track menu.label; let index = $index) { @if(menu.show)
      {
      <a
        id="menu-item-{{ index }}"
        mat-list-item
        [routerLink]="menu.url"
        [activated]="currentRoute === menu.url"
        class="sidenav-menu-item"
      >
        <mat-icon color="primary">{{ menu.icon }}</mat-icon>
        <span>{{ menu.label }}</span>
      </a>
      } }
    </mat-nav-list>
    <div class="logo-sidebar">
      <img src="./../../assets/logo-powered-by.jpeg" />
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="sidenav-content">
      @if(!isAdminOnly || (isAdminOnly && isAdminUser)) {
      <div class="header-page">
        <span class="title-page">{{ pageTitle }}</span>
      </div>
      <router-outlet></router-outlet>
      } @else {
      <kzn-status-message
        [heading]="Messages.MSG_ERROR_TITLE_NOT_AUTHORIZED"
        [message]="Messages.MSG_ERROR_NOT_AUTHORIZED"
      ></kzn-status-message>
      }
    </div>
    <kzn-footer [hideLogo]="true"></kzn-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
