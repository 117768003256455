<form id="form-customer" [formGroup]="customerForm">
  <fieldset>
    <legend>Company Details</legend>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Enter company name"
            formControlName="companyName"
          />
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            placeholder="Enter company email"
            formControlName="companyEmail"
          />
          @if(customerForm.get('companyEmail')?.errors?.['required']) {
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
          } @else {
          <mat-error>{{ Messages.MSG_ERROR_INVALID_EMAIL }}</mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Phone</mat-label>
          <input
            matInput
            placeholder="Enter company phone number"
            formControlName="companyPhone"
          />
          @if(customerForm.get('companyPhone')?.errors?.['required']) {
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
          } @else {
          <mat-error>{{ Messages.MSG_ERROR_INVALID_PHONE_NUMBER }}</mat-error>
          }
        </mat-form-field>
      </div>

      <ng-container formGroupName="companyAddress">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Country</mat-label>
            <input
              matInput
              placeholder="Enter country"
              formControlName="country"
            />
            <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <ng-container formGroupName="companyAddress">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Street</mat-label>
            <input
              matInput
              placeholder="Enter street"
              formControlName="street"
            />
            <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput placeholder="Enter city" formControlName="city" />
            <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <ng-container formGroupName="companyAddress">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>State</mat-label>
            <input matInput placeholder="Enter state" formControlName="state" />
            <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Zip Code</mat-label>
            <input
              matInput
              placeholder="Enter zip code"
              formControlName="zipCode"
            />
            @if(customerForm.get('zipCode')?.errors?.['required']) {
            <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
            } @else {
            <mat-error>{{ Messages.MSG_ERROR_INVALID_ZIP_CODE }}</mat-error>
            }
          </mat-form-field>
        </div>
      </ng-container>
      @if(!!customerDetails) {
      <div class="col">
        <mat-form-field
          floatLabel="always"
          appearance="fill"
          class="toggle-customer"
        >
          <mat-slide-toggle
            color="primary"
            formControlName="isCustomerActive"
            labelPosition="before"
            >Active</mat-slide-toggle
          >
          <input matInput hidden />
        </mat-form-field>
      </div>
      }
    </div>
  </fieldset>
  <fieldset>
    <legend>User Details</legend>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input
            matInput
            placeholder="Enter first name"
            formControlName="userFirstName"
          />
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            placeholder="Enter last name"
            formControlName="userLastName"
          />
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            placeholder="Enter email"
            formControlName="userEmail"
          />
          @if(customerForm.get('userEmail')?.errors?.['required']) {
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
          } @else {
          <mat-error>{{ Messages.MSG_ERROR_INVALID_EMAIL }}</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Phone</mat-label>
          <input
            matInput
            placeholder="Enter phone number"
            formControlName="userPhone"
          />
          @if(customerForm.get('userPhone')?.errors?.['required']) {
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
          } @else {
          <mat-error>{{ Messages.MSG_ERROR_INVALID_PHONE_NUMBER }}</mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input
            matInput
            placeholder="Enter username"
            formControlName="username"
          />
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
        </mat-form-field>
      </div>
      @if(!isViewOnly && !isEdit) {
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            [type]="showPassword ? 'text' : 'password'"
            matInput
            placeholder="Enter password"
            formControlName="userPassword"
          />
          <mat-icon
            matSuffix
            class="clickable primary"
            (click)="togglePasswordVisibility()"
            >{{ showPassword ? "visibility_off" : "visibility" }}</mat-icon
          >
          <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
        </mat-form-field>
      </div>
      } @if(isViewOnly || isEdit) {
      <div class="col">
        <mat-form-field
          floatLabel="always"
          appearance="fill"
          class="toggle-customer"
        >
          <mat-slide-toggle
            color="primary"
            formControlName="isMFAForced"
            labelPosition="before"
            >MFA</mat-slide-toggle
          >
          <input matInput hidden />
        </mat-form-field>
      </div>
      }
    </div>
  </fieldset>
</form>
