import { Routes } from "@angular/router";
import { LoginComponent } from "../components/login/login.component";
import { LayoutBeforeLoginComponent } from "@components/layouts/layout-before-login/layout-before-login.component";
import { OtpVerificationComponent } from "@components/otp-verification/otp-verification.component";
import { ResetPasswordComponent } from "@components/reset-password/reset-password.component";
import { LayoutAfterLoginComponent } from "@components/layouts/layout-after-login/layout-after-login.component";
import { RequestsComponent } from "@components/requests/requests.component";
import { SignComponent } from "@components/sign/sign.component";
import { afterLoginAuthGuard, beforeLoginAuthGuard } from "@guards/auth.guard";
import { SettingsComponent } from "@components/settings/settings.component";
import { CustomersComponent } from "@components/customers/customers.component";
import { BlogsComponent } from "@components/blogs/blogs.component";
import { Constants } from "./constants";
import { NewRequestComponent } from "@components/new-request/new-request.component";
import { CreateEditEmailTemplateComponent } from "@components/create-edit-email-template/create-edit-email-template.component";
import { EmailTemplateComponent } from "@components/email-template/email-template.component";

const titleSuffix = (title: string) => {
  return `${title} | Kaizen`;
};

export const routes: Routes = [
  {
    path: "",
    component: LayoutBeforeLoginComponent,
    canActivateChild: [beforeLoginAuthGuard],
    children: [
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
      },
      {
        path: "login",
        component: LoginComponent,
        title: titleSuffix("Login"),
      },
      {
        path: "reset-password",
        component: ResetPasswordComponent,
        title: titleSuffix("Reset Password"),
      },
      {
        path: "reset-password/:token",
        component: ResetPasswordComponent,
        title: titleSuffix("Reset Password"),
      },
      {
        path: "otp-verification",
        component: OtpVerificationComponent,
        title: titleSuffix("OTP Verification"),
      },
    ],
  },
  {
    path: "",
    component: LayoutAfterLoginComponent,
    canActivateChild: [afterLoginAuthGuard],
    children: [
      {
        path: "",
        redirectTo: Constants.DEFAULT_ROUTE_AFTER_LOGIN,
        pathMatch: "full",
      },
      {
        path: "requests",
        component: RequestsComponent,
        title: titleSuffix("My Requests")
      },
      {
        path: "requests/new",
        component: NewRequestComponent,
        title: titleSuffix("New Request"),
      },
      {
        path: "settings",
        component: SettingsComponent,
        title: titleSuffix("Settings"),
      },
      {
        path: "customers",
        component: CustomersComponent,
        title: titleSuffix("Customers"),
        data: {
          isAdminOnly: true,
        },
      },
      {
        path: "customers/:customerID",
        component: CustomersComponent,
        title: titleSuffix("Customer Details and Subscriptions"),
        data: {
          isAdminOnly: true,
        },
      },
      {
        path: "blogs",
        component: BlogsComponent,
        title: titleSuffix("Blogs"),
      },
      {
        path: "email-templates",
        component: EmailTemplateComponent,
        title: titleSuffix("Email Templates"),
      },
      {
        path: "email-templates/new",
        component: CreateEditEmailTemplateComponent,
        title: titleSuffix("New Email Template"),
      },
      {
        path: "email-templates/edit/:emailTemplateID",
        component: CreateEditEmailTemplateComponent,
        title: titleSuffix("Edit Email Template"),
        data: {
          isEdit: true
        }
      },
    ],
  },
  {
    path: "sign/:requestID/:signerID",
    component: SignComponent,
  },
  {
    path: "view/:requestID",
    component: SignComponent,
    data: {
      isView: true,
    },
  },
  {
    path: "**",
    redirectTo: "/",
    pathMatch: "full",
  },
];
