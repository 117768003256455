import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { HeaderBeforeLoginComponent } from '@components/layouts/header-before-login/header-before-login.component';
import { FooterComponent } from '@components/layouts/footer/footer.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'kzn-layout-guest-sign',
  standalone: true,
  imports: [RouterOutlet, HeaderBeforeLoginComponent, FooterComponent, CommonModule],
  templateUrl: './layout-guest-sign.component.html',
  styleUrl: './layout-guest-sign.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LayoutGuestSignComponent implements OnInit {
  isCustomerLayout: boolean = false;

  private _activatedRoute = inject(ActivatedRoute);

  ngOnInit(): void {
    while (this._activatedRoute.firstChild) {
      this._activatedRoute = this._activatedRoute.firstChild;
    }
    this.isCustomerLayout = this._activatedRoute.snapshot.data?.['isCustomerLayout'] || false;
  }
}
