import { Component, ViewEncapsulation } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NgOtpInputModule } from "ng-otp-input";
import { Config } from "ng-otp-input/lib/models/config";

@Component({
  selector: "kzn-otp-input",
  standalone: true,
  imports: [NgOtpInputModule],
  templateUrl: "./otp-input.component.html",
  styleUrl: "./otp-input.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class OtpInputComponent {
  public config: Config = {
    length: 6,
    allowNumbersOnly: true,
    containerClass: "otp-input-container",
    inputClass: "",
    disableAutoFocus: true,
  };
  public otpFormCtrl: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(6),
  ]);

  constructor() {
    this.otpFormCtrl.statusChanges.subscribe((status) => {
      this.updateInputClass(status === "INVALID" ? "invalid" : "");
    });
  }

  public updateInputClass(className: string = ""): void {
    this.config = { ...this.config, inputClass: className };
  }

  public onFocusOut(): void {
    this.otpFormCtrl.markAsTouched();
    this.updateInputClass(this.otpFormCtrl.invalid ? "invalid" : "");
  }

  public isOtpInputValid(): boolean {
    return this.otpFormCtrl.valid;
  }

  public getOtpValue(): string {
    return this.otpFormCtrl.value;
  }
}
