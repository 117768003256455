import { inject } from "@angular/core";
import { CanActivateChildFn, Router } from "@angular/router";
import { Constants } from "@config/constants";
import { AuthService } from "@services/auth.service";

export const beforeLoginAuthGuard: CanActivateChildFn = (route, state) => {
  const _router = inject(Router);
  const bypassGuard =
    _router.getCurrentNavigation()?.extras?.state?.["bypassGuard"] ?? false;
  const uri = _router.getCurrentNavigation()?.extras?.state?.["uri"];
  const authService = new AuthService();

  const token = authService.getAuthTokenFromLocalStorage();
  if (token) {
    authService.navigateAfterLogin(
      bypassGuard && uri === Constants.DEFAULT_ROUTE_AFTER_LOGIN
        ? "customers"
        : Constants.DEFAULT_ROUTE_AFTER_LOGIN
    );
  }

  return true;
};

export const afterLoginAuthGuard: CanActivateChildFn = (route, state) => {
  const authService = new AuthService();

  const token = authService.getAuthTokenFromLocalStorage();
  if (!token) {
    authService.navigateAfterLogout();
  }

  return true;
};
