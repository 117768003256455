import { Component, AfterViewInit, ElementRef, ViewChild, OnInit } from '@angular/core';
import { StatusMessageComponent } from '@components/layouts/status-message/status-message.component';
import * as pdfjsLib from 'pdfjs-dist';

@Component({
  selector: 'app-pdf-thank-you',
  standalone: true,
  imports: [StatusMessageComponent],
  templateUrl: './pdf-thank-you.component.html',
  styleUrl: './pdf-thank-you.component.scss'
})
export class PdfThankYouComponent implements AfterViewInit, OnInit {
  @ViewChild('pdfContainer') pdfContainer!: ElementRef<HTMLDivElement>;

  formattedData: any;

  ngOnInit(): void {
    const storedData = localStorage.getItem('submittedPdfData');

    if (storedData) {
      try {
        this.formattedData = JSON.parse(storedData);
        if (!this.formattedData?.annotations || !this.formattedData.annotations.length) {
          console.warn('No annotations found in submitted data.');
        } else {
          console.log('Annotations loaded:', this.formattedData.annotations);
        }
      } catch (error) {
        console.error('Error parsing stored data:', error);
      }
    } else {
      console.warn('No submitted data found in localStorage.');
    }
  }

  ngAfterViewInit(): void {
    const storedData = localStorage.getItem('submittedPdfData');
    if (storedData) {
      this.formattedData = JSON.parse(storedData);
      this.renderPDFWithAnnotations();
    }
  }

  async renderPDFWithAnnotations(): Promise<void> {
    const { docContent, annotations } = this.formattedData;

    if (!this.formattedData?.annotations || !Array.isArray(this.formattedData.annotations)) {
      console.warn('No annotations found in submitted data.');
      return;
    }

    const pdfData = atob(docContent.split(',')[1]);

    const loadingTask = pdfjsLib.getDocument({ data: pdfData });
    const pdf = await loadingTask.promise;

    const pdfContainer = this.pdfContainer.nativeElement;
    pdfContainer.innerHTML = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1.5 });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')!;
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const pageDiv = document.createElement('div');
      pageDiv.classList.add('pdf-page');
      pageDiv.style.position = 'relative';
      pageDiv.appendChild(canvas);

      pdfContainer.appendChild(pageDiv);

      // Render PDF page
      await page.render({ canvasContext: context, viewport }).promise;

      // Render annotations for this page
      annotations
        ?.filter((ann: any) => ann.page === i)
        .forEach((ann: any) => this.renderAnnotation(pageDiv, ann));
    }
  }

  renderAnnotation(pageDiv: HTMLElement, annotation: any): void {
    const wrapper = document.createElement('div');
    wrapper.classList.add('annotation-wrapper');
    wrapper.style.position = 'absolute';
    wrapper.style.left = annotation.coordinates.x;
    wrapper.style.top = annotation.coordinates.y;
    wrapper.style.width = annotation.size.width;
    wrapper.style.height = annotation.size.height;
    wrapper.style.border = '2px solid #f00';
    wrapper.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
    wrapper.style.display = 'flex';
    wrapper.style.alignItems = 'center';
    wrapper.style.justifyContent = 'center';

    // Add content based on annotation type
    if (annotation.type === 'text') {
      wrapper.textContent = annotation.text || 'Editable Text';
    } else if (annotation.type === 'checkbox') {
      const input = document.createElement('input');
      input.type = 'checkbox';
      input.checked = annotation.text;
      wrapper.appendChild(input);
    } else if (annotation.type === 'date') {
      const input = document.createElement('input');
      input.type = 'date';
      input.value = annotation.text || '';
      wrapper.appendChild(input);
    } else {
      wrapper.textContent = annotation.text || annotation.type;
    }

    pageDiv.appendChild(wrapper);
  }
}
