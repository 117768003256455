@if(showOtpVerification){
<kzn-otp-verification
  [userName]="userName"
  [requestId]="requestId"
  (navigateBackEvent)="cancelOTPVerification()"
></kzn-otp-verification>
} @else {
<div class="wrapper-login">
  <div class="wrapper-login__header">
    <h1 class="primary a-center"><strong>Login</strong></h1>
    <h3>Hey, welcome back! Enter your credentials here</h3>
  </div>
  <form id="form-login" [formGroup]="loginForm">
    <mat-form-field appearance="outline">
      <mat-label>User Name</mat-label>
      <input
        matInput
        placeholder="Enter your user name"
        formControlName="userName"
        id="input-login-user-name"
      />
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        [type]="showPassword ? 'text' : 'password'"
        matInput
        placeholder="Enter your password"
        formControlName="password"
        id="input-login-password"
        autocomplete
      />
      <mat-icon
        matSuffix
        class="clickable primary"
        (click)="togglePasswordVisibility()"
        >{{ showPassword ? "visibility_off" : "visibility" }}</mat-icon
      >
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    <span class="primary t-rt-align clickable" routerLink="/reset-password"
      >Forgot password?</span
    >
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="onLogin()"
      [class.spinner]="isLoading"
      [disabled]="isLoading"
    >
      Login
    </button>
  </form>
</div>
}
