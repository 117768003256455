import { EmbeddedViewRef, Injectable, ViewContainerRef } from "@angular/core";
import { SignDragResizeComponent } from "@components/sign-drag-resize/sign-drag-resize.component";
import { Constants } from "@config/constants";
import { IGetSignerSignature, ISignUploadSignature } from "@modals/api.modal";
import * as htmlToImage from "html-to-image";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class SignService {
  public isLoadingSignerSignatures: boolean = false;
  public signerSignatures: Array<IGetSignerSignature> = [];

  constructor(private _httpService: HttpService) { }

  generateUUID(): string {
    var d = new Date().getTime();
    var d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16;
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }

  makeResizableElement(
    elementSelector: string,
    minHeight: number,
    minWidth: number
  ) {
    const element: HTMLElement | null = document.querySelector(elementSelector);
    const resizers = document.querySelectorAll(elementSelector + " .resizer");
    let original_width = 0;
    let original_height = 0;
    let original_x = 0;
    let original_y = 0;
    let original_mouse_x = 0;
    let original_mouse_y = 0;

    if (element) {
      for (let i = 0; i < resizers.length; i++) {
        const currentResizer = resizers[i];
        const resizeStart = (event: any) => {
          event.preventDefault();
          original_width = parseFloat(
            getComputedStyle(element, null)
              .getPropertyValue("width")
              .replace("px", "")
          );
          original_height = parseFloat(
            getComputedStyle(element, null)
              .getPropertyValue("height")
              .replace("px", "")
          );
          original_x = element.offsetLeft;
          original_y = element.offsetTop;
          const pageX =
            event.type === "touchstart"
              ? event.changedTouches[0].pageX
              : event.pageX;
          const pageY =
            event.type === "touchstart"
              ? event.changedTouches[0].pageY
              : event.pageY;
          original_mouse_x = pageX;
          original_mouse_y = pageY;
          window.addEventListener("mousemove", resize);
          window.addEventListener("mouseup", stopResize);
          window.addEventListener("touchmove", resize);
          window.addEventListener("touchend", stopResize);
        };

        currentResizer.addEventListener("mousedown", resizeStart);
        currentResizer.addEventListener("touchstart", resizeStart);

        const resize = (event: any) => {
          const pageX =
            event.type === "touchmove"
              ? event.changedTouches[0].pageX
              : event.pageX;
          const pageY =
            event.type === "touchmove"
              ? event.changedTouches[0].pageY
              : event.pageY;

          if (element) {
            if (currentResizer.classList.contains("bottom-right")) {
              const width = original_width + (pageX - original_mouse_x);
              const height = original_height + (pageY - original_mouse_y);
              if (width > minWidth) {
                element.style.width = width + "px";
              }
              if (height > minHeight) {
                element.style.height = height + "px";
              }
            } else if (currentResizer.classList.contains("bottom-left")) {
              const height = original_height + (pageY - original_mouse_y);
              const width = original_width - (pageX - original_mouse_x);
              if (height > minHeight) {
                element.style.height = height + "px";
              }
              if (width > minWidth) {
                element.style.width = width + "px";
                element.style.left =
                  original_x + (pageX - original_mouse_x) + "px";
              }
            } else if (currentResizer.classList.contains("top-right")) {
              const width = original_width + (pageX - original_mouse_x);
              const height = original_height - (pageY - original_mouse_y);
              if (width > minWidth) {
                element.style.width = width + "px";
              }
              if (height > minHeight) {
                element.style.height = height + "px";
                element.style.top =
                  original_y + (pageY - original_mouse_y) + "px";
              }
            } else {
              const width = original_width - (pageX - original_mouse_x);
              const height = original_height - (pageY - original_mouse_y);
              if (width > minWidth) {
                element.style.width = width + "px";
                element.style.left =
                  original_x + (pageX - original_mouse_x) + "px";
              }
              if (height > minHeight) {
                element.style.height = height + "px";
                element.style.top =
                  original_y + (pageY - original_mouse_y) + "px";
              }
            }
          }
        };

        const stopResize = () => {
          window.removeEventListener("mousemove", resize);
          window.removeEventListener("touchmove", resize);
        };
      }
    }
  }

  private async getElementImage(element: Element): Promise<string> {
    const commentOnlyInput = element.getElementsByClassName(
      Constants.SIGN_COMMENT_CLASS
    )[0] as HTMLElement;
    const imgStr = commentOnlyInput
      ? await htmlToImage.toPng(commentOnlyInput)
      : await element.getElementsByTagName("img")[0]?.src;
    return imgStr?.split("base64,")[1];
  }

  async getPDFSignatures(): Promise<Array<ISignUploadSignature>> {
    const request: Array<ISignUploadSignature> = [];
    const pages = document.getElementsByClassName("page");
    for (let i = 0; i < pages.length; i++) {
      const signImagesOnPage = pages[i].getElementsByClassName("cdk-drag");
      if (signImagesOnPage.length) {
        for (let j = 0; j < signImagesOnPage.length; j++) {
          const pageRect = pages[i].getBoundingClientRect();
          const signImageRect = signImagesOnPage[j].getBoundingClientRect();
          const pageWidth = pages[i].clientWidth;
          const pageHeight = pages[i].clientHeight;
          const xPercent = ((signImageRect.x - pageRect.x) / pageWidth) * 100;
          const yPercent = ((signImageRect.y - pageRect.y) / pageHeight) * 100;
          const commentOnlyInput = signImagesOnPage[j].getElementsByClassName(
            Constants.SIGN_COMMENT_CLASS
          )[0];
          const ele = commentOnlyInput
            ? commentOnlyInput
            : signImagesOnPage[j].getElementsByTagName("img")[0];
          const signatureId = ele.getAttribute('signatureid') ?? '';
          const imgWidthPercent = (ele?.clientWidth / pageWidth) * 100;
          const imgHeightPercent = (ele?.clientHeight / pageHeight) * 100;
          const signComment =
            signImagesOnPage[j].getElementsByClassName("sign-comment")[0]
              ?.innerHTML || "";
          const signImgStr = await this.getElementImage(signImagesOnPage[j]);

          request.push({
            pageIndex: i,
            xPercent,
            yPercent,
            signImgStr,
            imgWidthPercent,
            imgHeightPercent,
            signComment,
            signatureId,
          });
        }
      }
    }

    return request;
  }

  addSignImage(
    imageBase64: string,
    left: string,
    top: string,
    pageIndex: number = 0,
    viewContainerRef: ViewContainerRef,
    isDraggable: boolean = true,
    isCommentOnly: boolean = false,
    signComment?: string,
    signHereContainer?: HTMLElement,
    comment?: string,
    signatureId?: string
  ) {
    const pages = document.getElementsByClassName("page");
    const element: HTMLElement = this.getSignDragResizeElement(
      imageBase64,
      left,
      top,
      viewContainerRef,
      isDraggable,
      isCommentOnly,
      signComment,
      comment,
      signatureId
    );
    if (signHereContainer) {
      const img = signHereContainer.getElementsByTagName("img")[0];
      if (img) img.style.display = "none";
      signHereContainer.appendChild(element);
    } else {
      pages[pageIndex].appendChild(element);
    }

    for (let i = 0; i < pages.length; ++i) {
      pages[i].removeEventListener("click", () => { });
      pages[i].removeEventListener("mousemove", () => { });
    }
  }

  getSignDragResizeElement(
    imageBase64: string,
    left: string,
    top: string,
    viewContainerRef: ViewContainerRef,
    isDraggable: boolean = true,
    isCommentOnly: boolean,
    signComment?: string,
    comment?: string,
    signatureId?: string
  ): HTMLElement {
    const componentRef = viewContainerRef.createComponent(
      SignDragResizeComponent
    );
    componentRef.instance.imageBase64 = imageBase64;
    componentRef.instance.signComment = signComment;
    componentRef.instance.left = left;
    componentRef.instance.top = top;
    componentRef.instance.isCommentOnly = isCommentOnly;
    componentRef.instance.comment = comment;
    componentRef.instance.isDraggable = isDraggable;
    componentRef.instance.signatureId = signatureId ?? '';
    componentRef.instance.destroyComponent = () => {
      let htmlElement = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      if (
        htmlElement.parentElement &&
        htmlElement.parentElement.nodeName === "SPAN"
      )
        htmlElement.parentElement.className = "box-sign-here";
      componentRef.destroy();
    };
    return componentRef.location.nativeElement;
  }

  addSign(
    imageBase64: string,
    viewContainerRef: ViewContainerRef,
    isCommentOnly: boolean = false,
    signComment?: string,
    comment?: string,
    signatureId?: string
  ) {
    let img: HTMLElement | undefined = document.createElement("img");
    img.setAttribute("src", imageBase64);
    img.style.position = "absolute";
    if (!isCommentOnly) {
      img.style.width = Constants.DEFAULT_SIGN_IMAGE_WIDTH;
      img.style.height = Constants.DEFAULT_SIGN_IMAGE_HEIGHT;
    }

    const textLayers = document.getElementsByClassName("textLayer");
    for (let i = 0; i < textLayers?.length; i++) {
      (textLayers[i] as HTMLElement).style.pointerEvents = "none";
    }

    const pages = document.getElementsByClassName("page");
    for (let i = 0; i < pages.length; i++) {
      pages[i].addEventListener("mouseenter", () => {
        if (img) {
          pages[i].appendChild(img);
        }
      });

      pages[i].addEventListener("mousemove", (event: any) => {
        if (img) {
          img!.style.left = event.layerX + 10 + "px";
          img!.style.top = event.layerY + 10 + "px";
        }
      });

      pages[i].addEventListener("click", (event: any) => {
        if (img) {
          this.addSignImage(
            imageBase64,
            img!.style.left,
            img!.style.top,
            i,
            viewContainerRef,
            true,
            isCommentOnly,
            signComment,
            undefined,
            comment,
            signatureId
          );
          pages[i].removeChild(img);
          img = undefined;

          for (let i = 0; i < textLayers?.length; i++) {
            (textLayers[i] as HTMLElement).style.pointerEvents = "all";
          }
        }
      });
    }
  }

  generateSignHereElements(signatureTag: string): {
    signHereElements: Array<HTMLElement>;
    commentHereElements: Array<HTMLElement>;
  } {
    const signHereElements: Array<HTMLElement> = [];
    const commentHereElements: Array<HTMLElement> = [];
    const textLayers = document.getElementsByClassName("textLayer");
    for (let i = 0; i < textLayers.length; i++) {
      const textElements = textLayers[i].children;
      for (let j = 0; j < textElements.length; j++) {
        if (
          textElements[j].textContent
            ?.toLowerCase()
            .includes(Constants.COMMENT_TAG_PREFIX.toLowerCase())
        ) {
          const element = textElements[j] as HTMLElement;
          element.setAttribute("page", i.toString());
          // element.style.left =
          //   element.offsetLeft -
          //   (Constants.DEFAULT_SIGN_IMAGE_WIDTH / 2 - element.clientWidth / 2) +
          //   "px";
          // element.style.top =
          //   element.offsetTop -
          //   (Constants.DEFAULT_SIGN_IMAGE_HEIGHT / 2 -
          //     element.clientHeight / 2) +
          //   "px";
          // console.log('WIDTH : ', (Constants.DEFAULT_SIGN_IMAGE_HEIGHT / textLayers[i].clientHeight) * 100)
          // console.log('WIDTHH : ', textLayers[i].clientHeight)
          element.style.width = Constants.DEFAULT_SIGN_IMAGE_WIDTH;
          element.style.height = Constants.DEFAULT_SIGN_IMAGE_HEIGHT;
          element.innerHTML = "";
          element.className = "box-sign-here";
          const img = document.createElement("img");
          img.setAttribute("src", "./../assets/comment-here.png");
          img.className = "img-sign-here";
          img.style.width = "100%";
          img.style.height = "100%";
          img.style.display = "none";
          element.appendChild(img);
          commentHereElements.push(element);
        } else if (
          textElements[j].textContent
            ?.toLowerCase()
            .includes(signatureTag.toLowerCase())
        ) {
          const element = textElements[j] as HTMLElement;
          element.setAttribute("page", i.toString());
          // element.style.left =
          //   element.offsetLeft -
          //   (Constants.DEFAULT_SIGN_IMAGE_WIDTH / 2 - element.clientWidth / 2) +
          //   "px";
          // element.style.top =
          //   element.offsetTop -
          //   (Constants.DEFAULT_SIGN_IMAGE_HEIGHT / 2 -
          //     element.clientHeight / 2) +
          //   "px";
          element.style.width = Constants.DEFAULT_SIGN_IMAGE_WIDTH;
          element.style.height = Constants.DEFAULT_SIGN_IMAGE_HEIGHT;
          element.innerHTML = "";
          element.className = "box-sign-here sign";
          const img = document.createElement("img");
          img.setAttribute("src", "./../assets/sign-here.png");
          img.className = "img-sign-here";
          img.style.width = "100%";
          img.style.height = "100%";
          img.style.display = "none";
          element.appendChild(img);
          signHereElements.push(element);
        }
      }
    }
    return { signHereElements, commentHereElements };
  }

  getSignerSignatures(signRequestId: string, signerId: string): void {
    const params = {
      SignRequestId: signRequestId,
      SignerId: signerId
    }

    this.isLoadingSignerSignatures = true;
    this.signerSignatures = [];

    this._httpService.get(
      this._httpService.createEndpoint(
        Constants.API_URI_SIGN_GET_SIGNER_SIGNATURE,
        undefined,
        params
      )
    ).subscribe((response: any) => {
      this.isLoadingSignerSignatures = false;
      this.signerSignatures = response?.Data?.map((sign: IGetSignerSignature) => ({
        ...sign,
        SignImagePath: 'data:image;base64,' + sign.SignImagePath
      }));
    },
      (errorResponse) => {
        this.isLoadingSignerSignatures = false;
        this._httpService.showHttpError(errorResponse);
      })
  }
}
