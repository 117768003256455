<div class="upload-section-container">
    <div class="drag-drop-area" (drop)="handleDrop($event)" (dragover)="allowDrop($event)">
        <div class="drag-drop-content">
            <span class="file-upload-title">Drag and drop your PDF here or</span>
            <button mat-raised-button color="primary" (click)="fileInput.click()"><mat-icon>file_upload</mat-icon>
                @if(pdfFileName) { Change file }
                @else { Select file }
            </button>
            <span class="info-text">It is mandatory to upload a pdf file</span>
            <input #fileInput type="file" hidden (change)="onPdfUpload($event)" />
        </div>
        @if(pdfFileName) {
        <div class="selected-file-container">
            <mat-icon class="success">check_circle</mat-icon>
            <span class="file-upload-title">{{pdfFileName}}</span>
        </div>
        }
    </div>
</div>

@if(!isDraftRequest) {
    <mat-form-field appearance="outline" class="width-100">
        <mat-label>App Token</mat-label>
        <mat-select [formControl]="appToken">
            @for(token of allTokens; track token.AppName; let index = $index) {
            <mat-option [value]="token">
                {{
                token.AppName + ' ' + '(' + token.Token + ')'
                }}
            </mat-option>
            }
        </mat-select>
        <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
}

<mat-accordion multi>
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>Requester details</mat-panel-title>
        </mat-expansion-panel-header>

        <div [formGroup]="requesterForm" class="form__requester-details">
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter name" formControlName="name" />
                <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Enter email" formControlName="email" />
                @if(requesterForm.get('email')?.errors?.['required']) {
                <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
                } @else {
                <mat-error>{{ Messages.MSG_ERROR_INVALID_EMAIL }}</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Phone</mat-label>
                <ngx-mat-intl-tel-input [preferredCountries]="['in', 'us', 'gb']" [enablePlaceholder]="false"
                    [enableSearch]="true" describedBy="phoneInput" inputPlaceholder="Enter phone"
                    formControlName="phone"></ngx-mat-intl-tel-input>
                <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
            </mat-form-field>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>Signer details</mat-panel-title>
        </mat-expansion-panel-header>

        <kzn-table [showSearch]="false" [showPagination]="false" [data]="tableService.tableInputData"
            [columns]="tableColumns" [rowActions]="tableRowActions" style="width: 100%">
            <ng-container header-right>
                <button type="button" mat-raised-button color="primary" (click)="addNewUser()">
                    <mat-icon>add</mat-icon>Add User
                </button>
            </ng-container>
        </kzn-table>
    </mat-expansion-panel>
</mat-accordion>