import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DialogComponent } from '@components/layouts/dialog/dialog.component';
import { Messages } from '@config/messages';

@Component({
  selector: 'kzn-decline-sign-request-confirmation',
  standalone: true,
  imports: [DialogComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './decline-sign-request-confirmation.component.html',
  styleUrl: './decline-sign-request-confirmation.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DeclineSignRequestConfirmationComponent {
  @Output() confirmed = new EventEmitter<string>();

  reasonForDecline: FormControl = new FormControl('', Validators.required);
  Messages = Messages;

  constructor(private dialogRef: MatDialogRef<DeclineSignRequestConfirmationComponent>) { }

  onAction() {
    this.confirmed.emit(this.reasonForDecline.value);
    this.dialogRef.close();
  }
}
