<kzn-dialog
  [title]="
    customerSubscription
      ? 'Update Customer Subscription'
      : 'Create Customer Subscription'
  "
  [isActionDisabled]="isLoadingCustomerSubscriptions"
  [isActionInProgress]="isCreatingOrUpdatingCustomerSubscription"
  [actionLabel]="customerSubscription ? 'Save' : 'Create'"
  (actionFired)="createOrUpdateCustomerSubscription()"
>
  <form
    [formGroup]="customerSubscriptionForm"
    class="form-create-edit-customer-subscription"
  >
    <mat-form-field
      appearance="outline"
      [class.spinner]="isLoadingCustomerSubscriptions"
    >
      <mat-label>Subscription Name</mat-label>
      <mat-select formControlName="name">
        @for(subscription of customerSubscriptions; track
        subscription.SubscriptionId; let index = $index) {
        <mat-option [value]="subscription">{{
          subscription.SubscriptionName
        }}</mat-option>
        }
      </mat-select>
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Start Date</mat-label>
      <input
        matInput
        [matDatepicker]="startDatePicker"
        formControlName="startDate"
        [max]="customerSubscriptionForm.get('endDate')?.value"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>End Date</mat-label>
      <input
        matInput
        [matDatepicker]="endDatePicker"
        formControlName="endDate"
        [min]="customerSubscriptionForm.get('startDate')?.value"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    <mat-slide-toggle
      color="primary"
      formControlName="isActive"
      labelPosition="before"
      >Active</mat-slide-toggle
    >
  </form>
</kzn-dialog>
