import { CommonModule } from "@angular/common";
import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "kzn-alert",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./alert.component.html",
  styleUrl: "./alert.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class AlertComponent {
  @Input({ required: true }) message!: string;
  @Input() isSuccess: boolean = false;
}
