@if(isLoading) {
<kzn-loader message="Getting customers..."></kzn-loader>
} @else if(isError) {
<kzn-status-message [message]="errorMessage"></kzn-status-message>
} @else if(customerID){
<kzn-view-customer
  (customerSubscriptionCreated)="getCustomers()"
  (customerUpdated)="getCustomers(true)"
  [customerDetails]="selectedCustomer"
></kzn-view-customer>
} @else {
<mat-card class="mat-elevation-z8">
  <div class="customers-content">
    <kzn-table
      [data]="customers"
      [columns]="tableColumns"
      [rowActions]="tableRowActions"
      [defaultSortColumnDef]="defaultSortColumnDef"
      [defaultSortDirection]="defaultSortDirection"
      [showRefresh]="true"
      (refreshEventFired)="getCustomers(true)"
      style="width: 100%"
    >
      <ng-container header-right>
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="openCreateEditCustomerDialog()"
        >
          <mat-icon>add</mat-icon>Create Customer
        </button>
      </ng-container>
    </kzn-table>
  </div>
</mat-card>
}
