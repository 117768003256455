import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TableComponent } from '@components/layouts/table/table.component';
import { Messages } from '@config/messages';

@Component({
  selector: 'kzn-new-request',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatButtonModule, MatIconModule, TableComponent, MatFormFieldModule, MatInputModule, MatSelectModule, MatIconModule, MatCardModule],
  templateUrl: './new-request.component.html',
  styleUrl: './new-request.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class NewRequestComponent implements OnInit {
  form!: FormGroup;
  fileName: string = '';
  signerOptions: { label: string, value: string }[] = [
    {
      label: 'Signer',
      value: 'signer'
    }
  ];
  Messages = Messages;

  @ViewChild('newRequestFile') newRequestFile!: ElementRef<HTMLInputElement>;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      file: [''],
      rows: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.addRow();
  }

  get rows(): FormArray {
    return this.form.get('rows') as FormArray;
  }

  getFormGroupAtIndex(index: number) {
    return (this.rows.controls[index] as FormGroup);
  }

  addRow() {
    const row = this.fb.group({
      signer: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
    });
    this.rows.push(row);
  }

  removeRow(index: number) {
    this.rows.removeAt(index);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.form.patchValue({ file: file });
    }
  }

  createRequest() {

  }
}
