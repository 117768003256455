<div class="wrapper-reset-password">
  <div class="wrapper-reset-password__header">
    <h1 class="primary a-center"><strong>Reset Password</strong></h1>
    <h3>
      @if(!isOTPSent) { Enter your user name and we'll send you an OTP to reset
      } @else { Enter 6 digit OTP and new password }
    </h3>
  </div>
  <form id="form-reset-password">
    @if(!isOTPSent) {
    <mat-form-field appearance="outline">
      <mat-label>User Name</mat-label>
      <input
        matInput
        placeholder="Enter your user name"
        [formControl]="userName"
      />
      <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>
    <span class="primary t-rt-align clickable" routerLink="/login"
      >Back to login</span
    >
    } @else {
    <kzn-otp-input #otpInputComponentRef></kzn-otp-input>
    <form id="form-password" [formGroup]="passwordForm">
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          [type]="showPassword ? 'text' : 'password'"
          matInput
          placeholder="Enter your password"
          formControlName="newPassword"
          autocomplete
        />
        <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
        <mat-icon
          matSuffix
          class="clickable primary"
          (click)="togglePasswordVisibility()"
          >{{ showPassword ? "visibility_off" : "visibility" }}</mat-icon
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input
          [type]="showConfirmPassword ? 'text' : 'password'"
          matInput
          placeholder="Enter your password"
          formControlName="confirmPassword"
          autocomplete
        />
        @if(passwordForm.get('confirmPassword')?.errors?.['required']) {
        <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
        }@else {
        <mat-error>{{Messages.MSG_ERROR_PASSWORD_MISMATCH}}</mat-error>
        }
        <mat-icon
          matSuffix
          class="clickable primary"
          (click)="toggleConfirmPasswordVisibility()"
          >{{ showConfirmPassword ? "visibility_off" : "visibility" }}</mat-icon
        >
      </mat-form-field>
    </form>
    } @if(!isOTPSent) {
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="sendResetOTP()"
      [class.spinner]="isSendingOTP"
      [disabled]="isSendingOTP"
    >
      Send OTP
    </button>
    } @else {
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="resetPassword()"
      [class.spinner]="isResettingPassword"
      [disabled]="isResettingPassword"
    >
      Reset
    </button>
    }
  </form>
</div>
