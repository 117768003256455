@if(isLoading) {
<kzn-loader message="Getting blogs..."></kzn-loader>
} @else {
<mat-card class="mat-elevation-z8">
  <div class="blogs-container">
    <div class="blogs__header">
      <div class="blogs-header__left">
        <input
          type="text"
          class="input-sm"
          placeholder="Search..."
          (keyup)="applyFilter($event)"
          [disabled]="isError || !blogs.length"
          #input
        />
      </div>
      <div class="blogs-header__right">
        @if(isAdminUser) {
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="openCreateEditBlogDialog()"
        >
          <mat-icon>add</mat-icon>Create Blog
        </button>
        }
      </div>
    </div>
    @if(isError) {
    <kzn-status-message [message]="errorMessage"></kzn-status-message>
    } @else if(!blogsToDisplay.length) {
    <kzn-status-message
      [message]="Messages.MSG_NO_RECORDS"
    ></kzn-status-message>
    } @else {
    <div class="blogs__body">
      @for(blog of blogsToDisplay; track blog.BlogId; let index = $index) {
      <mat-card class="mat-elevation-z8" class="blog">
        @if(isAdminUser) {
        <button
          mat-mini-fab
          color="primary"
          class="blog__edit"
          (click)="openCreateEditBlogDialog(blog)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        }
        <a [href]="blog.Url" target="_blank">
          <div class="blog__image"><img [src]="blog.Title" /></div>
          <div class="blog__title">
            <span [matTooltip]="blog.Description">{{ blog.Description }}</span>
          </div>
        </a>
      </mat-card>
      }
    </div>
    }
  </div>
</mat-card>
}
