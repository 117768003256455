import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { SharedMaterialModule } from "@config/shared-material.module";
import { PdfUploadUsersComponent } from "@components/pdf-editor/pdf-upload-users/pdf-upload-users.component";
import { PdfViewEditComponent } from "@components/pdf-editor/pdf-view-edit/pdf-view-edit.component";
import { PdfThankYouComponent } from "@components/pdf-editor/pdf-thank-you/pdf-thank-you.component";
import { IAppToken } from '@modals/api.modal';
import { MatStepper } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { TableService } from '@services/table.service';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '@services/http.service';
import { Constants } from '@config/constants';
import { LoaderComponent } from '@components/layouts/loader/loader.component';
import { StatusMessageComponent } from '@components/layouts/status-message/status-message.component';
import { SignService } from '@services/sign.service';

@Component({
    selector: 'app-pdf-edit-stepper',
    templateUrl: './pdf-edit-stepper.component.html',
    standalone: true,
    imports: [SharedMaterialModule, PdfUploadUsersComponent, PdfViewEditComponent, PdfThankYouComponent, MatCardModule, LoaderComponent, StatusMessageComponent],
    styleUrls: ['./pdf-edit-stepper.component.scss']
})
export class PdfEditStepperComponent implements OnInit, AfterViewInit {
    isPdfUploaded = false;
    pdfFile: File | null = null;
    isLoading: boolean = false;
    isError: boolean = false;
    requesterDetails = {};
    pdfDetails = {
        content: '',
        name: ''
    };
    steps = ['Upload & Assign Users', 'Add Tags', 'Submit'];
    topPosition: number = 0;
    appToken!: IAppToken;
    isEditable: boolean = true;
    isDraftRequest: boolean = false;
    @ViewChild('stepContentContainer') stepContentContainer!: ElementRef;
    @ViewChild('pdfUploadUsersComponentRef') pdfUploadUsersComponentRef!: PdfUploadUsersComponent;
    @ViewChild(PdfViewEditComponent) pdfViewEditComponent!: PdfViewEditComponent;
    @ViewChild('stepper') stepper!: MatStepper;

    private _activatedRoute = inject(ActivatedRoute);
    private _httpService = inject(HttpService);
    private _signService = inject(SignService);

    constructor(public tableService: TableService) { }

    ngOnInit(): void {
        this.tableService.tableInputData = [];
        this.isDraftRequest = this._activatedRoute.snapshot.data["isDraftRequest"] ?? false;
        if (this.isDraftRequest) this.getDraftRequestDetails();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.stepContentContainer) {
                this.topPosition = this.stepContentContainer.nativeElement.offsetTop;
            }
        });
    }

    generateFileFromBase64(base64String: string, fileName: string): File {
        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return new File([bytes], fileName, { type: 'application/pdf' });
    }


    getDraftRequestDetails() {
        this.isLoading = true;
        this.isError = false;

        this._httpService
            .get(
                this._httpService.createEndpoint(
                    Constants.API_URI_SIGN_REQUEST_GET_DRAFT,
                    undefined,
                    { SignRequestId: this._activatedRoute.snapshot.params["requestID"] }
                )
            )
            .subscribe(
                (response: any) => {
                    if (response?.Data?.[0]) {
                        const responseData = response?.Data?.[0];
                        this.requesterDetails = {
                            name: responseData.RequesterName,
                            email: responseData.RequesterEmail,
                            phone: responseData.RequesterPhone
                        };
                        this.pdfDetails = {
                            content: responseData.DocContent,
                            name: responseData.DocName + '.pdf',
                        }
                        const file: File = this.generateFileFromBase64(responseData.DocContent, responseData.DocName);
                        this.handlePdfUploaded(file);
                        responseData?.Signers?.forEach((signer: any) => {
                            this.tableService.tableInputData.push({
                                id: signer.Id,
                                name: signer.SignerName,
                                email: signer.SignerEmail,
                                phone: signer.Phone,
                                role: signer.SignerRole?.toLowerCase(),
                                tempID: this._signService.generateUUID()
                            });
                        });
                    }
                    this.isLoading = false;
                },
                (errorResponse) => {
                    this.isLoading = false;
                    this.isError = true;
                }
            );
    }

    handlePdfUploaded(file: File): void {
        this.pdfFile = file;
        this.isPdfUploaded = true;
    }

    handleAppTokenUpdated(updatedAppToken: IAppToken) {
        this.appToken = updatedAppToken;
    }

    resetStepper(): void {
        location.reload()
    }

    checkForAllValidUsers() {
        return this.tableService.tableInputData.length && this.tableService.tableInputData.every((item) =>
            Object.values(item).every((value) => !!value)
        );
    }

    nextStep(): void {
        if (this.isPdfUploaded && (this.isDraftRequest || (!this.isDraftRequest && this.appToken)) && this.checkForAllValidUsers() && this.pdfUploadUsersComponentRef.requesterForm.valid) {
            if (this.stepper.selected) this.stepper.selected.completed = true;
            this.stepper.next();
        }
    }

    previousStep(): void {
        this.stepper.previous();
    }

    handleSubmitComplete(): void {
        this.isEditable = false;
        this.nextStep();
    }

    // Trigger the child's submit method
    submitData(): void {
        let requesterDetails = this.pdfUploadUsersComponentRef.requesterForm.value;
        if (this.pdfViewEditComponent && requesterDetails) {
            requesterDetails = { ...requesterDetails, phone: this.tableService.getFormattedNumber(requesterDetails.phone, this.pdfUploadUsersComponentRef.countryCode) }
            this.pdfViewEditComponent.submit(requesterDetails);
        }
    }

    // Trigger the child's goToPrevious method
    goToPrevious(): void {
        if (this.pdfViewEditComponent) {
            this.pdfViewEditComponent.goToPrevious();
        }
    }
}
