import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'kzn-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './root.component.html',
  styleUrl: './root.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RootComponent {
  title = 'kaizen';
}
