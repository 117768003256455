@if(isDownloading) {
<kzn-loader message="Downloading file..."></kzn-loader>
} @else if(!isView && (isSigned || isSubmitted || isDeclined)) {
<kzn-status-message [heading]="statusMessage.heading" [message]="statusMessage.message"
  [isSuccess]="statusMessage.isSuccess"></kzn-status-message>
} @else if(isError) {
<kzn-status-message [heading]="statusMessage.heading" [message]="statusMessage.message"
  [isSuccess]="statusMessage.isSuccess"></kzn-status-message>
} @else if(base64) {
<ngx-extended-pdf-viewer [base64Src]="base64" [handTool]="false" [showHandToolButton]="true"
  (annotationLayerRendered)="onAnnotationLayerRendered($event)" [customToolbar]="additionalButtons"
  [showUnverifiedSignatures]="true" (pagesLoaded)="pagesLoaded($event)" [pageViewMode]="pageViewMode">
</ngx-extended-pdf-viewer>

<ng-template #additionalButtons>
  <div id="toolbarViewer">
    <div id="toolbarViewerLeft">
      <!-- <pdf-toggle-sidebar></pdf-toggle-sidebar> -->
      <div class="toolbarButtonSpacer"></div>
      <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
      <pdf-paging-area></pdf-paging-area>
    </div>
    <div id="toolbarViewerRight">
      @if(!isView) {
      <div class="document-action-btn-container">
        @if(isView) {
        <button mat-button color="primary" (click)="downloadPDF()" [disabled]="isDownloading || !base64">
          Download
        </button>
        } @else {
        <button mat-button mat-button (click)="decline()" [disabled]="isSubmitted || isDeclined || isSigned || !base64">
          Decline
        </button>
        <button mat-button mat-raised-button color="primary" (click)="signPDF()"
          [disabled]="isSubmitted || isDeclined || isSigned || !base64">
          Submit
        </button>
        }
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
        <mat-menu #menu="matMenu">
          @if(signerRole.toLowerCase() === EnumSignerRoleType.SIGNER.toLowerCase()) {
          <button mat-menu-item (click)="openSignDialog(false, true)">
            <mat-icon color="primary">add</mat-icon>Signature
          </button>
          @for(signTag of customSignTags; track signTag.type) {
          <button mat-menu-item (click)="activateClickMode(signTag.type, signTag.dimensions)">
            <mat-icon color="primary">{{signTag.icon}}</mat-icon>{{signTag.label}}
          </button>
          }
          }
          <button mat-menu-item (click)="openSignDialog(true, true)">
            <mat-icon color="primary">add_comment</mat-icon>Comment
          </button>
        </mat-menu>
      </div>
      }
    </div>
  </div>
</ng-template>
}