<kzn-dialog title="Decline request" actionLabel="Decline" (actionFired)="onAction()"
    [isActionDisabled]="reasonForDecline.invalid">
    <div class="content__decline-request">
        <span>Are you sure, you want to Decline this request? If yes, please provide a reason and click on Decline,
            otherwise click Cancel.</span>
        <mat-form-field appearance="outline" class="input__reason-for-decline">
            <mat-label>Reason for decline</mat-label>
            <input type="email" matInput placeholder="Enter reason for decline" [formControl]="reasonForDecline" />
            <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
        </mat-form-field>
    </div>
</kzn-dialog>