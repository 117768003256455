<div class="customer-subscriptions-container">
  <kzn-table
    [data]="customerSubscriptions"
    [columns]="tableColumns"
    [rowActions]="tableRowActions"
    style="width: 100%"
    class="table-customer-subscriptions"
  >
    <ng-container header-right>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="openCreateSubscriptionDialog()"
      >
        <mat-icon>add</mat-icon>Create Customer Subscription
      </button>
    </ng-container>
  </kzn-table>
</div>
