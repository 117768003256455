import { ISignRequest } from "@modals/api.modal";

export interface IUserDetails {
  CustomerId: string;
  CustomerName: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Mobile: string;
}

export enum EnumRequestStatus {
  ALL = "All",
  OPEN = "Open",
  SIGNED = "Signed",
  PARTIAL_SIGNED = "PartialSigned",
  CANCELLED = "Cancelled",
}

export type RequestStatus = keyof typeof EnumRequestStatus;

export interface IRequestStatus {
  status: string;
  count: number;
  label: string;
}

export interface IExpandedRowsData {
  data: Array<any>;
  columns: Array<any>;
  actions?: Array<ITableActionRow>
}

export type TSignRequestTableRow = ISignRequest & {
  isDownloading?: boolean;
  expandedRowsData?: IExpandedRowsData;
};

export interface ITableActionRow {
  icon: string;
  color: string;
  title: string;
  isLoading?: (row: any) => boolean;
  isDisabled?: (row: any) => boolean;
  actionFn: (row: any) => void;
}

export enum EnumSignTagTypes {
  SIGN = "Esign",
  INITIALS = "Initials",
  STAMPS = "Stamps",
  DATE = "Date",
  CHECKBOX = "Checkbox",
  COMMENT = "Comment",
  TEXT = "Text"
}

export interface ISignTag {
  Height: number;
  Page: number;
  Text: string;
  Type: EnumSignTagTypes;
  Width: number;
  XLocation: number;
  YLocation: number;
  IsRendered?: boolean;
}

export interface IUploadPDFUser {
  name: string;
  email: string;
  phone: string;
  role: string;
  id?: string;
  tempID: string;
}

export enum EnumTableDynamicInputType {
  TEXT = "text",
  DROPDOWN = "dropdown",
  TEL = "tel",
  EMAIL = "email",
}

export enum EnumSignerRoleType {
  SIGNER = "Signer",
  REVIEWER = "Reviewer"
}
