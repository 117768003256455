@if(isLoading) {
<kzn-loader message="Getting tokens..."></kzn-loader>
} @else if(isError) {
<kzn-status-message [message]="errorMessage"></kzn-status-message>
} @else {
<mat-card class="mat-elevation-z8">
  <div class="settings-content">
    <kzn-table
      [data]="allTokens"
      [columns]="tableColumns"
      style="width: 100%"
      [showRefresh]="true"
      (refreshEventFired)="getAllTokens(true)"
    >
      <ng-container header-right>
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="openCreateTokenDialog()"
        >
          <mat-icon>add</mat-icon>Create App Token
        </button>
      </ng-container>
    </kzn-table>
  </div>
</mat-card>
}
